
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC06 from "../img/flow-pc06.png"
import IconQ601 from "../img/q6-01.png"
import IconQ602 from "../img/q6-02.png"
import IconQ603 from "../img/q6-03.png"
import ImgQ6Btn from "../img/q6-btn.png"
import ImgQ6BtnSp from "../img/q6-btn_sp.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import ImgFlowPC05 from "../img/flow-pc05.png";
import * as React from "react";

const Q6 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.roof_degree)
    const getSelected = (key: number) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: number) => {
        const ls =  LocalStorage.getInstance()
        ls.setRoofDegree(value)
        navigate('/q7')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC06} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-3 ">
                屋根の勾配を選択してください
            </h1>

            <div className="row mt-2 mt-md-5">
                <div className="col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(10)} className={'link-no-decoration ' + getSelected(10)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta pt-2 fs-xl">
                                緩やかな屋根<br />
                                <span className="fs-md">(0.5寸～1.4寸)</span></h2>
                            <figure className="m-3"><img src={IconQ601} className="w-65" /></figure>
                        </div>
                    </a>
                </div>
                <div className="col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(20)} className={'link-no-decoration ' + getSelected(20)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-blue pt-2 fs-xl">
                                標準的な屋根<br />
                                <span className="fs-md">(1.5寸～4.5寸)</span></h2>
                            <figure className="m-3"><img src={IconQ602} className="w-65" /></figure>
                        </div>
                    </a>
                </div>
                <div className="col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(30)} className={'link-no-decoration ' + getSelected(30)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-green pt-2 fs-xl">
                                やや急な屋根 <span className="fs-sm">※</span><br />
                               <span className="fs-md">  (4.6寸～) </span></h2>
                            <figure className="m-3"><img src={IconQ603} className="w-65" /></figure>
                        </div>
                        <p className="tc-white fs-sm p-1">
                            ※条件により搭載不可の場合がございます。<br />
                            ※標準規格外仕様 となるため、発電量・電気料金のみのシミュレーションとなります。詳しくはお近くのヤマダホームズまでご相談ください。
                        </p>
                    </a>
                </div>
            </div>

            <figure className="d-none d-md-block m-2 text-center"><img src={ImgQ6Btn}  /></figure>
            <figure className="d-block d-md-none m-2 text-center"><img src={ImgQ6BtnSp}  /></figure>

            <div className="p-1 "></div>
            <div className="m-4">
                <Link to="/q5" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                    <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q6

