
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC05 from "../img/flow-pc05.png"
import IconQ501 from "../img/q5-01.png"
import IconQ502 from "../img/q5-02.png"
import IconQ503 from "../img/q5-03.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import * as React from "react";
import ImgFlowPC04 from "../img/flow-pc04.png";

const Q5 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.roof_type)
    const getSelected = (key: string) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: string) => {
        const ls =  LocalStorage.getInstance()
        ls.setRoofType(value)
        navigate('/q6')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC05} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-3 ">
                屋根の形を選択してください
            </h1>

            <div className="row mt-2 mt-md-5">
                <div className="col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer('切妻')}  className={'link-no-decoration ' + getSelected('切妻')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta pt-2"><span className="fs-xs">きりづま</span><br />切妻屋根</h2>
                            <figure className="m-3"><img src={IconQ501} className="w-65" /></figure>
                        </div>
                    </a>
                </div>
                <div className="col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer('寄棟')} className={'link-no-decoration ' + getSelected('寄棟')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-blue pt-2"><span className="fs-xs">よせむね</span><br />
                                寄棟屋根</h2>
                            <figure className="m-3"><img src={IconQ502} className="w-65" /></figure>
                        </div>
                    </a>
                </div>
                <div className="col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer('片流れ')} className={'link-no-decoration ' + getSelected('片流れ')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-green pt-2"><span className="fs-xs"> かたながれ</span><br />
                                片流れ屋根</h2>
                            <figure className="m-3"><img src={IconQ503} className="w-65" /></figure>
                        </div>
                    </a>
                </div>
            </div>

            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/q4" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                    <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q5

