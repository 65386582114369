
import {Link, useNavigate} from "react-router-dom";
import IconArrow from "../img/icon_arw01.png";
import {LocalStorage} from "../storage/local_storage";
import * as React from "react";

const Agree = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        const ls =  LocalStorage.getInstance()
        ls.resetSaveData()
        ls.resetUsageData()
        ls.resetPayConditionData()
        ls.setSimAcception(true)
        navigate('/q1')
    }

    return (
        <div className={`home container`}>
            <div className="p-2 m-5">
            </div>
            <h1 className="tc-yellow text-center fs-32">
                太陽光発電システム<br />
                導入シミュレーター利用上の注意事項
            </h1>

            <p className="tc-white mt-5 fs-lg mx-2 mx-md-auto col-12 col-md-8" >当シミュレーターの結果につきましては、省エネ機器の種類・使用方法、燃料価格の変動や気候の変化、お客様の生活パターン、その他の要因等により変動するため、
                <strong ><u>導入後の結果をお約束・保証するものではありません。</u></strong>
                <br />あくまで目安としてご利用願います。
                <br /><br />
                より詳細なシミュレーションをご希望される際は、お近くのヤマダホームズ各展示場までお問い合わせ願います。</p>

            <div className="p-4 "></div>
            <div className="m-4">
                <div style={{cursor:'pointer'}} onClick={handleClick} className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto col-12 col-md-6 text-center fs-xl">
                       同意する <div className="w-4 float-end"><img src={IconArrow} /></div>
                    </div>
                </div>
            </div>
            <p className="text-center tc-white">シミュレーションを開始します</p>
            <div className="p-3 "></div>
        </div>
    )
}
export default Agree

