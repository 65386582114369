
import {Link, useNavigate} from "react-router-dom";
import IconTableOff from "../../img/icon_table_off.png";
import IconTableOn from "../../img/icon_table_on.png";
import IconGraphOff from "../../img/icon_graph_off.png";
import IconGraphOn from "../../img/icon_graph_on.png";
import IconArrow from "../../img/icon_arw01.png";
import ButtonSelector from "../../component/button_selector";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import ResultGraph from "../../component/result_graph";
import {ResponseResults, ResponseResultsBaseData} from "../../entity/result_response";
import {LocalStorage} from "../../storage/local_storage";
import {GetLinkUrl} from "../../util/get_link_url";
import IconPdf from "../../img/icon_pdf.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import {GetModeTitle} from "../../util/get_mode_title";
import {SimulationMode} from "../../entity/types";
import {UsageResultBaseData} from "../../entity/usage_result";

const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY'
});
let fixPar = 1.0

interface Props {
    isDefaultBattery: boolean
}
let isSolarOnlyData = true
const SalesResult2 = (props:Props) => {
    let  ResponseResultsBaseData1 = { ...ResponseResultsBaseData }
    const gls =  LocalStorage.getInstance()
    gls.getSaveData()
    gls.getUsageData()
    let unitData = gls.usageData.unitDefines
    const navigate = useNavigate();
    const [isModeBattery, isModeBatterySet] = useState(props.isDefaultBattery)
    const [appMode, appModeSet] = useState(gls.saveData.app_mode)
    const [isSolarOnly, isSolarOnlySet] = useState(true)
    const [loading, loadingSet] = useState(true);
    const [resData, resDataSet] = useState<ResponseResults>(ResponseResultsBaseData1)

    useEffect(() => {
        /// loadData(false)
        if(isModeBattery){
            isSolarOnlyData = false
            isSolarOnlySet((res) => {
                return false
            })
            loadData()
        }else{
            isSolarOnlyData = true
            isSolarOnlySet((res) => {
                return true
            })
            loadData()
        }
    }, []);

    const navigatePayment = () => {
        if((gls.saveData.simulation_mode == SimulationMode.EV)) {
            navigate('/sales/result3_ev')
        }else{
            navigate('/sales/result3')
        }
    }

    const loadData = () => {
        if(isSolarOnlyData){
            let baseData = getLoadData(gls.usageData.summaryWithAll)
            if((gls.saveData.simulation_mode == SimulationMode.EV)) {
                baseData = getLoadData(gls.usageData.summaryWithAllWithBattery)
            }
            resDataSet(baseData)
        }else{
            let baseData = getLoadData(gls.usageData.summaryWithAllWithBattery)
            if((gls.saveData.simulation_mode == SimulationMode.EV)) {
                baseData = getLoadData(gls.usageData.summaryWithAllWithBatteryWithEV)
            }
            resDataSet(baseData)
        }

        loadingSet(false)
    }

    const getLoadData = (baseData:UsageResultBaseData) => {
        let setupResponseResult: ResponseResults =  JSON.parse(JSON.stringify(ResponseResultsBaseData))
        if( gls.usageData){
            let sellIncomeTotal = 0
            let buyOutcomeTotal = 0
            let basicPriceTotal = 0
            let saienePriceTotal = 0
            let surchargePriceTotal = 0
            let yearTotal = 0
            let yearNonPanelTotal = 0
            let totalDiffTotal = 0

            let usageSeason = {summer:0, winter:0}
            let generateSeason = {summer:0, winter:0}
            let sellIncomeSeason = {summer:0, winter:0}
            let buyOutcomeSeason = {summer:0, winter:0}

            fixPar = gls.saveData.monthly_total_all_elec_cost / gls.saveData.monthly_total_all_elec_cost_calc
            if(isNaN(fixPar)){
                fixPar = 1.0
            }
            console.log('fixPar--------------', fixPar)
            for(const key in baseData.monthly ) {
                type ObjectKeyResponseResult = keyof typeof  ResponseResultsBaseData.monthly;
                const mKey = "m"+key as ObjectKeyResponseResult
                type BuyKey = keyof typeof baseData.monthly;
                const buyKey = key as unknown as BuyKey

                let allNoPanel = gls.usageData.summaryWithAllNoPanelNoBattery
                /*let baseData = gls.usageData.summaryWithAll
                if(isBattery){
                    baseData = gls.usageData.summaryWithAllWithBattery
                }*/

                let surchargeUnit = gls.usageData.unitDefines.surchargeUnitPrice
                let saieneChargeUnit = gls.usageData.unitDefines.saieneChargeUnitPrice

                const sellUnitPrice =  gls.usageData.unitDefines.sellUnitPrice
                const sellIncome = baseData.monthly[buyKey].sell_income
                const buyOutComeWithPanel = baseData.monthly[buyKey].buy_outcome*fixPar
                const buyOutComeNoPanel = allNoPanel.monthly[buyKey].buy_outcome*fixPar
                const saieneChargeWithPanel = Math.floor(baseData.monthly[buyKey].saiene_outcome*fixPar)
                const surchargeWithPanel =  Math.floor(baseData.monthly[buyKey].surcharge_outcome*fixPar)

                //const avgKwhBuyUnit = gls.usageData.summaryWithAll.monthly[buyKey].buy_outcome / (gls.usageData.summaryWithAll.monthly[buyKey].usage_wh/1000)
                //const nonPanelSurplusWh = (baseData.monthly[buyKey].usage_wh_day - baseData.monthly[buyKey].generate_wh)
                const nonPanelbuyWh = allNoPanel.monthly[buyKey].usage_wh
                const nonPanelSaieneCharge = allNoPanel.monthly[buyKey].saiene_outcome*fixPar
                const nonPanelSurcharge = allNoPanel.monthly[buyKey].surcharge_outcome*fixPar

                let fixedBasicPrice = baseData.basic*fixPar
                setupResponseResult.monthly[mKey].sell_income = sellIncome
                setupResponseResult.monthly[mKey].buy_outcome = (buyOutComeWithPanel<0?0:buyOutComeWithPanel)
                setupResponseResult.monthly[mKey].basic_price = fixedBasicPrice
                setupResponseResult.monthly[mKey].saiene_fuka = saieneChargeWithPanel
                setupResponseResult.monthly[mKey].surcharge =  surchargeWithPanel
                setupResponseResult.monthly[mKey].monthly_total = buyOutComeWithPanel + (fixedBasicPrice) + saieneChargeWithPanel + surchargeWithPanel - sellIncome
                setupResponseResult.monthly[mKey].non_panel_total = buyOutComeNoPanel + fixedBasicPrice + nonPanelSaieneCharge + nonPanelSurcharge
                setupResponseResult.monthly[mKey].total_diff = setupResponseResult.monthly[mKey].non_panel_total - setupResponseResult.monthly[mKey].monthly_total
                setupResponseResult.monthly[mKey].total_diff = (setupResponseResult.monthly[mKey].total_diff<0?0:setupResponseResult.monthly[mKey].total_diff)

                if(parseInt(key) >= 10 || parseInt(key) <= 3 ){
                    usageSeason.winter += baseData.season.winter.daily_avg.usage_wh
                    generateSeason.winter +=baseData.season.winter.daily_avg.generate_wh
                    sellIncomeSeason.winter += baseData.season.winter.daily_avg.sell_income
                    buyOutcomeSeason.winter +=  baseData.season.winter.daily_avg.buy_outcome
                }else{
                    usageSeason.summer += baseData.season.summer.daily_avg.usage_wh
                    generateSeason.summer +=baseData.season.summer.daily_avg.generate_wh
                    sellIncomeSeason.summer += baseData.season.summer.daily_avg.sell_income
                    buyOutcomeSeason.summer += baseData.season.summer.daily_avg.buy_outcome
                }
                console.log('baseData.monthly[mKey]-------------------------',baseData.monthly[buyKey])
                sellIncomeTotal += baseData.monthly[buyKey].sell_income
                buyOutcomeTotal +=  (baseData.monthly[buyKey].buy_outcome<0?0:baseData.monthly[buyKey].buy_outcome)
                basicPriceTotal += baseData.basic
                saienePriceTotal += baseData.monthly[buyKey].saiene_outcome
                surchargePriceTotal += baseData.monthly[buyKey].surcharge_outcome
                yearTotal += setupResponseResult.monthly[mKey].monthly_total
                yearNonPanelTotal += setupResponseResult.monthly[mKey].non_panel_total
                totalDiffTotal = yearNonPanelTotal - yearTotal
            }
            setupResponseResult.monthly.year_total.sell_income = sellIncomeTotal
            setupResponseResult.monthly.year_total.buy_outcome = buyOutcomeTotal
            setupResponseResult.monthly.year_total.basic_price = basicPriceTotal
            setupResponseResult.monthly.year_total.saiene_fuka = saienePriceTotal
            setupResponseResult.monthly.year_total.surcharge = surchargePriceTotal
            setupResponseResult.monthly.year_total.monthly_total = yearTotal
            setupResponseResult.monthly.year_total.non_panel_total = yearNonPanelTotal
            setupResponseResult.monthly.year_total.total_diff = totalDiffTotal

            setupResponseResult.daily.summer.use =  ((usageSeason.summer)/1000)/6
            setupResponseResult.daily.summer.generate =  ((generateSeason.summer)/1000)/6
            setupResponseResult.daily.summer.sell_income =  Math.floor(sellIncomeSeason.summer)/6
            setupResponseResult.daily.summer.buy_outcome =  Math.floor(buyOutcomeSeason.summer)/6

            setupResponseResult.daily.winter.use = ((usageSeason.winter)/1000)/6
            setupResponseResult.daily.winter.generate = ((generateSeason.winter)/1000)/6
            setupResponseResult.daily.winter.sell_income = Math.floor(sellIncomeSeason.winter)/6
            setupResponseResult.daily.winter.buy_outcome = Math.floor(buyOutcomeSeason.winter)/6
        }
        return setupResponseResult
    }
    const handleClickBt1 = (isActive: boolean) => {
        console.log('handleClickBt1', isActive)
        isSolarOnlyData = true
        isSolarOnlySet((res) => {
            return true
        })
        loadData()
    }
    const handleClickBt2 = (isActive: boolean) => {
        console.log('handleClickBt2', isActive)
        isSolarOnlyData = false
        isSolarOnlySet((res) => {
            return false
        })
        loadData()
    }

    return (
        <div className={`home container`}>
            <h1 className="tc-yellow text-center fs-24 m-4 pt-2">
                <div className={'fs-12 btn btn-info'}> <FontAwesomeIcon icon={faCalculator} /> {GetModeTitle()}</div>
                <div className={'pt-2'}>電気料金シミュレーション結果</div>
            </h1>
            <div className={'rounded-4 bg-white p-4'}>
                <div className={'d-none d-lg-block'}>
                    <div className={'row mt-2'}>
                        <div className={'col-6'}>
                            <ButtonSelector title={(gls.saveData.simulation_mode == SimulationMode.EV?"太陽光とEVあり":"太陽光パネルのみ搭載時")} active={isSolarOnly} callback={handleClickBt1} />
                        </div>
                        <div className={'col-6'}>
                            <ButtonSelector title={(gls.saveData.simulation_mode == SimulationMode.EV?"太陽光とEV+蓄電池あり":"太陽光パネル+蓄電池搭載時")} active={!isSolarOnly} callback={handleClickBt2} />
                        </div>
                    </div>
                    <div className={'text-center  d-none d-lg-block'}>
                        { (gls.saveData.simulation_mode == SimulationMode.EV) && (
                            (isSolarOnly && (<p className={'mb-0'}>
                                EV【太陽光パネル＋V2H】を搭載した場合の電気料金を試算しています。<br />
                                蓄電池容量の想定： {unitData.batteryCapacityForModeEVLeft} kWh（緊急時を考慮して容量上限の75％設定としています。）
                            </p>))
                        )}
                        { (gls.saveData.simulation_mode == SimulationMode.EV) && (
                            (!isSolarOnly && (<p className={'mb-0'}>
                                EV【太陽光パネル＋蓄電池＋V2H】を搭載した場合の電気料金を試算しています。<br />
                                蓄電池容量の想定： {unitData.batteryCapacityForModeEVRight} kWh（設置型蓄電池容量＋EV、合計{unitData.batteryCapacityForModeEVRight}kWh設定としています。）
                            </p>))
                        )}
                        { (gls.saveData.simulation_mode == SimulationMode.DEFAULT) && (
                            (isSolarOnly && (<p className={'mb-0'}>
                                太陽光発電パネルのみを搭載した場合の電気料金を試算しています。
                            </p>))
                        )}
                        { (gls.saveData.simulation_mode == SimulationMode.DEFAULT) && (
                            (!isSolarOnly && (<p className={'mb-0'}>
                                太陽光発電パネルと蓄電池を搭載した場合の電気料金を試算しています。
                            </p>))
                        )}
                    </div>

                    <div className={' p-4'} >
                        <div className={'d-flex justify-content-center'} >
                            <div>年間 </div>
                            <div className={'pe-1'}>
                                <input className={'resultSagakuValue'} value={Math.floor(resData.monthly.year_total.total_diff).toLocaleString()} readOnly={true} />
                            </div>
                            <div className={'pt-3'}>
                                円
                            </div>
                        </div>
                        <div className={'text-center pt-2'}>光熱費削減に！</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className={'resultLeft'} id={'resultLeft'}>
                            <div className={'px-3'}>
                                <div className={'d-block d-lg-none'}>
                                    <div className={'row mt-1'}>
                                        <div className={'col-12 tc-white bg-blue p-２ fs-xl text-center'}>
                                            太陽光パネルのみ搭載時
                                        </div>
                                    </div>

                                    <div className={' p-2'} >
                                        <div className={'d-flex justify-content-around'} >
                                            <div>年間</div>
                                            <div className={'pe-1'}>
                                                <input className={'resultSagakuValue'} value={(loading == false?Math.floor(resData.monthly.year_total.total_diff).toLocaleString() :'')} readOnly={true} />
                                            </div>
                                            <div className={'pt-3'}>
                                                円
                                            </div>
                                        </div>
                                        <div className={'text-center pt-2'}>光熱費削減に！</div>
                                    </div>
                                </div>

                                <div >
                                    {loading ? (
                                        <p className="loader">ロード中...</p>
                                    ) : (
                                        <>

                                            <div className="d-flex bd-highlight mt-3">
                                                <div className="flex-grow-1 bd-highlight">
                                                    <h2 className="fs-lg ">
                                                        月間電気料金試算
                                                    </h2></div>

                                                </div>
                                                <table className={'resultTable table2'} key={"result1-"+(isSolarOnly?'solar':'battery')+"-table2"} id={"result1-"+(isSolarOnly?'solar':'battery')+"-table2"}>
                                                    <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>売電収入</th>
                                                        <th>買電金額</th>
                                                        <th>基本電気料金</th>
                                                        <th>再エネ賦課金</th>
                                                        <th>燃料調整費</th>
                                                        <th className={'monthlySubtotal'}>月間電気料金計</th>
                                                        <th className={'hitousai'}>非搭載時月間電気料金計<br />（オール電化）</th>
                                                        <th className={'sagaku'}>差額 </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>1月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m1.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m1.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m1.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m1.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m1.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m1.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m1.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m1.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m2.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m2.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m2.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m2.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m2.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m2.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m2.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m2.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m3.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m3.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m3.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m3.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m3.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m3.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m3.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m3.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m4.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m4.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m4.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m4.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m4.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m4.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m4.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m4.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m5.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m5.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m5.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m5.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m5.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m5.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m5.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m5.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m6.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m6.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m6.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m6.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m6.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m6.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m6.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m6.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m7.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m7.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m7.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m7.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m7.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m7.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m7.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m7.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m8.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m8.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m8.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m8.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m8.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m8.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m8.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m8.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m9.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m9.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m9.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m9.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m9.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m9.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m9.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m9.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m10.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m10.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m10.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m10.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m10.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m10.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m10.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m10.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>11月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m11.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m11.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m11.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m11.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m11.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m11.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m11.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m11.total_diff))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12月</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m12.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m12.buy_outcome))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m12.basic_price))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m12.saiene_fuka))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.m12.surcharge))}</td>
                                                        <td className={'monthlySubtotal'}>{formatter.format(Math.floor(resData.monthly.m12.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.m12.non_panel_total))}</td>
                                                        <td className={'sagaku'}>{formatter.format(Math.floor(resData.monthly.m12.total_diff))}</td>
                                                    </tr>
                                                    <tr className={'tableFooting'}>
                                                        <td>年間目安</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.year_total.sell_income))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.year_total.buy_outcome*fixPar))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.year_total.basic_price*fixPar))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.year_total.saiene_fuka*fixPar))}</td>
                                                        <td>{formatter.format(Math.floor(resData.monthly.year_total.surcharge*fixPar))}</td>
                                                        <td className={'monthlySubtotal end'}>{formatter.format(Math.floor(resData.monthly.year_total.monthly_total))}</td>
                                                        <td className={'hitousai'}>{formatter.format(Math.floor(resData.monthly.year_total.non_panel_total))}</td>
                                                        <td className={'sagaku sagaku_result'}>{formatter.format(Math.floor(resData.monthly.year_total.total_diff))}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                        </>
                                    )}
                                </div>
                                <div className="p-3 "></div>
                                <div className={''}>
                                    <ResultGraph resData={resData} />
                                </div>
                                <div className="p-3 "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'row mt-4 pb-5'}>
                <div className="col-4">
                    <Link to="/sales/result1" className="link-no-decoration">
                        <div className="  float-start defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 w-80 text-center fs-lg back_button">
                            <div className="wpx-10 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                            戻る
                        </div>
                    </Link>
                </div>
                <div className="col-8">
                    <a href="javascript:void(0);" onClick={navigatePayment}  className="link-no-decoration">
                        <div className=" float-end defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 w-80 text-center fs-xl">
                            支払額シミュレーションを見る <div className="wpx-20 float-end"><img src={IconArrow} /></div>
                        </div>
                    </a>
                </div>
                <br className={'clear'} />
                <div className="p-3 "></div>
            </div>
        </div>
    )
}
export default SalesResult2

