import {LocalStorage} from "../storage/local_storage";
import {AppReferrer} from "../const/referrer";
import {AppMode} from "../const/app_mode";

export const GetLinkUrl = (type: string) => {
    const gls =  LocalStorage.getInstance()
    let link = ''
    if(type == 'contact'){
        link = 'https://yamadahomes.jp/forms/reform_consultation/'
        if(gls.saveData.home_type == 'reform'){
            link = 'https://yamadahomes.jp/forms/reform_consultation/'
        }else{
            if(gls.saveData.referrer_type == AppReferrer.LEOHOUSE){
                link = 'https://leohouse.resv.jp/'
            }else{
                link = 'https://yamadahomes.resv.jp/'
            }
        }
    }else if('zero_regi'){
        link = 'https://yamadahomes.jp/solar/zeroregi/'
        if(gls.saveData.app_mode==AppMode.YLET){
            link = 'ylet'
        }else{
            if(gls.saveData.referrer_type == AppReferrer.LEOHOUSE){
                link = 'https://www.leohouse.jp/solar/zeroregi/'
            }else{
                link = 'https://yamadahomes.jp/solar/zeroregi/'
            }
        }
    }
    return link
}
