import * as React from 'react';
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC02 from "../img/flow-pc02.png"
import ImgMap from "../img/map.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import ImgFlowPC01 from "../img/flow-pc01.png";

const Q2 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.prefecture)
    const getSelected = (key: string) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: string) => {
        const ls =  LocalStorage.getInstance()
        ls.setPrefecture(value)
        navigate('/q3')
    }
    const prefList = [
        ['','','','','','','','','','','','','','青森','北海道'],
        ['','','','','','','','','','','','','','秋田','岩手',''],
        ['','','','','','','','','','','','','','山形','宮城',''],
        ['','','','','','','','','','','石川','富山','新潟','栃木','福島',''],
        ['','','','','島根','鳥取','兵庫','京都','滋賀','福井','岐阜','長野','群馬','埼玉','茨城',''],
        ['長崎','佐賀','福岡','山口','広島','岡山','大阪','奈良','三重','愛知','静岡','山梨','神奈川','東京','千葉',''],
        ['','熊本','大分','','愛媛','香川','','和歌山','','','','','','','',''],
        ['沖縄','','鹿児島','宮崎','','高知','徳島','','','','','','','','','','']
    ]
    const areaNames = [ '北海道','東北','関東','中部','近畿','中国','四国','九州']
    const areaList = [
        ['北海道'],
        ['青森','岩手','秋田','宮城','山形','福島'],
        ['茨城','栃木','群馬','埼玉','千葉','東京','神奈川'],
        ['新潟','富山','石川','福井','山梨','長野','岐阜','静岡','愛知'],
        ['三重','滋賀','京都','大阪','兵庫','奈良','和歌山'],
        [ '鳥取','島根','岡山','広島','山口'],
        ['徳島','香川','愛媛','高知'],
        ['福岡','佐賀','長崎','熊本','大分','宮崎','鹿児島','沖縄'],
    ]

    const areaListCheck = (pref:string) => {
        let resRow = 0;
        for (let i = 0; i < areaList.length; i++) {
            let list = areaList[i]
            for (let ii = 0; ii < list.length; ii++) {
                if(pref === list[ii]){
                    resRow = i
                }
            }
        }
        let result = ''
        if(resRow === 0 || resRow === 1){
            result = 'tc-blue'
        }else if(resRow === 2){
            result = 'tc-pale-green'
        }else if(resRow === 3){
            result = 'tc-green'
        }else if(resRow === 4){
            result = 'tc-purple'
        }else if(resRow === 5){
            result = 'tc-magenta'
        }else if(resRow === 6){
            result = 'tc-sand'
        }else if(resRow === 7){
            result = 'tc-deep-orange'
        }
        return result
    }

    const PrefMap = () =>  {
        let mapHtmls = []
        for (let i = 0; i < prefList.length; i++) {
            let list = prefList[i]
            mapHtmls.push(<div key={`pref-${i}`}   />)
            for (let ii = 0; ii < list.length; ii++) {
                let html =  (<div key={`pref-${i}-${ii}`} className={'prefListItem '}><p className="">&nbsp;</p></div>)
                if(list[ii] === ''){
                    html =  (<div key={`pref-${i}-${ii}`} className={'d-none d-md-inline-block prefListItem '}><p className="">&nbsp;</p></div>)
                }else{
                    let addClass = ''
                    if(list[ii] === '北海道'){
                        addClass += 'hokkaido'
                    }else  if(list[ii] === '沖縄'){
                        addClass += 'okinawa '
                    }
                    addClass += ' ' + areaListCheck(list[ii])
                    addClass += ' ' + getSelected(list[ii])
                    html = (<div key={`pref-${i}-${ii}`}  onClick={()=>handleAnswer(list[ii])} className={`d-md-inline-block prefListItem rounded-2 prefListItem onClick ${addClass}`}>
                        <p >{list[ii]}</p>
                    </div>)
                }
                mapHtmls.push(html)
            }
        }
        return (<React.Fragment>
            {mapHtmls}
        </React.Fragment>)
    }

    const PrefMapSP = () =>  {
        let mapHtmls = []
        for (let i = 0; i < areaNames.length; i++) {
            let area = areaNames[i]
            let list = areaList[i]
            let html1 =  (<div key={`prefsp-${i}-heading`} className={' '}><p className=" mt-3 prefListItemHeading">{area}</p></div>)
            let html2 = []
            for (let ii = 0; ii < list.length; ii++) {
                if(list[ii] === ''){
                 }else{
                    let addClass = ''
                    addClass += ' ' + areaListCheck(list[ii])
                    addClass += ' ' + getSelected(list[ii])
                    html2.push(<div key={`pref-${i}-${ii}`}  className={`col-6 `}>
                        <div onClick={()=>handleAnswer(list[ii])} className={`mt-1 px-1 py-3 prefListItemSP rounded-2 onClick ${addClass}`}><p >{list[ii]}</p></div>
                    </div>)
                }
            }
            let base = <div className={'row'} key={`prefsp-${i}`}   ><div className={'col-4'} >{html1}</div>
                <div className={'col-8 '} ><div className={'row mt-3'} >{html2}</div>
                </div></div>
            mapHtmls.push(base)
        }
        return (<React.Fragment>
            {mapHtmls}
        </React.Fragment>)
    }

    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC02} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-2 ">
                太陽光発電パネルを設置予定の地域を選択してください。
            </h1>

            <div className="row mt-1">
                <div className="col-md-12 mt-4 mt-md-0">
                    <div className="text-center m-auto d-block d-md-none" >
                        <PrefMapSP />
                    </div>
                    <div className="text-center m-auto d-none d-md-block" style={{maxWidth:'1280px'}}>
                        <PrefMap />
                    </div>
                </div>
            </div>
            <div className="p-1 "></div>
            <div className="m-3">
                <Link to="/q1" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                         <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q2

