
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC03 from "../img/flow-pc03.png"
import IconQ301 from "../img/q3-01.png"
import IconQ302 from "../img/q3-02.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import ImgFlowPC01 from "../img/flow-pc01.png";
import * as React from "react";

const Q3 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.stay_type)
    const getSelected = (key: string) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: string) => {
        const ls =  LocalStorage.getInstance()
        ls.setStayType(value)
        navigate('/q4')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC03} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-3 ">
                在宅形式を選択してください
            </h1>

            <div className="row mt-3 mt-md-5">
                <div className="col-md-6 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer("always")} className={'link-no-decoration ' + getSelected('always')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta pt-4 fs-xl" >１日を通して誰かが<br />
                                在宅していることが多い</h2>
                            <figure className="m-3 "><img src={IconQ301} className="w-55" /></figure>
                        </div>
                    </a>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer("morning_and_night")} className={'link-no-decoration ' + getSelected('morning_and_night')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-blue pt-4 fs-xl" >主に朝と夜間の<br />
                                在宅が多い</h2>
                            <figure className="m-3"><img src={IconQ302} className="w-55" /></figure>
                        </div>
                    </a>
                </div>
            </div>

            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/q2" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                    <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q3

