
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC01 from "../img/flow-pc01.png"
import IconQ101 from "../img/q1-01.png"
import IconQ102 from "../img/q1-02.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import {PayConditon} from "../entity/pay_condition";

const Q1 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.home_type)
    const getSelected = (key: string) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: string) => {
        const ls =  LocalStorage.getInstance()
        ls.setHomeType(value)
        if(value=='new'){
            ls.setPayConditionData({zero_regi_year:15, loan_type:'住宅ローン', loan_year:35, rate:1.0, water_mark:false})
        }else{
            ls.setPayConditionData({zero_regi_year:15, loan_type:'リフォームローン', loan_year:20, rate:2.0, water_mark:false})
        }
        btSelectedSet(value)
        navigate('/q2')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC01} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl m-4">
                シミュレートしたい住まいは<br className={'d-none d-md-block'} />
                どちらに該当しますか？
            </h1>

            <div className="row mt-4">
                <div className="col-md-6 mt-4 mt-md-0">
                    <button onClick={() => handleAnswer("new")} className={'link-no-decoration ' + getSelected('new')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta fs-xxl">新たに取得する住宅</h2>
                            <figure className="m-3"><img src={IconQ101} className="w-30" /></figure>
                            <p className={'fs-sm'}>注文住宅/建売住宅など<br className={'d-none d-md-block'} />
                                住宅と太陽光設備を同時購入し<br className={'d-none d-md-block'} />
                                住宅ローンが適用される住宅</p>
                        </div>
                    </button>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                    <button onClick={() => handleAnswer("reform")} className={'link-no-decoration ' + getSelected('reform')}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-blue fs-xxl">所有中の住宅</h2>
                            <figure className="m-3"><img src={IconQ102} className="w-30" /></figure>
                            <p className={'fs-sm'}>居住中の住まいなどへの太陽光設備追加<br className={'d-none d-md-block'} />
                                リフォームローンが適用される住宅<br className={'d-none d-md-block'} />&nbsp;</p>
                        </div>
                    </button>
                </div>
            </div>

            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/agree" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                        <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} alt={"Arrow"} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q1

