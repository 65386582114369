
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC07 from "../img/flow-pc07.png"
import IconQ701_1 from "../img/q7_01-1.png"
import IconQ701_2 from "../img/q7_01-2.png"
import IconQ701_3 from "../img/q7_01-3.png"
import IconQ701_4 from "../img/q7_01-4.png"
import IconQ701_5 from "../img/q7_01-5.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import * as React from "react";

const Q7 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.house_angle)
    const getSelected = (key: number) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: number) => {
        const ls =  LocalStorage.getInstance()
        ls.setHouseAngle(value)
        navigate('/q8')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC07} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-3 ">
                家の向きを選択してください
            </h1>

            <div className={'d-none d-md-block '}>
                <div className="mt-2 mt-md-5 d-flex justify-content-between">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(90)} className={'link-no-decoration d-inline-block  w-17 ' + getSelected(90)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-magenta pt-2">西</h2>
                            <figure className="m-3"><img src={IconQ701_1} className="w-100" /></figure>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(45)} className={'link-no-decoration d-inline-block  w-17 ' + getSelected(45)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-blue pt-2">南西</h2>
                            <figure className="m-3"><img src={IconQ701_2} className="w-100" /></figure>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(0)} className={'link-no-decoration d-inline-block  w-17 ' + getSelected(0)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-green pt-2">南</h2>
                            <figure className="m-3"><img src={IconQ701_3} className="w-100" /></figure>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(-45)} className={'link-no-decoration d-inline-block  w-17 ' + getSelected(-45)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-blue pt-2">南東</h2>
                            <figure className="m-3"><img src={IconQ701_4} className="w-100" /></figure>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(-90)} className={'link-no-decoration d-inline-block  w-17 ' + getSelected(-90)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-magenta pt-2">東</h2>
                            <figure className="m-3"><img src={IconQ701_5} className="w-100" /></figure>
                        </div>
                    </a>
                </div>
            </div>

            <div className={'d-block d-md-none '}>
                <div className="mt-4 row">
                    <div onClick={() => handleAnswer(90)} className={'selectable mt-3 col-6 ' + getSelected(90)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-magenta pt-2">西</h2>
                            <figure className="m-3"><img src={IconQ701_1} className="w-100" /></figure>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(45)} className={'selectable mt-3 col-6 ' + getSelected(45)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-blue pt-2">南西</h2>
                            <figure className="m-3"><img src={IconQ701_2} className="w-100" /></figure>
                        </div>
                    </div >
                    <div  onClick={() => handleAnswer(0)} className={'selectable mt-3 col-6 ' + getSelected(0)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-green pt-2">南</h2>
                            <figure className="m-3"><img src={IconQ701_3} className="w-100" /></figure>
                        </div>
                    </div >
                    <div onClick={() => handleAnswer(-45)} className={'selectable mt-3 col-6 ' + getSelected(-45)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-blue pt-2">南東</h2>
                            <figure className="m-3"><img src={IconQ701_4} className="w-100" /></figure>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(-90)} className={'selectable mt-3 col-6 ' + getSelected(-90)}>
                        <div className="block rounded-4 bg-white text-center p-2">
                            <h2 className="text-center tc-magenta pt-2">東</h2>
                            <figure className="m-3"><img src={IconQ701_5} className="w-100" /></figure>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/q6" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                    <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q7

