
import Plotly, {Layout, PlotData} from 'plotly.js'
import Plot from "react-plotly.js";
import {useEffect, useRef, useState} from "react";
import {ResponseResults} from "../entity/result_response";

interface Props {
    loanData: { [key: string]: number }
}
const ResultPayGraph50 = (props: Props) => {
    const ref = useRef<HTMLElement>(null);
    const [width, widthSet] = useState(0);
    const {loanData} = props
    useEffect(() => {
        handleResize()
        // console.log('dom width', document?.getElementById('resultLeft')?.clientWidth)
        // console.log('width', ref.current ? ref.current.clientWidth : 0);
    }, [ref.current]);

    const handleResize = () => {
        let targetWidth = document?.getElementsByClassName('graphWrap')[0]?.clientWidth
        let graphWrapInners = document?.getElementsByClassName('graphWrapInner')
        let bodyWidth = document?.body.clientWidth
        if(targetWidth && graphWrapInners){
            if(targetWidth > 100){
                if(graphWrapInners[0]){
                    let graphWrapInner = graphWrapInners[0]
                    let fixWidthDiff = targetWidth - 960
                    let fixLeft = 95 + ((fixWidthDiff/20))
                    let fixWidth = 820 + ((fixWidthDiff)*0.90)
                    console.log("fixWidthDiff", fixWidthDiff)
                    graphWrapInner.setAttribute("style" ,"width:"+fixWidth+'px;left:'+fixLeft+'px')
                    // graphWrapInner.style.left = fixLeft+'px'
                }

                if(targetWidth > bodyWidth){
                    targetWidth = bodyWidth
                }
                if(targetWidth < 960){
                    targetWidth = 960
                }
                //widthSet(targetWidth * 0.96)
                widthSet(targetWidth)
            }
        }

        let targetWrapper = document?.getElementById('graphWrapCover')
        if(targetWrapper){
            targetWrapper.style.width = width+"px"
        }
        console.log('graph resData', loanData)
    }
    window.addEventListener('resize', handleResize)

    const trace1:Partial<PlotData> =  {
        x: ['1年目', '5年目', '10年目', '15年目', '20年目', '25年目', '30年目', '35年目', '50年目'],
        y: [loanData['yearTo10WithLoan'],
            loanData['yearTo10WithLoan'],
            loanData['yearTo10WithLoan'],
            loanData['yearTo15WithLoan'],
            loanData['yearTo25WithLoan'],
            loanData['yearTo25WithLoan'],
            loanData['yearTo35WithLoan'],
            loanData['yearTo35WithLoan'],
            loanData['yearTo50WithLoan'],],
        type: 'scatter',
        hovertemplate: '%{x}: ￥%{y:,.0d}' +'',
        name: '住宅ローン利用時',
        line: {color: '#0081CE'},
    }
    const trace2:Partial<PlotData> =  {
        x: ['1年目', '5年目', '10年目', '15年目', '20年目', '25年目', '30年目', '35年目', '50年目'],
        y: [loanData['yearTo10WithZeroRegi'],
            loanData['yearTo10WithZeroRegi'],
            loanData['yearTo10WithZeroRegi'],
            loanData['yearTo15WithZeroRegi'],
            loanData['yearTo25WithZeroRegi'],
            loanData['yearTo25WithZeroRegi'],
            loanData['yearTo35WithZeroRegi'],
            loanData['yearTo35WithZeroRegi'],
            loanData['yearTo50WithZeroRegi'],],
        type: 'scatter',
        hovertemplate: '%{x}: ￥%{y:,.0d}' +'',
        line: {color: '#E33982'},
        name: 'ZEROレジ利用時',
    }
    const trace3:Partial<PlotData> =  {
        x: ['1年目', '5年目', '10年目', '15年目', '20年目', '25年目', '30年目', '35年目', '50年目'],
        y: [loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],
            loanData['buyYearlyPriceAvgNoMount'],],
        type: 'scatter',
        hovertemplate: '%{x}: ￥%{y:,.0d}' +'',
        line: {color: '#555'},
        name: '非搭載',
    }
    const data = [trace1, trace2, trace3];
    const config = {
        displayModeBar: false, // this is the line that hides the bar.
    };
    const layout1:Partial<Layout> = {
        paper_bgcolor:'rgba(0,0,0,0)',
        plot_bgcolor:'rgba(0,0,0,0)',
        hovermode: 'closest',
        title: '',
        autosize: false,
        showlegend: true,
        margin: {l:50, r:0, t:0, b:0},
        legend: {
            orientation: 'h',
            x: 0.4,
            y: -0.1
        },
        yaxis: {
            fixedrange: true,
            gridcolor: '#ccc',
            tickformat: ',',
            tickwidth: 0,
            tickprefix:'￥'
        },
        xaxis: {
            fixedrange: true,
            gridcolor: '#ccc',
        }
    }
    layout1.height = 220
    if(width > 100){
        layout1.autosize = false
        layout1.width = width
    }
    return (
        <Plot data={data} layout={layout1} config={config} />
    );
}
export default  ResultPayGraph50

