
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC01 from "../../img/flow-pc01.png"
import IconQ101 from "../../img/q1-01.png"
import IconQ102 from "../../img/q1-02.png"
import IconArrow from "../../img/icon_arw01.png"
import {LocalStorage} from "../../storage/local_storage";
import React from "react";
import {GetModeTitle} from "../../util/get_mode_title";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";

const gls =  LocalStorage.getInstance()
const PriceConfigConfirm = () => {
    const navigate = useNavigate();
    let panelList = []
    for (let i = 1; i < 40; i++) {
        panelList.push(<option>{i}</option>)
    }
    const handleAnswer = (value: string) => {
        const ls =  LocalStorage.getInstance()
        ls.setHomeType(value)
        navigate('/q2')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
            </div>
            <h1 className="tc-yellow text-center fs-24 m-4">
                <div className={'fs-12 btn btn-info'}> <FontAwesomeIcon icon={faCalculator} /> {GetModeTitle()}</div>
                <div className={'pt-2'}>各都道府県 電力発電・消費シミュレーション</div>
            </h1>

            <div className={'rounded-4 bg-white p-4'}>
                <h2 className={'fs-xl mb-4 text-center'}>現在の光熱費のご確認</h2>

                <p className={'mb-4'}>【電気・ガス併用】料金について：　※ガスはLPガスにて算出<br />
                    お客様が選択された世帯形式・世帯人数より、戸建て・マンションなど住居形態を限定しない全国平均の光熱費を表示しています。<br />
                    <br />
                    【オール電化】料金について：<br />
                    お客様が選択された世帯形式・世帯人数より、戸建て120㎡（約36坪）　断熱等級４　エコキュートJIS3.8　IH（ｸｯｷﾝｸﾞﾋｰﾀｰ）<br />
                    エアコン3台　三種換気仕様の全国平均の電気料金を表示しています。<br />
                    <br />
                    <span className={'tc-darkblue'} >このシミュレーションは、【オール電化】にて算出した電気料金を基準に太陽光発電システムや蓄電池を導入した場合、お客様にとってどれだけ削減メリットがあるのか、またお得な導入手段（購入または初期費用0円リース）を表示しております。</span>
                    <br />
                    <br />
                    2020年初めより世界情勢の影響から光熱費が高騰しており、表示金額も最新の各社平均的プランにて算出していますが、電気・ガス共に今後も大幅な値上げが予想されますので、あくまで参考値としてご覧ください。<br/>
                    <span className={'tc-magenta'} >*従来、お客様より春・夏・冬の3月分の光熱費領収証等をご持参いただければ、現在に最も近い光熱費算出が可能でしたが、上記理由により昨年度の夏季の光熱費と今年では値上がり幅が大きく、金額相違が発生するため、市場が落ち着くまで上記参考値を基準とさせて頂きます。</span><br />

                </p>
            </div>


            <div className="m-4">
                <Link to="/sales/price-config" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto w-80 text-center fs-xl">
                        光熱費の確認画面へ <div className="wpx-20 float-end"><img src={IconArrow} /></div>
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/sales/top" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto w-30 text-center fs-lg back_button">
                        <div className="wpx-10 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default PriceConfigConfirm

