
import {Link, useLocation} from "react-router-dom";
import IconTableOff from "../img/icon_table_off.png";
import IconTableOn from "../img/icon_table_on.png";
import IconPdf from "../img/icon_pdf.png";
import IconGraphOff from "../img/icon_graph_off.png";
import IconGraphOn from "../img/icon_graph_on.png";
import IconArrow from "../img/icon_arw01.png";
import ButtonSelector from "../component/button_selector";
import React, {ReactElement, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {ResponseResults, ResponseResultsBaseData} from "../entity/result_response";
import sprintf from "sprintf-js"
import {LocalStorage} from "../storage/local_storage";
import ResultPayGraph from "../component/result_pay_graph";
import {GetLinkUrl} from "../util/get_link_url";
import {AppMode} from "../const/app_mode";
import {Buffer} from "buffer";
import {APIURLs} from "../const/urls";
import axios from "axios";
import {PayConditon} from "../entity/pay_condition";

const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY'
});
let currentZeroRegiPayYear = 15 // PRD=15
let currentLoanType = '住宅ローン'
let currentLoanYear = 35 //PRD=35
let currentRate = 1.0
let fixPar = 1.0
let referUUID = ''
let mailDialog: { show: () => void; hide: () => void; } | null = null
interface Props {
    isDefaultBattery: boolean
    isPrint: boolean
}
const Result2 = (props:Props) => {
    const gls =  LocalStorage.getInstance()
    gls.getSaveData()
    gls.getUsageData()
    const [isModeBattery, isModeBatterySet] = useState(props.isDefaultBattery)
    const [appMode, appModeSet] = useState(gls.saveData.app_mode)
    const [isSolarOnly, isSolarOnlySet] = useState(true)
    const [isSolarBattery, isSolarBatterySet] = useState(false)
    const [isGraphMode, isGraphModeSet] = useState(false)
    const [payYears, payYearsSet] = useState(currentZeroRegiPayYear)
    const [loanType, loanTypeSet] = useState(currentLoanType)
    const [loanYears, loanYearsSet] = useState(currentLoanYear)
    const [loanYearSelectList, loanYearSelectListSet] = useState<Array<ReactElement>>()
    const [payYearSelectList, payYearSelectListSet] = useState<Array<ReactElement>>()
    const [loanCalcData, loanCalcDataSet] = useState<{ [key: string]: number }>({})
    const [loanCalcDataBattery, loanCalcDataBatterySet] = useState<{ [key: string]: number }>({})
    // const [loanTypeSelectList, loanTypeSelectListSet] = useState<Array<ReactElement>>()
    const [cheapestRow, cheapestRowSet] = useState(-1)
    const [cheapestRow2, cheapestRow2Set] = useState(-1)
    const [rates, ratesSet] = useState(currentRate)
    const [fukidashi1Text, fukidashi1TextSet] = useState((<span>11年目〜売電価格が<br />50%減で売電収入減</span>))
    const [fukidashi2Text, fukidashi2TextSet] = useState((<span>16年目〜<br />ZEROレジ定額終了</span>))
    const [fukidashi3Text, fukidashi3TextSet] = useState((<span>機器寿命=発電量0<br />通常の電気料金がかかる、ローンの支払いは残る</span>))
    const [fukidashi3TextPlot, fukidashi3TextPlotSet] = useState((<span>太陽光パネルの<br />寿命はおよそ25年</span>))
    const [resultSagakuValue, resultSagakuValueSet] = useState('')
    const [mailAddressInput, mailAddressInputSet] = useState(gls.saveData.default_mail_to)
    const [dialogMessage, dialogMessageSet] = useState((<></>))
    const [dialogMessageState, dialogMessageStateSet] = useState(0)
    const [loading, loadingSet] = useState(true);
    const [resData, resDataSet] = useState<ResponseResults>(ResponseResultsBaseData)
    const [baseData, baseDataSet] =  useState< { [key: string]: number }>({})


    const search = useLocation().search;
    useEffect(() => {
        // loanTypeListSet()
        changeFukidasiSet(isSolarBattery)
        if(gls.saveData.home_type == 'new'){
            currentLoanType = '住宅ローン'
            currentLoanYear = 35 //  prd = 35
            loanYearListSet(35)
            currentRate = 1.0
            ratesSet(currentRate)
        }else{
            currentLoanType = 'リフォームローン'
            currentLoanYear = 20
            loanYearListSet(30)
            currentRate = 2.0
            ratesSet(currentRate)
        }
        loanTypeSet(currentLoanType)
        payYearListSet()

        gls.getSaveData()
        gls.getUsageData()
        gls.getPayCondtionData()
        let payConditionJson = gls.payConditionData
        console.log('payConditionJson', payConditionJson)
        if(payConditionJson.zero_regi_year){
            currentZeroRegiPayYear = payConditionJson.zero_regi_year
            payYearsSet(currentZeroRegiPayYear)
        }
        if(payConditionJson.loan_type){
            currentLoanType = payConditionJson.loan_type
            loanTypeSet(currentLoanType)
        }
        if(payConditionJson.loan_year){
            currentLoanYear = payConditionJson.loan_year
            loanYearsSet(currentLoanYear)
        }
        if(payConditionJson.rate){
            currentRate = payConditionJson.rate
            ratesSet(currentRate)
        }
        if(payConditionJson.loan_type == '住宅ローン'){
            loanYearListSet(35)
        }else{
            loanYearListSet(30)
        }
        if(isModeBattery){
            isSolarOnlySet(false)
            isSolarBatterySet(true)
            loadData(true)
        }else{
            isSolarOnlySet(true)
            isSolarBatterySet(false)
            loadData(false)
        }

    }, []);

    const dataChange = () => {
        loadingSet(true)
    }
    const loadData = (isBattery:boolean) => {
        createResultData(isBattery)
        loadingSet(false)
    }

    const handleZeroRegiLink = () => {
        const link = GetLinkUrl('zero_regi')
        if(link != 'ylet'){
            window.open(link ,'_blank')
        }else{
            // @ts-ignore
            ylet.linkToZeroRegi()
        }
    }

    const pmt = (ir:number, np:number, pv:number, fv:number, type:number) => {
        /*
         * ir   - interest rate per month
         * np   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        let pmtRes, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (ir === 0)
            return -(pv + fv)/np;

        pvif = Math.pow(1 + ir, np);
        pmtRes = - ir * (pv * pvif + fv) / (pvif - 1);

        if (type === 1)
            pmtRes /= (1 + ir);

        return pmtRes;
    }

    const createResultData = (isBattery: boolean) => {
        let loanCalcBase = getLoanResultData(isBattery)
        const list = [loanCalcBase['totalWithLoan'],loanCalcBase['totalWithZeroRegi'],loanCalcBase['totalWithNoMount']]
        const getMin = Math.min(...list)
        const getMinIndex = list.indexOf(getMin)
        console.log('cheapestRow', getMinIndex, getMin)
        cheapestRowSet(getMinIndex)
        loanCalcDataSet(loanCalcBase)

        let loanCalcBattery = getLoanResultData(true)
        const listBattery = [loanCalcBattery['totalWithLoan'],loanCalcBattery['totalWithZeroRegi'],loanCalcBattery['totalWithNoMount']]
        const getMinBattery = Math.min(...listBattery)
        const getMinIndexBattery = listBattery.indexOf(getMinBattery)
        console.log('cheapestRow2', getMinIndexBattery, getMinBattery)
        cheapestRow2Set(getMinIndexBattery)
        loanCalcDataBatterySet(loanCalcBattery)

        changeFukidasiSet(isBattery)
    }

    const getLoanResultData = (isBattery: boolean) => {
        fixPar = gls.saveData.monthly_total_all_elec_cost / gls.saveData.monthly_total_all_elec_cost_calc
        if(isNaN(fixPar)){
            fixPar = 1.0
        }
        console.log('createResultData isBattery, currentZeroRegiPayYear, currentLoanYear, currentRate', isBattery, currentZeroRegiPayYear, currentLoanYear, currentRate)
        let loanCalc:{ [key: string]: number } = {}
        const showBaseYear = 35

        let systemSolarPower = gls.usageData.systemSolarPower
        let unitData = gls.usageData.unitDefines
        let totalData = gls.usageData
        let baseData = gls.usageData.summaryWithAll

        let baseDataWithBattery = gls.usageData.summaryWithAllWithBattery
        let noBatteryBase = gls.usageData.summaryWithAll
        let noPanelBase = gls.usageData.summaryWithAllNoPanelNoBattery

        loanCalc['buySystemPrice'] = unitData['solarKwhUnitPrice'] * systemSolarPower
        if(isBattery){
            baseData = baseDataWithBattery
            loanCalc['buySystemPrice'] = unitData['solarKwhUnitPrice'] * systemSolarPower + unitData['batteryUnitPrice']
            if(gls.saveData.condition_price_solar_battery > 0){
                loanCalc['buySystemPrice'] =  loanCalc['buySystemPrice'] - (gls.saveData.condition_price_solar_battery)
            }
        }else{
            if(gls.saveData.condition_price_solar > 0){
                loanCalc['buySystemPrice'] =  loanCalc['buySystemPrice'] - (gls.saveData.condition_price_solar)
            }
        }
        console.log('baseData-------------------',baseData)
        console.log('unitData-------------------',unitData)
        let baseZeroRegiUnitPanelOnly = unitData['zeroRegiPricePanelOnly15']
        let baseZeroRegiUnitWithBattery = unitData['zeroRegiPriceWithBattery15']
        if(currentZeroRegiPayYear<= 10){
            baseZeroRegiUnitPanelOnly = unitData['zeroRegiPricePanelOnly10']
            baseZeroRegiUnitWithBattery = unitData['zeroRegiPriceWithBattery10']
        }
        let baseZeroRegiUnit = baseZeroRegiUnitPanelOnly
        if(isBattery){
            baseZeroRegiUnit = baseZeroRegiUnitWithBattery
        }
        let basicPriceYearly = (baseData.basic) * 12
        let buyOutComeFullTotalWithPanel = (baseData.yearly.buy_outcome*fixPar)
        let buyOutComeFullTotalNoBatteryWithPanel = (noBatteryBase.yearly.buy_outcome*fixPar)
        let buyOutComeFullTotalNoBatteryNoPanel = (noPanelBase.yearly.buy_outcome*fixPar)
        loanCalc['loanYear'] = currentLoanYear
        loanCalc['zeroRegiYear'] = currentZeroRegiPayYear
        loanCalc['rate'] = currentRate
        loanCalc['yearlyPayBack'] = pmt((loanCalc['rate']/100),loanCalc['loanYear'], -1*loanCalc['buySystemPrice'], 0, 0)
        loanCalc['monthlyPayBack'] = loanCalc['yearlyPayBack'] / 12
        loanCalc['yearlyCostWithElecWithPayBack'] = loanCalc['yearlyPayBack']+buyOutComeFullTotalWithPanel
        loanCalc['yearlyCostWithElecWithPayBackNoBatteryWithPanel'] = loanCalc['yearlyPayBack']+buyOutComeFullTotalNoBatteryWithPanel
        loanCalc['yearlyCostWithElecWithPayBackNoBatteryNoPanel'] = loanCalc['yearlyPayBack']+buyOutComeFullTotalNoBatteryNoPanel
        loanCalc['yearlyCostWithElecNoPayBack'] = buyOutComeFullTotalWithPanel
        loanCalc['yearlyCostWithElecNoPayBackNoBatteryWithPanel'] = buyOutComeFullTotalNoBatteryWithPanel
        loanCalc['yearlyCostWithElecNoPayBackNoBatteryNoPanel'] = buyOutComeFullTotalNoBatteryNoPanel
        loanCalc['totalPayment'] = loanCalc['yearlyPayBack'] * loanCalc['loanYear']
        loanCalc['monthlyPaymentWithZeroRegiSubscribed'] = loanCalc['yearlyCostWithElecNoPayBack']/12 + baseZeroRegiUnit
        loanCalc['yearlyPaymentWithZeroRegiSubscribed'] = loanCalc['monthlyPaymentWithZeroRegiSubscribed'] * 12
        loanCalc['monthlyPaymentWithZeroRegiCompleted'] =  loanCalc['yearlyCostWithElecNoPayBack']/12
        loanCalc['monthlyPaymentWithZeroRegiCompleted'] = (loanCalc['monthlyPaymentWithZeroRegiCompleted']<0?0:loanCalc['monthlyPaymentWithZeroRegiCompleted'])
        loanCalc['yearlyPaymentWithZeroRegiCompleted'] = loanCalc['monthlyPaymentWithZeroRegiCompleted'] * 12
        loanCalc['yearlyPaymentWithZeroRegiCompletedNoBatteryWithPanel'] = buyOutComeFullTotalNoBatteryWithPanel
        loanCalc['yearlyPaymentWithZeroRegiCompletedNoBatteryNoPanel'] = buyOutComeFullTotalNoBatteryNoPanel
        loanCalc['buyUnitPriceAvg'] = (baseData.yearly.buy_outcome*fixPar) / (baseData.yearly.usage_wh/1000)
        loanCalc['buyDailyPriceAvg'] = (baseData.yearly.buy_outcome*fixPar ) / 365
        loanCalc['buyDailyWhAvg'] = baseData.yearly.usage_wh / 365
        loanCalc['yearlySellIncomeBase'] = baseData.yearly.sell_income
        loanCalc['yearlySellIncomeBaseFrom10Year'] = baseData.yearly.sell_income * 0.5
        loanCalc['yearlySellIncomeNoBattery'] = noBatteryBase.yearly.sell_income
        loanCalc['yearlySellIncomeNoBatteryFrom10Year'] = noBatteryBase.yearly.sell_income * 0.5
        let yearlyNonpanelBuyWh = baseData.yearly.usage_wh

        let saieneYearlyWithPanel = baseData.yearly.saiene_outcome
        let saieneYearlyNoBatteryWithPanel = noBatteryBase.yearly.saiene_outcome
        let saieneYearlyNoBatteryNoPanel = noPanelBase.yearly.saiene_outcome

        let surchargeYearlyWithPanel = baseData.yearly.surcharge_outcome
        let surchargeYearlyNoBatteryWithPanel = noBatteryBase.yearly.surcharge_outcome
        let surchargeYearlyNoBatteryNoPanel = noPanelBase.yearly.surcharge_outcome

        //let saieneYearlyNoPanel = (yearlyNonpanelBuyWh/1000) * unitData.saieneChargeUnitPrice
        //let surchargeYearlyNoPanel = (yearlyNonpanelBuyWh/1000) * unitData.surchargeUnitPrice
        loanCalc['buyYearlyPriceAvgNoMount'] = saieneYearlyNoBatteryNoPanel + surchargeYearlyNoBatteryNoPanel+basicPriceYearly + (noPanelBase.yearly.buy_outcome*fixPar)
        console.log('loanCalc-------------------',loanCalc, )

        let remainLoanYear = currentLoanYear+0 // at 35
        let to10year = (currentLoanYear<10?currentLoanYear:10)
        let remainTo10year = 10 - to10year
        loanCalc['yearTo10WithLoan'] = saieneYearlyWithPanel + surchargeYearlyWithPanel + basicPriceYearly +
            (((loanCalc['yearlyCostWithElecWithPayBack']) * to10year) +
            ((loanCalc['yearlyCostWithElecNoPayBack']) * remainTo10year)) / 10
            -loanCalc['yearlySellIncomeBase']

        remainLoanYear = remainLoanYear - to10year // at 25
        let to15year = (remainLoanYear<5?remainLoanYear:5)
        let remainTo15year = 5 - to15year
        loanCalc['yearTo15WithLoan'] = saieneYearlyWithPanel + surchargeYearlyWithPanel + basicPriceYearly +
            (((loanCalc['yearlyCostWithElecWithPayBack']) * to15year) +
            ((loanCalc['yearlyCostWithElecNoPayBack']) * remainTo15year)) / 5
            -loanCalc['yearlySellIncomeBaseFrom10Year']

        remainLoanYear = remainLoanYear - to15year // at 20
        let to25year = (remainLoanYear<10?remainLoanYear:10)
        let remainTo25year = 10 - to25year
        loanCalc['yearTo25WithLoan'] = saieneYearlyNoBatteryWithPanel + surchargeYearlyNoBatteryWithPanel + basicPriceYearly +
            (((loanCalc['yearlyCostWithElecWithPayBackNoBatteryWithPanel']) * to25year) +
            ((loanCalc['yearlyCostWithElecNoPayBackNoBatteryWithPanel']) * remainTo25year)) / 10
            -loanCalc['yearlySellIncomeNoBatteryFrom10Year']

        remainLoanYear = remainLoanYear - to25year // at 10
        let to35year = (remainLoanYear<10?remainLoanYear:10)
        let remainTo35year = 10 - to35year
        loanCalc['yearTo35WithLoan'] =  saieneYearlyNoBatteryNoPanel + surchargeYearlyNoBatteryNoPanel + basicPriceYearly +
            (((loanCalc['yearlyCostWithElecWithPayBackNoBatteryNoPanel']) * to35year) +
            ((loanCalc['yearlyCostWithElecNoPayBackNoBatteryNoPanel']) * remainTo35year)) / 10

        loanCalc['yearTo10WithZeroRegi'] = saieneYearlyWithPanel + surchargeYearlyWithPanel + basicPriceYearly +
            loanCalc['yearlyPaymentWithZeroRegiSubscribed']-(loanCalc['yearlySellIncomeBase'])
        loanCalc['yearTo15WithZeroRegi'] = saieneYearlyWithPanel + surchargeYearlyWithPanel + basicPriceYearly +
            (currentZeroRegiPayYear>10?loanCalc['yearlyPaymentWithZeroRegiSubscribed']:loanCalc['yearlyPaymentWithZeroRegiCompleted'])-(loanCalc['yearlySellIncomeBaseFrom10Year'])
        loanCalc['yearTo25WithZeroRegi'] = saieneYearlyNoBatteryWithPanel + surchargeYearlyNoBatteryWithPanel + basicPriceYearly +
            loanCalc['yearlyPaymentWithZeroRegiCompletedNoBatteryWithPanel']-(loanCalc['yearlySellIncomeNoBatteryFrom10Year'])
        loanCalc['yearTo35WithZeroRegi'] = saieneYearlyNoBatteryNoPanel + surchargeYearlyNoBatteryNoPanel + basicPriceYearly +
            loanCalc['yearlyPaymentWithZeroRegiCompletedNoBatteryNoPanel']

        loanCalc['totalWithLoan'] = (loanCalc['yearTo10WithLoan']*10)+(loanCalc['yearTo15WithLoan']*5)+(loanCalc['yearTo25WithLoan']*10)+(loanCalc['yearTo35WithLoan']*10)
        //const remainZeroRegiYear = (showBaseYear-currentZeroRegiPayYear)<0?0:showBaseYear-currentZeroRegiPayYear
        loanCalc['totalWithZeroRegi'] = (loanCalc['yearTo10WithZeroRegi']*10)+(loanCalc['yearTo15WithZeroRegi']*5)+(loanCalc['yearTo25WithZeroRegi']*10)+(loanCalc['yearTo35WithZeroRegi']*10)
        loanCalc['totalWithNoMount'] = (loanCalc['buyYearlyPriceAvgNoMount']*showBaseYear)

        return loanCalc
    }
    const changeFukidasiSet = (isBattery:boolean) => {
        if(isBattery){
            fukidashi1TextSet((<span>11年目〜売電価格が<br />50%減で売電収入減</span>))
            fukidashi2TextSet((<span>{(currentZeroRegiPayYear==10?'11':'16')}年目〜<br />ZEROレジ定額終了<br />蓄電池の寿命はおよそ15年</span>))
            fukidashi3TextSet((<span>機器寿命=発電量0<br />通常の電気料金がかかる、ローンの支払いは残る</span>))
            fukidashi3TextPlotSet((<span>太陽光パネルの<br />寿命はおよそ25年</span>))
        }else{
            fukidashi1TextSet((<span>11年目〜売電価格が<br />50%減で売電収入減</span>))
            fukidashi2TextSet((<span>{(currentZeroRegiPayYear==10?'11':'16')}年目〜<br />ZEROレジ定額終了</span>))
            fukidashi3TextSet((<span>機器寿命=発電量0<br />通常の電気料金がかかる、ローンの支払いは残る</span>))
            fukidashi3TextPlotSet((<span>太陽光パネルの<br />寿命はおよそ25年</span>))
        }
    }

    const handleClickBt1 = (isActive: boolean) => {
        console.log('handleClickBt1', isActive)
        if(isSolarOnly){
            return
        }
        isSolarOnlySet(isActive)
        isSolarBatterySet(!isActive)
        changeFukidasiSet(!isActive)
        dataChange()
        loadData(false)
    }
    const handleClickBt2 = (isActive: boolean) => {
        console.log('handleClickBt2', isActive)
        if(isSolarBattery){
            return
        }
        isSolarOnlySet(!isActive)
        isSolarBatterySet(isActive)
        changeFukidasiSet(isActive)
        dataChange()
        loadData(true)
    }
    const changeMode = () => {
        isGraphModeSet(!isGraphMode)
    }
    const fileDL = () => {
        let saveDataJsonStr = JSON.stringify(gls.saveData);
        let usageDataJsonStr = JSON.stringify(gls.usageData);
        const reqUrl = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}`+APIURLs.ADDRES
        let paymentCondition:PayConditon = {water_mark: false, zero_regi_year:currentZeroRegiPayYear, loan_type:currentLoanType, loan_year:currentLoanYear, rate:currentRate}
        gls.setPayConditionData(paymentCondition)
        let paymentConditionJsonStr = JSON.stringify(paymentCondition);
        let sendData = {
            'con':saveDataJsonStr,
            'pc':paymentConditionJsonStr,
            'cal':usageDataJsonStr
        }
        axios.post(reqUrl, sendData).then((response) => {
            console.log(response.data)
            if(response.data.result == true){
                referUUID = response.data.uuid
                if(gls.saveData.app_mode==AppMode.YLET){
                    if(mailDialog == null){
                        // @ts-ignore
                        mailDialog = new bootstrap.Modal(document.getElementById('mailDialog'), {
                            keyboard: false
                        })
                    }
                    if(mailDialog != null){
                        dialogMessageSet((<>メールアドレスを入力してください。<br />ダウンロードURLが送信されます。</>))
                        dialogMessageStateSet(0)
                        mailDialog.show()
                    }
                }else{
                    setTimeout(
                        ()=>{
                            window.open( "/pdf_download?did="+referUUID)
                        }
                    )
                }
            }else{
                dialogMessageSet(<>データの一時保存に失敗しました</>)
                dialogMessageStateSet(10)
                if(mailDialog != null){
                    mailDialog.show()
                }
            }
        })
    }
    const mailSend = () => {
        // @ts-ignore
        if(mailDialog != null){
            mailDialog.hide()
        }
        if(referUUID != ''){
            let sendData = {
                'mailto':mailAddressInput,
                'uuid': referUUID
            }
            if(mailAddressInput){
                const reqUrl = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}`+APIURLs.PDFMAIL
                axios.post(reqUrl, sendData).then((response) => {
                    console.log(response.data)
                    if(response.data.result == true){
                        dialogMessageStateSet(1)
                        dialogMessageSet(<>ご入力されたメールアドレスに太陽光シミュレーターの計算結果を送信しました。</>)
                        if(mailDialog != null){
                            mailDialog.show()
                        }
                    }else{
                        if(response.data.error == 'invalid_email'){
                            console.log('response.data.error', response.data.error, mailDialog)
                            dialogMessageStateSet(10)
                            dialogMessageSet(<>メールアドレスを半角英数のみで正しく入力してください。</>)
                            if(mailDialog != null){
                                mailDialog.show()
                            }
                        }
                    }
                })
            }else{
                dialogMessageStateSet(10)
                dialogMessageSet(<>メールアドレスを半角英数のみで正しく入力してください。</>)
                if(mailDialog != null){
                    mailDialog.show()
                }
            }
        }
    }
    const changeInput = (elem: HTMLInputElement) => {
        const ls =  LocalStorage.getInstance()
        let num = parseInt(elem.value)
        let numStr = ''
        if(!isNaN(num)){
            numStr = num.toString()
        }
    }
    const changePayYears = (elem: HTMLSelectElement) => {
        currentZeroRegiPayYear = parseInt(elem.value)
        payYearsSet(parseInt(elem.value))
        loadData(isSolarBattery)
    }
    const changeLoanType = (elem: HTMLSelectElement) => {
        currentLoanType = elem.value
        if(elem.value == 'new'){
            loanYearListSet(35)
        }else{
            loanYearListSet(30)
        }
        loadData(isSolarBattery)
    }
    const changeLoanYears = (elem: HTMLSelectElement) => {
        currentLoanYear = parseInt(elem.value)
        loanYearsSet(parseInt(elem.value))
        loadData(isSolarBattery)
    }
    const changeRate = (elem: HTMLSelectElement) => {
        currentRate = parseFloat(elem.value)
        ratesSet(currentRate)
        loadData(isSolarBattery)
    }

    const loanYearListSet = (maxNum:number) => {
        console.log('loanYearListSet', maxNum)
        const list:Array<ReactElement> = []
        for(let i=1; i<=maxNum; i++){
            let selected = false
            if(currentLoanYear > maxNum ){
                if(i == maxNum){
                    selected = true
                }
            }
            if(i == currentLoanYear) {
                selected = true
            }
            list.push((<option value={i} selected={selected} >{i}</option>))
        }
        loanYearSelectListSet(prev => {
            return list;
        } )

        if(currentLoanYear > maxNum ){
            currentLoanYear = maxNum
            loadData(isSolarBattery)
        }
    }
    /*const loanTypeListSet = () => {
        const list:Array<ReactElement> = []
        list.push((<option value="new">住宅ローン</option>))
        list.push((<option value="reform">リフォームローン</option>))
        loanTypeSelectListSet(list)
    }*/
    const payYearListSet = () => {
        const list:Array<ReactElement> = []
        list.push((<option value={10}>{10}</option>))
        list.push((<option value={15}>{15}</option>))
        payYearSelectListSet(list)
    }


    const rateList = []
    for(let i=5; i<=50; i+=5){
        rateList.push((<option value={sprintf.sprintf("%.1f",i/10)}>{sprintf.sprintf("%.1f",i/10)}</option>))
    }

    return (
        <div className={``}>
            <div className="modal" style={{"zIndex":"100000000000000"}} id={"mailDialog"} >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">PDFダウンロードURL送信</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"　aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className={(dialogMessageState==10?'alert-danger':'')}>{dialogMessage}</p>
                            {dialogMessageState==0 && (<input className={'form-control'} onChange={(e)=>{mailAddressInputSet(e.target.value)}} type={'input'}  value={mailAddressInput} />)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
                            {dialogMessageState==0 && (<button type="button" onClick={()=>{mailSend()}} className="btn btn-primary">送信</button>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`result2 `}>
                <div className="mt-4 pt-4 pt-lg-1">
                    <h1 className="fs-xl pt-2 ps-2 mt-4 text-center text-lg-start">
                        年間支払額<br className={'d-block d-lg-none'} />シミュレーション結果
                    </h1>
                    <div className={'resultLeft'}>
                        <div className={'px-3 d-none d-lg-block'}>
                            <div className={'row mt-2'}>
                                <div className={'col-6'}>
                                    <ButtonSelector title={"太陽光パネルのみ搭載時"} active={isSolarOnly} callback={handleClickBt1} />
                                </div>
                                <div className={'col-6'}>
                                    <ButtonSelector title={"太陽光パネル+蓄電池搭載時"} active={isSolarBattery} callback={handleClickBt2} />
                                </div>
                            </div>
                            <div className={'text-center'}>
                                {isSolarOnly && (<p>太陽光発電パネルのみを搭載した場合の電気料金を試算しています。</p>)}
                                {isSolarBattery && (<p>太陽光発電パネルと蓄電池を搭載した場合の電気料金を試算しています。</p>)}
                            </div>
                        </div>
                    </div>
                    <div className={'resultRight d-none d-lg-block'}>
                        <div className={'px-2'}>
                            <Button onClick={fileDL} className={'dlButton'} ><img src={IconPdf} alt={'結果PDFダウンロード'} />  結果PDFダウンロード</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'result2 clearfix'}>
                {loading ? (
                    <div className={"pt-5"}>
                        <p className="loader pt-5">ロード中...</p>
                    </div>
                ) : (
                    <div className={' px-2'}>
                        <div className="border border-blue border-3 rounded-4">
                            <div className="d-block d-lg-none tc-white bg-blue rounded border-3 rounded-3 px-4 pt-2">
                                <div className="  ">
                                    <p className="text-center fs-xl m-1 pb-2">条件選択
                                    </p>
                                </div>
                            </div>
                            <div className="d-lg-flex justify-content-lg-between px-3 pb-2 px-lg-0 pb-lg-0">
                                <div className="d-none d-lg-inline-block tc-white bg-blue rounded-end border-3 rounded-3 px-4 pt-2">
                                    <div className="  ">
                                        <p className="fs-14 m-1 pb-2">条件選択
                                        </p>
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <div className="row justify-content-between justify-content-lg-start">
                                        <div className={'col-auto fs-sm pt-0'}>
                                            ZEROレジ<br className={'d-none d-lg-block'} />
                                            支払い年数
                                        </div>
                                        <div className="col-auto pt-2">
                                            <select defaultValue={payYears} key={'payYears-'+(isSolarOnly?'solar':'battery')} onChange={ e => changePayYears(e.target)}>
                                                {payYearSelectList}
                                            </select>
                                            <span className="col-auto fs-sm"> 年</span></div>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <div className="row justify-content-between justify-content-lg-start">
                                        <div className={'col-auto fs-sm pt-0'}>
                                            ローン種別
                                        </div>
                                        <div className="col-auto">
                                               {loanType}
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <div className="row justify-content-between justify-content-lg-start">
                                        <div className={'col-auto fs-sm pt-0'}>
                                            ローン年数
                                        </div>
                                        <div className="col-auto">
                                            <select defaultValue={currentLoanYear} key={'loanYears-'+(isSolarOnly?'solar':'battery')} onChange={ e => changeLoanYears(e.target)}>
                                                {loanYearSelectList}
                                            </select>
                                            <span className="col-auto fs-sm"> 年</span></div>
                                    </div>
                                </div>
                                <div className="pt-3 pe-lg-3">
                                    <div className="row justify-content-between justify-content-lg-start">
                                        <div className={'col-auto fs-sm pt-0'}>
                                            固定金利
                                        </div>
                                        <div className="col-auto">
                                            <select defaultValue={sprintf.sprintf("%.1f",currentRate)} key={'rateList-'+(isSolarOnly?'solar':'battery')} onChange={ e => changeRate(e.target)}>
                                                {rateList}
                                            </select>
                                            <span className="col-auto fs-sm"> %</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={'d-block d-lg-none mt-4'}>
                            <div className={'row mt-1'}>
                                <div className={'col-12 tc-white bg-blue p-2 fs-xl text-center  mx-1'}>
                                    太陽光パネルのみ搭載時
                                </div>
                            </div>
                            <table className={'result3Table noCheckLine mt-4'}>
                                <thead>
                                <th>

                                </th>
                                <td className={'row1 ' + (cheapestRow==0&&'cheapestColHead')}>
                                    住宅ローン<br />利用時<br />
                                    ローン相当額<br />
                                    +電気料金
                                </td>
                                <td className={'row2 ' + (cheapestRow==1&&'cheapestColHead')}>
                                    ZEROレジ<br />利用時<br />
                                    定額利用料<br />
                                    +電気料金
                                </td>
                                <td className={'row3 ' + (cheapestRow==2&&'cheapestColHead')}>
                                    非搭載<br />
                                    （オール<br />電化）
                                </td>
                                </thead>
                                <tbody>
                                <tr className={''}>
                                    <th className={'position-relative'}>
                                        1〜10<br />年目
                                    </th>
                                    <td className={'row1 ' + (cheapestRow==0&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo10WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow==1&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo10WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow==2&&'cheapestCol')}>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''} >
                                    <th rowSpan={2} className={'position-relative'}>11〜15<br />年目</th>
                                    <td colSpan={3} className={'bg-green tc-white'}>11年目〜売電価格が50%減で売電収入減</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'row1 ' + (cheapestRow==0&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo15WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow==1&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo15WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow==2&&'cheapestCol')}>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''} >
                                    <th rowSpan={2} className={'position-relative'}>16〜25<br />年目</th>
                                    <td colSpan={3} className={'bg-green tc-white'}>16年目〜ZEROレジ定額終了</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'row1 ' + (cheapestRow==0&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo25WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow==1&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo25WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow==2&&'cheapestCol')}>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''} >
                                    <th rowSpan={2} >26〜35<br />年目</th>
                                    <td colSpan={3} className={'bg-green tc-white'}>機器寿命=発電量0<br />
                                        通常の電気料金がかかる、<br />ローンの支払いは残る</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'row1 ' + (cheapestRow==0&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo35WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow==1&&'cheapestCol')}>{formatter.format(loanCalcData['yearTo35WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow==2&&'cheapestCol')}>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''}>
                                    <th>総額</th>
                                    <td className={'row1 ' + (cheapestRow==0&&'cheapestColEnd')}>{formatter.format(loanCalcData['totalWithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow==1&&'cheapestColEnd')}>{formatter.format(loanCalcData['totalWithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow==2&&'cheapestColEnd')}>{formatter.format(loanCalcData['totalWithNoMount'])}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div className={'row'}>
                                <div className={'col-12 mt-3'}>
                                    <p className={'fs-xs text-center mt-4'}>金額メリットだけじゃない！サブスク型太陽光発電システム</p>
                                    <a href="javascript:void(0);" onClick={handleZeroRegiLink} target={gls.saveData.app_mode!=AppMode.YLET?'_blank':'_self'} className="d-block link-no-decoration m-auto">
                                        <div className="defbtn d-block rounded-5 bg-yellow py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1'} >「ZEROレジ」詳しくはこちら</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 pt-lg-2 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                {appMode=='default'&&(<div className="col-12  mt-3">
                                    <p className={'fs-xs text-center mt-4'}>更に詳しいシミュレーションをご希望の方はこちら</p>
                                    <a href={GetLinkUrl('contact')} target="_blank" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-white py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1'} >お問い合わせ</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>)}
                            </div>


                            <div className={'row mt-5'}>
                                <div className={'col-12 tc-white bg-blue p-2 fs-xl text-center  mx-1'}>
                                    太陽光パネル+蓄電池搭載時
                                </div>
                            </div>
                            <table className={'result3Table noCheckLine mt-4'}>
                                <thead>
                                <th>

                                </th>
                                <td className={'row1 ' + (cheapestRow2==0&&'cheapestColHead')}>
                                    住宅ローン<br />利用時<br />
                                    ローン相当額<br />
                                    +電気料金
                                </td>
                                <td className={'row2 ' + (cheapestRow2==1&&'cheapestColHead')}>
                                    ZEROレジ<br />利用時<br />
                                    定額利用料<br />
                                    +電気料金
                                </td>
                                <td className={'row3 ' + (cheapestRow2==2&&'cheapestColHead')}>
                                    非搭載<br />
                                    （オール<br />電化）
                                </td>
                                </thead>
                                <tbody>
                                <tr className={''}>
                                    <th className={'position-relative'}>
                                        1〜10<br />年目
                                    </th>
                                    <td className={'row1 ' + (cheapestRow2==0&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo10WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow2==1&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo10WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow2==2&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''} >
                                    <th rowSpan={2} className={'position-relative'}>11〜15<br />年目</th>
                                    <td colSpan={3} className={'bg-green tc-white'}>11年目〜売電価格が50%減で売電収入減</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'row1 ' + (cheapestRow2==0&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo15WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow2==1&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo15WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow2==2&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''} >
                                    <th rowSpan={2} className={'position-relative'}>16〜25<br />年目</th>
                                    <td colSpan={3} className={'bg-green tc-white'}>16年目〜ZEROレジ定額終了</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'row1 ' + (cheapestRow2==0&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo25WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow2==1&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo25WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow2==2&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''} >
                                    <th rowSpan={2} >26〜35<br />年目</th>
                                    <td colSpan={3} className={'bg-green tc-white'}>機器寿命=発電量0<br />
                                        通常の電気料金がかかる、<br />ローンの支払いは残る</td>
                                </tr>
                                <tr className={''}>
                                    <td className={'row1 ' + (cheapestRow2==0&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo35WithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow2==1&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['yearTo35WithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow2==2&&'cheapestCol')}>{formatter.format(loanCalcDataBattery['buyYearlyPriceAvgNoMount'])}</td>
                                </tr>
                                <tr className={''}>
                                    <th>総額</th>
                                    <td className={'row1 ' + (cheapestRow2==0&&'cheapestColEnd')}>{formatter.format(loanCalcDataBattery['totalWithLoan'])}</td>
                                    <td className={'row2 ' + (cheapestRow2==1&&'cheapestColEnd')}>{formatter.format(loanCalcDataBattery['totalWithZeroRegi'])}</td>
                                    <td className={'row3 ' + (cheapestRow2==2&&'cheapestColEnd')}>{formatter.format(loanCalcDataBattery['totalWithNoMount'])}</td>
                                </tr>
                                </tbody>
                            </table>

                            <div className={'row'}>

                                <div className={'px-4 mt-5 '}>
                                    <Button onClick={fileDL} className={'dlButton'} ><img src={IconPdf} alt={'結果PDFダウンロード'} />  結果PDFダウンロード</Button>
                                </div>

                                <div className={'col-12 mt-3'}>
                                    <p className={'fs-xs text-center mt-4'}>金額メリットだけじゃない！サブスク型太陽光発電システム</p>
                                    <a href="javascript:void(0);" onClick={handleZeroRegiLink} target={gls.saveData.app_mode!=AppMode.YLET?'_blank':'_self'} className="d-block link-no-decoration m-auto">
                                        <div className="defbtn d-block rounded-5 bg-yellow py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1'} >「ZEROレジ」詳しくはこちら</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 pt-lg-2 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                {appMode=='default'&&(<div className="col-12  mt-3">
                                    <p className={'fs-xs text-center mt-4'}>更に詳しいシミュレーションをご希望の方はこちら</p>
                                    <a href={GetLinkUrl('contact')} target="_blank" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-white py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1'} >お問い合わせ</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>)}


                                <div className="mt-4">
                                    <Link to="/result1" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-grey1 py-2 px-4 m-auto w-80 text-center fs-sm back_button ">
                                            <div className="wpx-7 float-start"><img className="flip-horizontal" src={IconArrow} alt={'Arrow'}  /></div>
                                            戻る
                                        </div>
                                    </Link>
                                </div>
                                <div className="p-4 "></div>
                            </div>
                        </div>

                        <div className={'position-relative result2-'+(isSolarOnly?'solar':'battery')+'-table1 d-none d-lg-block'}  >
                            {props.isPrint==false&&<div className="d-flex bd-highlight mt-3">
                                <div className="flex-grow-1 bd-highlight">
                                </div>
                                <div className="ps-2 pb-2 bd-highlight"><Button onClick={changeMode}
                                                                                className={'modeButton ' + (isGraphMode ? 'in_active' : 'active')}><img
                                    src={(isGraphMode ? IconTableOff : IconTableOn)} alt={'試算表を見る'} /> 試算表を見る</Button></div>
                                <div className="ps-2 pb-2 bd-highlight"><Button onClick={changeMode}
                                                                                className={'modeButton ' + (isGraphMode ? 'active' : 'in_active')}><img
                                    src={(isGraphMode ? IconGraphOn : IconGraphOff)} alt={'グラフ表示'} /> グラフ表示</Button></div>
                            </div>}
                            {props.isPrint&&<div className={'p-5'}></div>}
                            {isGraphMode && (resData != null && (
                                <div className={'m-auto graphWrap '} style={{minWidth: 960, maxWidth:1200}} >
                                    {props.isPrint==false&&<div className={'position-relative graphWrapInner'}>
                                        <div className={'naviFukidashiLine'} style={{top:0, left: "28.6%"}}>
                                            <div className={'naviFukidashiTop'} style={{top:195, left: -80}}>
                                                {fukidashi1Text}
                                            </div>
                                        </div>

                                        <div className={'naviFukidashiLine'} style={{top:0, left: (payYears==10?"28.6%":"42.8%")}}>
                                            <div className={'naviFukidashiBottom'} style={{top:-35, left: -80}}>
                                                {fukidashi2Text}
                                            </div>
                                        </div>
                                        <div className={'naviFukidashiLine'} style={{top:0, left: "71.1%"}}>
                                            <div className={'naviFukidashiBottom'} style={{top:-35, left: -80}}>
                                                {fukidashi3TextPlot}
                                            </div>
                                        </div>
                                    </div>}
                                    <div  >
                                        <ResultPayGraph loanData={loanCalcData}/>
                                    </div>
                                </div>))}

                            {!isGraphMode && (
                                <table className={'result2Table hasCheckLine'}>
                                    <thead>
                                    <tr className={''}>
                                        <th>年間支払額目安</th>
                                        <th className={'position-relative'}>
                                            1〜10年目
                                            <div className={'naviFukidashiTop'} style={{bottom:-185, right: "-82px"}}>
                                                {fukidashi1Text}
                                            </div>
                                            {(payYears==10?(<div className={'naviFukidashiBottom'} style={{top:-45, right: "-82px"}}>
                                                {fukidashi2Text}
                                            </div>):(<div />))}
                                        </th>
                                        <th className={'position-relative'}>
                                            11〜15年目
                                            {(payYears==15?(<div className={'naviFukidashiBottom'} style={{top:-45, right: "-82px"}}>
                                                {fukidashi2Text}
                                            </div>):(<div />))}
                                        </th>
                                        <th className={'position-relative'}>16〜25年目
                                            <div className={'naviFukidashiTop'} style={{width:240, bottom:-185, right: "-122px"}}>
                                                {fukidashi3Text}
                                            </div></th>
                                        <th>
                                            26〜35年目</th>
                                        <th>総額</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={'row1 ' + (cheapestRow==0&&'cheapest') }>
                                        <td>ローン利用時<br/>
                                            ローン相当額+電気料金
                                        </td>
                                        <td>{formatter.format(loanCalcData['yearTo10WithLoan'])}</td>
                                        <td>{formatter.format(loanCalcData['yearTo15WithLoan'])}</td>
                                        <td>{formatter.format(loanCalcData['yearTo25WithLoan'])}</td>
                                        <td>{formatter.format(loanCalcData['yearTo35WithLoan'])}</td>
                                        <td>{formatter.format(loanCalcData['totalWithLoan'])}</td>
                                    </tr>
                                    <tr className={'row2 ' + (cheapestRow==1&&'cheapest') }>
                                        <td>ZEROレジ利用時<br/>
                                            定額利用料+電気料金
                                        </td>
                                        <td>{formatter.format(loanCalcData['yearTo10WithZeroRegi'])}</td>
                                        <td>{formatter.format(loanCalcData['yearTo15WithZeroRegi'])}</td>
                                        <td>{formatter.format(loanCalcData['yearTo25WithZeroRegi'])}</td>
                                        <td>{formatter.format(loanCalcData['yearTo35WithZeroRegi'])}</td>
                                        <td>{formatter.format(loanCalcData['totalWithZeroRegi'])}</td>
                                    </tr>
                                    <tr className={'row3 ' + (cheapestRow==2&&'cheapest') }>
                                        <td className={'text-center'}>非搭載<br/>
                                            （オール電化）
                                        </td>
                                        <td>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                        <td>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                        <td>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                        <td>{formatter.format(loanCalcData['buyYearlyPriceAvgNoMount'])}</td>
                                        <td>{formatter.format(loanCalcData['totalWithNoMount'])}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            )}

                            {props.isPrint&&<div className={'p-5'}></div>}
                        </div>


                        <div className={'d-none d-lg-block '}>
                            <div className={'row pb-4 '+ (!isGraphMode &&'mt-5')}>
                                <div className={'col-1 position-relative'}>
                                    <div className="">
                                        <Link to="/result1" className="link-no-decoration position-absolute bottom-0">
                                            <div className="defbtn d-block rounded-5 bg-grey1 py-1 ps-2 pe-1 w-100 text-center fs-sm back_button ">
                                                <div className="wpx-6 float-start"><img className="flip-horizontal" src={IconArrow} alt={'Arrow'}  /></div>
                                                戻る
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className={(appMode=='default'?'col-5':'col-10')}>
                                    <p className={'fs-xs text-center mt-4'}>金額メリットだけじゃない！サブスク型太陽光発電システム</p>
                                    <a href="javascript:void(0);" onClick={handleZeroRegiLink} target={gls.saveData.app_mode!=AppMode.YLET?'_blank':'_self'} className="d-block link-no-decoration m-auto mwpx-300">
                                        <div className="defbtn d-block rounded-5 bg-yellow px-2 m-auto  hfs-sm hpx-50">
                                            <div className={'d-flex justify-content-between align-items-center hpx-50'}>
                                                <div className={'w-90'} >
                                                    <div className={'text-center fs-sm w-90'}>「ZEROレジ」詳しくはこちら</div>
                                                </div>
                                                <div className="wpx-10 d-flex align-items-center"><img src={IconArrow} alt={""} /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                {appMode=='default'&&(<div className="col-5">
                                    <p className={'fs-xs text-center mt-4'}>更に詳しいシミュレーションをご希望の方はこちら</p>
                                    <a href={GetLinkUrl('contact')} target="_blank" className="d-block link-no-decoration m-auto mwpx-300">
                                        <div className="defbtn d-block rounded-5 bg-white px-2 w-100 m-auto  fs-sm hpx-50">
                                            <div className={'d-flex justify-content-between align-items-center hpx-50'}>
                                                <div className={'text-center fs-sm w-90'}>お問い合わせ</div>
                                                <div className="wpx-10 "><img src={IconArrow} alt={""} /></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>)}
                            </div>
                        </div>

                    </div>
                )
                }
            </div>
        </div>
    )
}
export default Result2

