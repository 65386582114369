
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC01 from "../../img/flow-pc01.png"
import IconQ101 from "../../img/q1-01.png"
import IconQ102 from "../../img/q1-02.png"
import IconArrow from "../../img/icon_arw01.png"
import {LocalStorage} from "../../storage/local_storage";
import React, {ReactElement, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import IconPdf from "../../img/icon_pdf.png";
import {CurrencyFormat} from "../../helper/formatter";
import axios from "axios";
import {UsageResult} from "../../entity/usage_result";
import {APIURLs} from "../../const/urls";
import {GetModeTitle} from "../../util/get_mode_title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import {SimulationMode} from "../../entity/types";

const loaded = false
const SalesPriceConfig = () => {
    const ls = LocalStorage.getInstance()
    ls.getSaveData()
    useEffect(() => {
        getUsageData()
    },  []);

    const [loading, loadingSet] = useState(true);
    const [listTableView, listTableViewSet] = useState<ReactElement>()
    const [subTotalTableWithGasElec, subTotalTableWithGasElecSet] = useState<any>()
    const [subTotalTableWithGasGas, subTotalTableWithGasGasSet] = useState<any>()
    const [subTotalTableWithGasWhole, subTotalTableWithGasWholeSet] = useState<any>()
    const [subTotalTableWithElecElec, subTotalTableWithElecElecSet] = useState<any>()
    const [subTotalTableWithElecWhole, subTotalTableWithElecWholeSet] = useState<any>()

    const rn = (min:number, max:number)=>{
        let num = Math.floor(Math.random() * max) + min;
        return num
    }
    const getUsageData = () => {
        if (loaded == false) {
            ls.getSaveData()
            // let sendData = {"app_mode":"default","house_tsubo":15,"panel_number":18,"sim_acception":true,"home_type":"reform","prefecture":"東京","stay_type":"always","number_of_family":4,"roof_type":"片流れ","roof_degree":20,"house_angle":0,"monthly_elec_cost":13532,"monthly_total_all_elec_cost":18043,"monthly_total_all_elec_cost_calc":18043,"monthly_total_cost_calc":18043,"monthly_gas_cost":7414}
            let sendData = ls.saveData
            let reqUrl = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}` + APIURLs.SUMMARY
            if((ls.saveData.simulation_mode == SimulationMode.EV)) {
                reqUrl = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}` + APIURLs.SUMMARY_EV
            }
            reqUrl = reqUrl + '?t='+new Date().getTime()

            console.log('sendData', sendData)
            axios.post(reqUrl, sendData).then((response) => {
                const ls = LocalStorage.getInstance()
                if (response.data) {
                    ls.setUsageData(response.data)
                }
                const resData = response.data as UsageResult
                const d = new Date();
                const monInt = d.getMonth()
                const mon = d.getMonth() + 1;
                let days = 30
                if(mon == 1 || mon == 3 || mon==5 || mon==7 || mon==8 ||  mon==10 || mon==12){
                    days = 31
                }else{
                    if(mon==2){
                        days=28
                    }
                }
                type MonKey = keyof typeof resData.summaryWithAll.monthly;
                const mKey = mon as unknown as MonKey
                // type ObjectKey = keyof typeof response.data.;
                // const buyKey = mon as ObjectKey;
                console.log('response.data', response.data);
                const allWithPanel = resData.summaryWithAll
                const allNoPanel = resData.summaryWithAllNoPanelNoBattery
                const elecWithGas = resData.summaryWithGas
                const gasCostMonthly = Math.floor(resData.gasData.gasUsageEachMonthly[monInt].usageMonthTotal * resData.gasData.gasUnitPrice) + resData.gasData.gasBasicPrice

                const totalGasBuyOutcome = elecWithGas.monthly[mKey].buy_outcome + elecWithGas.basic + elecWithGas.monthly[mKey].saiene_outcome + elecWithGas.monthly[mKey].surcharge_outcome
                const total = Math.floor(totalGasBuyOutcome) + gasCostMonthly
                const totalAllBuyOutcome = allNoPanel.monthly[mKey].buy_outcome + allNoPanel.basic + allNoPanel.monthly[mKey].saiene_outcome + allNoPanel.monthly[mKey].surcharge_outcome
                ls.setMonthlyElecCost(Math.floor(elecWithGas.monthly[mKey].buy_outcome))
                ls.setMonthlyTotalAllElecCost(Math.floor(allNoPanel.monthly[mKey].buy_outcome))

                ls.setMonthlyTotalAllElecCostCalc(Math.floor(allNoPanel.monthly[mKey].buy_outcome))
                ls.setMonthlyTotalCostCalc(Math.floor(allNoPanel.monthly[mKey].buy_outcome))
                ls.setMonthlyGasCost(gasCostMonthly)
                /// return resData
                setupShowData()
                loadingSet(false)
            })
        }
    }

    const setupShowData = () => {
        ls.getSaveData()
        ls.getUsageData()
        const usageWithGas = ls.usageData.summaryWithGas
        const usageWithAll = ls.usageData.summaryWithAllNoPanelNoBattery
        const unitData = ls.usageData.unitDefines
        const gasData = ls.usageData.gasData
        console.log('usageWithGas-------------', usageWithGas)
        for(let i=0; i<12; i++){
            let month = i+1
            let monInt = i
            type MonKey = keyof typeof usageWithAll.monthly;
            const mKey = i+1 as unknown as MonKey
            let days = 30
            if(month == 1 || month == 3 || month==5 || month==7 || month==8 ||  month==10 || month==12){
                days = 31
            }else{
                if(month==2){
                    days=28
                }
            }

            basicPrice.WithGas[i] = usageWithGas.basic
            basicPrice.WithAll[i] = usageWithAll.basic

            console.log('usageWithGas.monthly[mKey]-------------', usageWithGas.monthly[mKey])
            buyPrice.WithGas[i] = usageWithGas.monthly[mKey].buy_outcome
            buyPrice.WithAll[i] = usageWithAll.monthly[mKey].buy_outcome

            surchargePrice.WithGas[i] = usageWithGas.monthly[mKey].surcharge_outcome
            surchargePrice.WithAll[i] = usageWithAll.monthly[mKey].surcharge_outcome

            saienePrice.WithGas[i] = usageWithGas.monthly[mKey].saiene_outcome
            saienePrice.WithAll[i] = usageWithAll.monthly[mKey].saiene_outcome

            gasBasicPrice.WithGas[i] = unitData.gasBasicPrice
            const gasCostMonthly = Math.floor(gasData.gasUsageEachMonthly[monInt].usageMonthTotal * gasData.gasUnitPrice)
            gasBuyPrice.WithGas[i] = gasCostMonthly

            let subTotalValWithGasElecSubtotal = (basicPrice.WithGas[i]+buyPrice.WithGas[i]+surchargePrice.WithGas[i]+saienePrice.WithGas[i])
            subtotalList.WithGasElecSubtotal[i] = subTotalValWithGasElecSubtotal
            // @ts-ignore
            //subtotalItemList.WithGasElecSubtotal[i] = <td className={'bg-info'}><input readOnly={true} type='text' className={'w-100'} value={Math.floor(subTotalValWithGasElecSubtotal)} /></td>
            subtotalItemList.WithGasElecSubtotal[i] = <td className={'bg-info'}>{CurrencyFormat.format(Math.floor(subTotalValWithGasElecSubtotal))}</td>
            subTotalTableWithGasElecSet( subtotalItemList.WithGasElecSubtotal )

            let subTotalValWithGasGasSubtotal = (gasBasicPrice.WithGas[i]+gasBuyPrice.WithGas[i])
            subtotalList.WithGasGasSubtotal[i] = subTotalValWithGasGasSubtotal
            // @ts-ignore
            // subtotalItemList.WithGasGasSubtotal[i] = <td><input readOnly={true} type='text' className={'w-100'} value={Math.floor(subTotalValWithGasGasSubtotal)} /></td>
            subtotalItemList.WithGasGasSubtotal[i] = <td className={'tc-white'}>{CurrencyFormat.format(Math.floor(subTotalValWithGasGasSubtotal))}</td>
            subTotalTableWithGasGasSet( subtotalItemList.WithGasGasSubtotal )

            let subTotalValWithGasWholeSubtotal = (subTotalValWithGasElecSubtotal+subTotalValWithGasGasSubtotal)
            subtotalList.WithGasWholeSubtotal[i] = subTotalValWithGasWholeSubtotal
            // @ts-ignore
            // subtotalItemList.WithGasWholeSubtotal[i] = <td><input readOnly={true} type='text' className={'w-100'} value={Math.floor(subTotalValWithGasWholeSubtotal)} /></td>
            subtotalItemList.WithGasWholeSubtotal[i] = <td className={'tc-white'}>{CurrencyFormat.format(Math.floor(subTotalValWithGasWholeSubtotal))}</td>
            subTotalTableWithGasWholeSet( subtotalItemList.WithGasWholeSubtotal )

            let subTotalValWithAllElecSubtotal = (basicPrice.WithAll[i]+buyPrice.WithAll[i]+surchargePrice.WithAll[i]+saienePrice.WithAll[i])
            subtotalList.WithAllElecSubtotal[i] = subTotalValWithAllElecSubtotal
            // @ts-ignore
            // subtotalItemList.WithAllElecSubtotal[i] = <td className={'bg-info'}><input readOnly={true} type='text' className={'w-100'} value={Math.floor(subTotalValWithAllElecSubtotal)} /></td>
            subtotalItemList.WithAllElecSubtotal[i] = <td className={'bg-info'}>{CurrencyFormat.format(Math.floor(subTotalValWithAllElecSubtotal))}</td>
            subTotalTableWithElecElecSet( subtotalItemList.WithAllElecSubtotal )

            let subTotalValWithAllWholeSubtotal = subtotalList.WithAllElecSubtotal[i]
            subtotalList.WithAllWholeSubtotal[i] = subTotalValWithAllWholeSubtotal
            // @ts-ignore
            //subtotalItemList.WithAllWholeSubtotal[i] = <td><input readOnly={true} type='text' className={'w-100'} value={Math.floor(subTotalValWithAllWholeSubtotal)} /></td>
            subtotalItemList.WithAllWholeSubtotal[i] = <td className={'tc-white'}>{CurrencyFormat.format(Math.floor(subTotalValWithAllWholeSubtotal))}</td>
            subTotalTableWithElecWholeSet( subtotalItemList.WithAllWholeSubtotal )
        }
        createListTable()
    }


    interface MonthlyPrices  {
        [key: string]: [number,number,number,number,number,number,number,number,number,number,number,number]
    }
    interface MonthlyPriceItems  {
        [key: string]: any[]
    }
    let basicPrice:MonthlyPrices = {
        WithGas: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ,WithAll: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
    let buyPrice:MonthlyPrices = {
        WithGas: [rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
        ,WithAll:[rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
    }
    let surchargePrice:MonthlyPrices = {
        WithGas: [rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
        ,WithAll:[rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
    }
    let saienePrice:MonthlyPrices = {
        WithGas: [rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
        ,WithAll:[rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
    }
    let gasBasicPrice:MonthlyPrices = {
        WithGas: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        ,WithAll:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
    let gasBuyPrice:MonthlyPrices = {
        WithGas: [rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
        ,WithAll: [rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0),rn(0,0)]
    }
    let subtotalItemList:MonthlyPriceItems = {
        WithGasElecSubtotal:  [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,],
        WithGasGasSubtotal: [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,],
        WithGasWholeSubtotal: [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,],
        WithAllElecSubtotal: [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,],
        WithAllWholeSubtotal: [<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,<></>,],
    }
    let subtotalList:MonthlyPrices = {
        WithGasElecSubtotal:[0,0,0,0,0,0,0,0,0,0,0,0],
        WithGasGasSubtotal:[0,0,0,0,0,0,0,0,0,0,0,0],
        WithGasWholeSubtotal:[0,0,0,0,0,0,0,0,0,0,0,0],
        WithAllElecSubtotal:[0,0,0,0,0,0,0,0,0,0,0,0],
        WithAllWholeSubtotal:[0,0,0,0,0,0,0,0,0,0,0,0],
    }

    const createListTable = () =>{
        listTableViewSet(
        <table className={'table table-bordered fs-sm'}>
            <thead>
            <tr>
                <th colSpan={3}></th>
                <th>1月</th>
                <th>2月</th>
                <th>3月</th>
                <th>4月</th>
                <th>5月</th>
                <th>6月</th>
                <th>7月</th>
                <th>8月</th>
                <th>9月</th>
                <th>10月</th>
                <th>11月</th>
                <th>12月</th>
                <th>年合計</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th rowSpan={9} className={'wpx-10 '}>電<br />気<br />・<br />ガ<br />ス<br />併<br />用</th>
                <th rowSpan={5} className={'bg-info'}>電気</th>
                <th className={'wpx-110'}>基本料金</th>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithGas.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr>
                <th>従量料金</th>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(buyPrice.WithGas.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr>
                <th>燃料調整費</th>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithGas.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr>
                <th>再エネ賦課金</th>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithGas.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr className={'bg-info'}>
                <th>電気料金計</th>
                {subtotalItemList.WithGasElecSubtotal}
                <td>
                    {CurrencyFormat.format(subtotalList.WithGasElecSubtotal.reduce((a, b) => a + b, 0))}
                </td>
            </tr>
            <tr>
                <th rowSpan={3} className={'bg-secondary tc-white'}>ガス</th>
                <th>基本料金</th>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBasicPrice.WithGas.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr>
                <th>従量料金</th>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(gasBuyPrice.WithGas.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr className={'bg-secondary'}>
                <th className={'bg-secondary tc-white'}>ガス料金計</th>
                {subtotalItemList.WithGasGasSubtotal}
                <td className={'tc-white'}>
                    {CurrencyFormat.format(subtotalList.WithGasGasSubtotal.reduce((a, b) => a + b, 0))}
                </td>
            </tr>
            <tr className={'bg-magenta'}>
                <th colSpan={2} className={'tc-white'}>月間光熱費計</th>
                {subtotalItemList.WithGasWholeSubtotal}
                <td className={'tc-white'}>
                    {CurrencyFormat.format(subtotalList.WithGasWholeSubtotal.reduce((a, b) => a + b, 0))}
                </td>
            </tr>
            <tr>
                <th rowSpan={6} className={'wpx-10 '}>オ<br />｜<br />ル<br />電<br />化</th>
                <th rowSpan={5} className={'bg-info'}>電気</th>
                <th className={'wpx-110'}>基本料金</th>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(basicPrice.WithAll[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(basicPrice.WithAll[11])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(basicPrice.WithAll.reduce((a, b) => a + b, 0))))}</td>
            </tr>
            <tr>
                <th>従量料金</th>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[0])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[1])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[2])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[3])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[4])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[5])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[6])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[7])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[8])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[9])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[10])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll[11])))}</td>
                <td>{CurrencyFormat.format(Math.floor(Math.floor(buyPrice.WithAll.reduce((a, b) => a + b, 0))))}</td>
            </tr>
            <tr>
                <th>燃料調整費</th>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(surchargePrice.WithAll.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr>
                <th>再エネ賦課金</th>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[0]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[1]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[2]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[3]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[4]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[5]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[6]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[7]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[8]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[9]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[10]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll[11]))}</td>
                <td>{CurrencyFormat.format(Math.floor(saienePrice.WithAll.reduce((a, b) => a + b, 0)))}</td>
            </tr>
            <tr className={'bg-info'}>
                <th >電気料金計</th>
                {subtotalItemList.WithAllElecSubtotal}
                <td>
                    {CurrencyFormat.format(Math.floor(subtotalList.WithAllElecSubtotal.reduce((a, b) => a + b, 0)))}
                </td>
            </tr>
            <tr className={'bg-blue'}>
                <th colSpan={2} className={'tc-white'}>月間光熱費計</th>
                {subtotalItemList.WithAllWholeSubtotal}
                <td className={'tc-white'}>
                    {CurrencyFormat.format(Math.floor(subtotalList.WithAllWholeSubtotal.reduce((a, b) => a + b, 0)))}
                </td>
            </tr>
            </tbody>
        </table>)
    }

    return (
        <div className={`home container`}>
            <div className="mt-5">
            </div>
            <h1 className="tc-yellow text-center fs-24 m-4">
                <div className={'fs-12 btn btn-info'}> <FontAwesomeIcon icon={faCalculator} /> {GetModeTitle()}</div>
                <div className={'pt-2'}>光熱費の確認</div>
            </h1>

            <div className={'rounded-4 bg-white p-4'}>
                <p className={'mb-4'}>【電気・ガス併用】　※ガスはLPガスにて算出<br />
                    お客様が選択された世帯形式・世帯人数より、戸建て・マンションなど住居形態を限定しない全国平均の光熱費を表示しています。<br />
                    <br />
                    【オール電化】<br />
                    お客様が選択された世帯形式・世帯人数より、戸建て120㎡（約36坪）　断熱等級４　エコキュートJIS3.8　IH（ｸｯｷﾝｸﾞﾋｰﾀｰ）<br />
                    エアコン3台　三種換気仕様の全国平均の電気料金を表示しています。<br />

                </p>
                {loading && ( <div className={'loaderWrap'}><p className="loader">ロード中...</p></div>) }
                <div className="row">

                    <div className="row">
                        <div className="col-12">
                            {listTableView}
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <p className={'text-center m-4'}>太陽光発電を導入する際、ガス併用型で受けられる恩恵は限定的です。以降は「オール電化」を前提に試算するため、<br />
                    新築の場合は「オール電化」の選択、リフォームの場合はオール電化へのリフォームを条件としてご案内してください。
                </p>
            </div>

            <div className="m-4">
                <Link to={loading?'#':'/sales/confirm'} className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-magenta fw-bold py-3 px-4 m-auto w-50 text-center  tc-white fs-xl">
                        お客様確認画面へ進む <div className="w-4 float-end"><img className={'filter-white'} src={IconArrow} /></div>

                        <p className={'fs-sm p-0 mt-2 mb-0'}>※試算結果に関する重要な同意事項を表示します。<br />
                            必ず<span className={'tc-yellow text-decoration-underline'}>お客様自身で「同意する」をタップ</span>いただくようにしてください。
                        </p>
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/sales/price-config-confirm" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto w-30 text-center fs-lg back_button">
                        <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>)
}
export default SalesPriceConfig

