
export interface  ResponseDailyModel {
    summer:ResponseDailyModelContent
    winter:ResponseDailyModelContent
}
export interface  ResponseDailyModelContent {
    use : number
    generate: number
    sell_income: number
    buy_outcome: number
}
export interface  ResponseMonthlyModelContent {
    sell_income:number
    buy_outcome:number
    basic_price: number
    saiene_fuka:number
    surcharge: number
    monthly_total:number
    non_panel_total:number
    total_diff:number
}
export interface  ResponseMonthlyModel {
    m1: ResponseMonthlyModelContent
    m2: ResponseMonthlyModelContent
    m3: ResponseMonthlyModelContent
    m4: ResponseMonthlyModelContent
    m5: ResponseMonthlyModelContent
    m6: ResponseMonthlyModelContent
    m7: ResponseMonthlyModelContent
    m8: ResponseMonthlyModelContent
    m9: ResponseMonthlyModelContent
    m10: ResponseMonthlyModelContent
    m11: ResponseMonthlyModelContent
    m12: ResponseMonthlyModelContent
    year_total: ResponseMonthlyModelContent
}
export interface ResponseResults {
    daily: ResponseDailyModel
    monthly: ResponseMonthlyModel
}
export const ResponseResultsBaseData:ResponseResults = {
    daily: {
        summer : {
            use : 0,
            generate: 0,
            sell_income: 0,
            buy_outcome: 0
        },
        winter : {
            use : 0,
            generate: 0,
            sell_income: 0,
            buy_outcome: 0
        }
    },
    monthly:{
        m1:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m2:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m3:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m4:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m5:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m6:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m7:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m8:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m9:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m10:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m11:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        m12:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        },
        year_total:{
            sell_income:0,
            buy_outcome:0,
            basic_price: 0,
            saiene_fuka: 0,
            surcharge: 0,
            monthly_total:0,
            non_panel_total:0,
            total_diff:0,
        }
    }
}
