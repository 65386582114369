
import {Link, useNavigate} from "react-router-dom";
import IconArrowRed from "../img/icon_arw_red.png"
import IconArrow from "../img/icon_arw01.png"
import React, {useEffect, useRef, useState} from "react";
import {LocalStorage} from "../storage/local_storage";
import routeCheck from "../util/route_check";
import {APIURLs} from "../const/urls";
import axios from "axios";
import {ResponseResults, ResponseResultsBaseData} from "../entity/result_response";
import {UsageResult} from "../entity/usage_result";
import ImgFlowPC08 from "../img/flow-pc08.png";

const formatter = new Intl.NumberFormat('ja-JP');
const Q9 = () => {
    const [monthlyElecCost, monthlyElecCostSet] = useState('')
    const [monthlyGasCost, monthlyGasCostSet] = useState('')
    const [monthlyTotalCostTemp, monthlyTotalCostTempSet] = useState('')
    const [monthlyTotalAllElecCost, monthlyTotalAllElecCostSet] = useState('')
    const navigate = useNavigate();
    const [loading, loadingSet] = useState(false);
    const [resData, resDataSet] = useState<UsageResult|null>(null)
    const initialized = useRef(false);
    const usageLoaded = useRef(false);
    let loaded = false

    const reqUrl = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}`+APIURLs.SUMMARY+'?t='+new Date().getTime()

    useEffect(() => {
        getUsageData()
    },  []);

    const getUsageData = () => {
        if(loaded==false){
            loadingSet((res) => {
                return true
            })
            const ls =  LocalStorage.getInstance()
            ls.getSaveData()
            let sendData = ls.saveData
            console.log('sendData', sendData)
            axios.post(reqUrl, sendData).then((response) => {
                const ls =  LocalStorage.getInstance()
                if(response.data){
                    ls.setUsageData(response.data)
                }
                resDataSet((res) => {
                    const ls =  LocalStorage.getInstance()
                    if(response.data){
                        ls.setUsageData(response.data)
                    }
                    const resData = response.data as UsageResult
                    const d = new Date();
                    let mon = d.getMonth()+1;
                    mon = mon - 1
                    if(mon < 1){
                        mon = 12
                    }
                    let days = 30
                    if(mon == 1 || mon == 3 || mon==5 || mon==7 || mon==8 ||  mon==10 || mon==12){
                        days = 31
                    }else{
                        if(mon==2){
                            days=28
                        }
                    }
                    type MonKey = keyof typeof resData.summaryWithAll.monthly;
                    const mKey = mon as unknown as MonKey
                    // type ObjectKey = keyof typeof response.data.;
                    // const buyKey = mon as ObjectKey;
                    console.log('response.data', response.data);
                    const allWithPanel = resData.summaryWithAll
                    const allNoPanel = resData.summaryWithAllNoPanelNoBattery
                    const elecWithGas = resData.summaryWithGas
                    const gasCostMonthly = Math.floor(resData.gasData.gasUsageDailyTotal * days * resData.gasData.gasUnitPrice) + resData.gasData.gasBasicPrice

                    const totalGasBuyOutcome = elecWithGas.monthly[mKey].buy_outcome + elecWithGas.basic + elecWithGas.monthly[mKey].saiene_outcome +elecWithGas.monthly[mKey].surcharge_outcome
                    monthlyElecCostSet(formatter.format(Math.floor(totalGasBuyOutcome)))
                    monthlyGasCostSet(formatter.format(gasCostMonthly))
                    const total =  Math.floor(totalGasBuyOutcome) +gasCostMonthly
                    monthlyTotalCostTempSet(formatter.format(total))

                    const totalAllBuyOutcome = allNoPanel.monthly[mKey].buy_outcome + allNoPanel.basic + allNoPanel.monthly[mKey].saiene_outcome +allNoPanel.monthly[mKey].surcharge_outcome
                    monthlyTotalAllElecCostSet(formatter.format(Math.floor(totalAllBuyOutcome)))

                    ls.setMonthlyElecCost(Math.floor(elecWithGas.monthly[mKey].buy_outcome))
                    ls.setMonthlyTotalAllElecCost(Math.floor(totalAllBuyOutcome))

                    ls.setMonthlyTotalAllElecCostCalc(Math.floor(totalAllBuyOutcome))
                    ls.setMonthlyTotalCostCalc(Math.floor(totalAllBuyOutcome))
                    ls.setMonthlyGasCost(gasCostMonthly)

                    usageLoaded.current = true
                    return resData
                })
                loaded = true
                loadingSet(false)
            })
        }
    }

    useEffect(() => {
        if(!initialized.current){
            console.log('useEffect initialized')
            if(!routeCheck()){
                alert('入力に不足した情報があります。')
                navigate('/q8')
            }
            console.log('useEffect init')
            initialized.current = true;
            const ls =  LocalStorage.getInstance()
            ls.getSaveData()
            if(ls.saveData.monthly_elec_cost){
                monthlyElecCostSet(formatter.format(ls.saveData.monthly_elec_cost))
            }
            if(ls.saveData.monthly_gas_cost){
                monthlyGasCostSet(formatter.format(ls.saveData.monthly_gas_cost))
            }
            if(ls.saveData.monthly_total_all_elec_cost){
                monthlyTotalAllElecCostSet(formatter.format(ls.saveData.monthly_total_all_elec_cost))
            }
        }
    }, [navigate]);

    const focusInput = (elem: HTMLInputElement) => {
        console.log('focusInput ', elem.id, elem.value)
        let num = parseInt(elem.value.replace(/￥|,/g,''))
        elem.value = num.toString()
    }
    const blurInput = (elem: HTMLInputElement) => {
        console.log('blurInput ', elem.id, elem.value)
        let num = parseInt(elem.value.replace(/￥|,/g,''))
        let numStr =  formatter.format(num)
        elem.value = numStr
        if(elem.id === "monthlyElecCost"){
            monthlyElecCostSet(numStr)
        }else if(elem.id === "monthlyGasCost"){
            monthlyGasCostSet(numStr)
        }else if(elem.id === "monthlyTotalCostAllElec"){
            monthlyTotalAllElecCostSet(numStr)
        }
    }

    const changeInput2 = (elem: HTMLInputElement) => {
        console.log('changeInput ', elem.value)
        let num = parseInt(elem.value.replace(/￥|,/g,''))
        let numStr = ''
        if(!isNaN(num)){
            numStr = num.toString()
        }
        elem.value = numStr
    }

    const changeInput = (elem: HTMLInputElement) => {
        console.log('changeInput ', elem.id, elem.value)
        const ls =  LocalStorage.getInstance()
        let num = parseInt(elem.value.replace(/￥|,/g,''))
        let numStr = ''
        if(!isNaN(num)){
            numStr = num.toString()
        }
        let tmpElecCost = parseInt(monthlyElecCost.replace(/￥|,/g,''))
        let tmpGasCost = parseInt(monthlyGasCost.replace(/￥|,/g,''))
        if(elem.id === "monthlyElecCost"){
            monthlyElecCostSet(numStr)
            tmpElecCost = num
            ls.setMonthlyElecCost(num)
        }else if(elem.id === "monthlyGasCost"){
            monthlyGasCostSet(numStr)
            tmpGasCost = num
            ls.setMonthlyGasCost(num)
        }else if(elem.id === "monthlyTotalCostAllElec"){
            monthlyTotalAllElecCostSet(numStr)
            ls.setMonthlyTotalAllElecCost(num)
        }
        const total = Math.floor(tmpElecCost+tmpGasCost)
        monthlyTotalCostTempSet(formatter.format(total))
    }


    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 fs-xl text-center">
                    光熱費を確認してください
                </p>
                <p className="fs-sm text-center m-0">太陽光発電を搭載しなかった場合の<br className={'d-lg-none'} />先月の光熱費を試算しています。</p>
                <p className="fs-sm text-center tc-magenta">導入予定物件について実際の光熱費が分かる場合は<br className={'d-lg-none'} />先月分の金額をご入力ください。</p>
            </div>


            <div>
                {loading ? (
                    <div className={'loaderWrap'} ><p className="loader">ロード中...</p></div>
                ) : (
                    <>
                        <div className="border border-magenta border-3 rounded-4" >
                            <div className="d-block d-lg-none tc-white bg-magenta rounded border-4 rounded-3 py-2">
                                <div className="  ">
                                    <p className="fs-16 m-0 p-2">電気・ガス併用の場合 <span style={{lineHeight:'100%'}}  className={'fs-xs'}>※先月度の月額を試算</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="ms-2 d-none d-lg-block col-md-2 tc-white bg-magenta rounded-end border-5 rounded-4 py-2">
                                    <div className="  ">
                                        <p className="fs-16 m-0 text-lg-start text-center">電気・ガス<br />
                                            併用の場合<br /><span style={{lineHeight:'100%'}}  className={'fs-xs'}>※先月度の月額を試算</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 py-2 px-2 d-lg-flex align-items-center ">
                                    <div className={'ms-lg-4'}>
                                        <p className="mb-0 fs-md text-lg-start text-center">月額電気料金
                                            <span className="ms-2 fs-xs">※60A契約の場合</span>
                                        </p>
                                        <div className="row justify-content-center justify-content-lg-start">
                                            <div className="col-auto w-60">
                                                <div className={'d-flex'}>
                                                    <strong className="fs-xl d-inline-block d-lg-none me-3 w-10">&nbsp;</strong>
                                                    <div className={'w-90'}>
                                                        <input type="text" id="monthlyElecCost" className="fs-lg text-center form-control w-100" value={monthlyElecCost} readOnly={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto "> 円 <strong className="d-none d-lg-inline-block fs-xl ms-3"> + </strong></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 py-2 px-2 d-lg-flex align-items-center ">
                                    <div>
                                        <p className="mb-0 fs-md text-lg-start text-center">月額ガス料金
                                        </p>
                                        <div className="row justify-content-center justify-content-lg-start">
                                            <div className="col-auto w-60">
                                                <div className={'d-flex'}>
                                                    <strong className="fs-xl d-inline-block d-lg-none me-3 w-10"> + </strong>
                                                    <div className={'w-90'}>
                                                        <input type="text" id="monthlyGasCost" className="fs-lg text-center form-control w-100" value={monthlyGasCost} readOnly={true}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto "> 円<strong className="d-none d-lg-inline-block fs-xl ms-3"> = </strong></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3 py-2 px-2 d-lg-flex align-items-center ">
                                    <div>
                                        <p className="mb-0 fs-md text-lg-start text-center">月額 電気・ガス料金総額
                                        </p>
                                        <div className="row justify-content-center justify-content-lg-start">
                                            <div className="col-auto w-60">
                                                <div className={'d-flex'}>
                                                    <strong className="fs-xl d-inline-block d-lg-none me-3 w-10"> = </strong>
                                                    <div className={'w-90'}>
                                                        <input type="text" readOnly className="fs-lg text-center form-control w-100" value={monthlyTotalCostTemp}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto "> 円<strong className="d-none d-lg-inline-block fs-xl ms-3"> &nbsp; </strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="text-center m-4 "> 太陽光発電を導入する際、<strong>ガス併用型で受けられる恩恵は限定的</strong>です。<br />
                            <strong className="tc-magenta">オール電化の選択または、オール電化へのリフォームをおすすめいたします。</strong></p>


                        <div className="border border-blue border-3 rounded-4" >
                            <div className="d-block d-lg-none tc-white bg-blue rounded border-4 rounded-3 py-2">
                                <div className="  ">
                                    <p className="fs-16 m-0 p-2">オール電化の場合<span style={{lineHeight:'100%'}}  className={'fs-xs'}>※先月度の月額を試算</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="ms-2 d-none d-lg-block col-md-2 tc-white bg-blue rounded-end border-5 rounded-4 py-2">
                                    <div className=" ">
                                        <p className="fs-16 m-0">オール電化<br />
                                            の場合<br /><span style={{lineHeight:'100%'}} className={'fs-xs'}>※先月度の月額を試算</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-3 py-2 px-2 d-lg-flex align-items-center justify-content-lg-end ">
                                    <p className="mb-0 fs-md text-lg-start text-center">月額電気料金<br /><span className="ms-2 fs-xs">※60A契約の場合</span>
                                    </p>
                                </div>
                                <div className="col-12 col-lg-3 py-2 px-2 d-lg-flex align-items-center ">
                                    <div className="row justify-content-center justify-content-lg-start">
                                        <div className="col-auto w-60"><input id="monthlyTotalCostAllElec" type="text" className="fs-lg text-center form-control w-100" value={monthlyTotalAllElecCost} onBlur={(e)=>blurInput(e.target)} onFocus={(e) => focusInput(e.target)} onChange={(e) => changeInput(e.target)}  /></div>
                                        <div className="col-auto "> 円<strong className="fs-lg ms-3">&nbsp;</strong></div>
                                        <span className="d-block text-center  d-lg-inline-block fs-xs">※変更できます</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>



            <div className="m-4">
                {usageLoaded.current&&(
                <Link to={'/result1'} className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto col-10 col-md-7 text-center">
                        <div className={'d-flex justify-content-between align-items-center'} >
                            <div className={'text-center w-90  fs-20'}> シミュレーション結果を見る </div><div className="wpx-16"><img src={IconArrow} alt={'Arrow'}  /></div>
                        </div>
                    </div>
                </Link>)}
            </div>
            <div className="m-4">
                <Link to="/q8" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-8 col-md-4 text-center fs-lg back_button">
                        <div className="wpx-10 float-start"><img className="flip-horizontal" src={IconArrow} alt={'Arrow'}  /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q9

