
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC01 from "../../img/flow-pc01.png"
import IconQ101 from "../../img/q1-01.png"
import IconQ102 from "../../img/q1-02.png"
import IconArrow from "../../img/icon_arw01.png"
import {LocalStorage} from "../../storage/local_storage";
import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {SimulationMode} from "../../entity/types";

const gls =  LocalStorage.getInstance()
const Top = () => {
    const [isModalOpen, isModalOpenSet] = useState(false)
    useEffect(() => {
        console.log('setting reset at top')
        gls.setHomeType('new')
        gls.setPayConditionData({zero_regi_year:15, loan_type:'住宅ローン', loan_year:35, rate:1.0, water_mark:false})
        gls.setPrefecture('東京')
        gls.setStayType('always')
        gls.setPanelNumber(12)
        gls.setNumberOfFamily(2)
        gls.setRoofDegree(10)
        gls.setHouseAngle(0)
        gls.setConditionPriceSolar(0)
        gls.setConditionPriceSolarBattery(0)
        gls.setSimulationMode(SimulationMode.DEFAULT)
    }, []);
    const navigate = useNavigate();
    let panelList = []
    for (let i = 10; i <= 26; i++) {
        panelList.push(<option>{i}</option>)
    }
    const handleAnswerLoanType = (value: string) => {
        gls.setHomeType(value)
        if(value=='new'){
            gls.setPayConditionData({zero_regi_year:15, loan_type:'住宅ローン', loan_year:35, rate:1.0, water_mark:false})
        }else{
            gls.setPayConditionData({zero_regi_year:15, loan_type:'リフォームローン', loan_year:20, rate:2.0, water_mark:false})
        }
    }
    const handleAnswerPref = (value: string) => {
        gls.setPrefecture(value)
    }
    const handleAnswerStateType = (value: string) => {
        gls.setStayType(value)
    }
    const handleAnswerNumberOfFamily = (value: number) => {
        gls.setNumberOfFamily(value)
    }
    const handleAnswerRoofType = (value: string) => {
        gls.setRoofType(value)
    }
    const handleAnswerRoofDegree = (value: number) => {
        gls.setRoofDegree(value)
    }
    const handleAnswerHouseAngle = (value: number) => {
        gls.setHouseAngle(value)
    }
    const handleAnswerPanelNumber = (value: number) => {
        gls.setPanelNumber(value)
    }
    const handleAnswerCondtionPriceSolar = (value: number) => {
        gls.setConditionPriceSolar(value)
    }
    const handleAnswerCondtionPriceSolarBattery = (value: number) => {
        gls.setConditionPriceSolarBattery(value)
    }

    const linkToNextDefault = () => {
        gls.setSimulationMode(SimulationMode.DEFAULT)
        navigate('/sales/price-config-confirm')
    }
    const linkToNextV2h = () => {
        if(gls.saveData.panel_number < 12){
            isModalOpenSet(true)
        }else{
            gls.setSimulationMode(SimulationMode.EV)
            navigate('/sales/price-config-confirm')
        }
    }
    const handleCloseEVAlertModal = () => {
        gls.setPanelNumber(12)
        gls.setSimulationMode(SimulationMode.EV)
        isModalOpenSet(false)
        navigate('/sales/price-config-confirm')
    }

    return (
        <div className={`home container`}>
            <div className="mt-5">
            </div>
            <h1 className="tc-yellow text-center fs-24 m-4">
                条件設定
            </h1>

            <div className={'rounded-4 bg-white p-4'}>


                <div className="row pt-3">
                    <div className="col-6 ">
                        住まいのタイプ（住宅ローン または リフォームローン）
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} onChange={(e)=>{handleAnswerLoanType(e.currentTarget.value)}}>
                            <option value="new">新たに取得する住宅</option>
                            <option value="reform">所有中の住宅</option>
                        </select>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-6">
                    お住いの地域
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} defaultValue={'東京'} onChange={(e)=>{handleAnswerPref(e.currentTarget.value)}} >
                            <option value="北海道">北海道</option>
                            <option value="青森">青森県</option>
                            <option value="岩手">岩手県</option>
                            <option value="宮城">宮城県</option>
                            <option value="秋田">秋田県</option>
                            <option value="山形">山形県</option>
                            <option value="福島">福島県</option>
                            <option value="茨城">茨城県</option>
                            <option value="栃木">栃木県</option>
                            <option value="群馬">群馬県</option>
                            <option value="埼玉">埼玉県</option>
                            <option value="千葉">千葉県</option>
                            <option value="東京">東京都</option>
                            <option value="神奈川">神奈川県</option>
                            <option value="新潟">新潟県</option>
                            <option value="富山">富山県</option>
                            <option value="石川">石川県</option>
                            <option value="福井">福井県</option>
                            <option value="山梨">山梨県</option>
                            <option value="長野">長野県</option>
                            <option value="岐阜">岐阜県</option>
                            <option value="静岡">静岡県</option>
                            <option value="愛知">愛知県</option>
                            <option value="三重">三重県</option>
                            <option value="滋賀">滋賀県</option>
                            <option value="京都">京都府</option>
                            <option value="大阪">大阪府</option>
                            <option value="兵庫">兵庫県</option>
                            <option value="奈良">奈良県</option>
                            <option value="和歌山">和歌山県</option>
                            <option value="鳥取">鳥取県</option>
                            <option value="島根">島根県</option>
                            <option value="岡山">岡山県</option>
                            <option value="広島">広島県</option>
                            <option value="山口">山口県</option>
                            <option value="徳島">徳島県</option>
                            <option value="香川">香川県</option>
                            <option value="愛媛">愛媛県</option>
                            <option value="高知">高知県</option>
                            <option value="福岡">福岡県</option>
                            <option value="佐賀">佐賀県</option>
                            <option value="長崎">長崎県</option>
                            <option value="熊本">熊本県</option>
                            <option value="大分">大分県</option>
                            <option value="宮崎">宮崎県</option>
                            <option value="鹿児島">鹿児島県</option>
                            <option value="沖縄">沖縄県</option>
                        </select>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-6 ">
                        お住まいになる世帯形式
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} defaultValue={'always'}  onChange={(e)=>{handleAnswerStateType(e.currentTarget.value)}}>
                            <option value="always">1日を通して誰かが在宅をしていることが多い</option>
                            <option value="morning_and_night">主に朝と夜間のみの在宅が多い</option>
                        </select>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-6 ">
                        お住まいになる世帯人数
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} defaultValue={2} onChange={(e)=>{handleAnswerNumberOfFamily(parseInt(e.currentTarget.value))}}>
                            <option value="2">2人</option>
                            <option value="3">3人</option>
                            <option value="4">4人</option>
                            <option value="5">5人</option>
                            <option value="6">6人</option>
                        </select>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-6 ">
                        太陽光パネル搭載枚数
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} defaultValue={12}  onChange={(e)=>{handleAnswerPanelNumber(parseInt(e.currentTarget.value))}}>
                            {panelList}
                        </select>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-6 ">
                        太陽光パネル方位
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} defaultValue={0} onChange={(e)=>{handleAnswerHouseAngle(parseInt(e.currentTarget.value))}}>
                            <option value="90">90°（西 または 東）</option>
                            <option value="45">45°（南西 または 南東）</option>
                            <option value="0">0°（南）</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-6 ">
                        屋根勾配
                    </div>
                    <div className="col-6 ">
                        <select className={'form-control w-100 rounded-2'} defaultValue={10} onChange={(e)=>{handleAnswerRoofDegree(parseInt(e.currentTarget.value))}} >
                            <option value="10">10°</option>
                            <option value="20">20°</option>
                            <option value="30">30°</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-6 ">
                        太陽光のみ向け調整金【請負時】<br />
                        (※キャンペーン時・補助金等 総額から減額する場合に使用)
                    </div>
                    <div className="col-6 ">
                        <input type={' number'} className={'form-control w-100 rounded-2'} defaultValue={0}  onChange={(e)=>{handleAnswerCondtionPriceSolar(parseInt(e.currentTarget.value))}} />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-6 ">
                        太陽光+蓄電池向け調整金【請負時】<br />
                        (※キャンペーン時・補助金等 総額から減額する場合に使用)
                    </div>
                    <div className="col-6 ">
                        <input type={' number'} className={'form-control w-100 rounded-2'} defaultValue={0}  onChange={(e)=>{handleAnswerCondtionPriceSolarBattery(parseInt(e.currentTarget.value))}} />
                    </div>
                </div>
            </div>


            <div className="m-4">
                <button onClick={linkToNextDefault} className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto w-70 text-center fs-xl">
                        蓄電池シミュレーションへ進む <div className="w-3 float-end"><img src={IconArrow} /></div>
                    </div>
                </button>
            </div>

            <div className="m-4">
                <button onClick={linkToNextV2h} className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto w-70 text-center fs-xl">
                        EVシミュレーションへ進む <div className="w-3 float-end"><img src={IconArrow} /></div>
                    </div>
                </button>
            </div>
            <div className="p-3 "></div>

            <Modal
                show={isModalOpen}
            >
                <Modal.Header >
                    <Modal.Title>EVシミュレーションの場合の注意事項</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className={'text-danger'}>EVと蓄電池の組み合わせでは太陽光パネル12枚以上の発電がないと蓄電池導入効果が見込めません。
                        <br />
                        現在の設定は {gls.saveData.panel_number} 枚です。<br /><br />
                        太陽光パネル枚数設定を12枚に変更させていただきます。</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseEVAlertModal}>OK</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Top

