
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC04 from "../img/flow-pc04.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useState} from "react";
import * as React from "react";
import ImgFlowPC03 from "../img/flow-pc03.png";

const Q4 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.number_of_family)
    const getSelected = (key: number) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const navigate = useNavigate();
    const handleAnswer = (value: number) => {
        const ls =  LocalStorage.getInstance()
        ls.setNumberOfFamily(value)
        navigate('/q5')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC04} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-3 ">
                世帯人数を選択してください
            </h1>

            <div className="row mt-3 mt-md-5">
                <div className="col-6 col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(2)}  className={'link-no-decoration ' + getSelected(2)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">2人</h2>
                        </div>
                    </a>
                </div>
                <div className="col-6 col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(3)}  className={'link-no-decoration ' + getSelected(3)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">3人</h2>
                        </div>
                    </a>
                </div>
                <div className="col-6 col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(4)}  className={'link-no-decoration ' + getSelected(4)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">4人</h2>
                        </div>
                    </a>
                </div>
                <div className="col-6 d-md-none col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(5)}   className={'link-no-decoration ' + getSelected(5)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">5人</h2>
                        </div>
                    </a>
                </div>
                <div className="col-6 d-md-none col-md-4 mt-4 mt-md-0">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(6)}   className={'link-no-decoration ' + getSelected(6)}>
                        <div className="block rounded-4 bg-white text-center p-3">
                            <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">6人以上</h2>
                        </div>
                    </a>
                </div>
            </div>
            <div className={'d-none d-md-block'}>
                <div className="row mt-5 ">
                    <div className="col-md-2 mt-4 mt-md-0">
                    </div>
                    <div className="col-md-4 mt-4 mt-md-0">
                        <a href="javascript:void(0);" onClick={() => handleAnswer(5)}   className={'link-no-decoration ' + getSelected(5)}>
                            <div className="block rounded-4 bg-white text-center p-3">
                                <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">5人</h2>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4 mt-4 mt-md-0">
                        <a href="javascript:void(0);" onClick={() => handleAnswer(6)}   className={'link-no-decoration ' + getSelected(6)}>
                            <div className="block rounded-4 bg-white text-center p-3">
                                <h2 className="text-center tc-magenta py-3 fs-28 fw-bold fs-xxl">6人以上</h2>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-2 mt-4 mt-md-0">
                    </div>
                </div>
            </div>

            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/q3" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-10 col-md-4 text-center fs-lg back_button">
                    <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q4

