import React, {useEffect, useState, useContext, FC, useLayoutEffect, createContext} from 'react';
import {Link, NavLink, Route, useLocation} from 'react-router-dom';
import useLocationChange from "./helper/use_location_change";
import SlideRoutes from 'react-slide-routes';
import IconHome from "./img/icon_home_blue.png"
import IconHomeResult from "./img/icon_home_white.png"
import PageHome from "./pages/home";
import PageAgree from "./pages/agree";
import PageQ1 from "./pages/q1";
import PageQ2 from "./pages/q2";
import PageQ3 from "./pages/q3";
import PageQ4 from "./pages/q4";
import PageQ5 from "./pages/q5";
import PageQ6 from "./pages/q6";
import PageQ7 from "./pages/q7";
import PageQ8 from "./pages/q8";
import PageQ9 from "./pages/q9";
import PageResult1 from "./pages/result1";
import PageResult2 from "./pages/result2";
import {LocalStorage} from "./storage/local_storage";
import {AppReferrer} from "./const/referrer";
import {AppMode} from "./const/app_mode";
import Top from "./pages/sales/top";
import SalesPriceConfigConfirm from "./pages/sales/price-config-confirm";
import SalesPriceConfig from "./pages/sales/price-config";
import Confirm from "./pages/sales/confirm";
import SalesResult1 from "./pages/sales/result1";
import SalesResult2 from "./pages/sales/result2";
import SalesResult3 from "./pages/sales/result3";
import PdfDownload from "./pages/pdf_download";
import ScrollToTop from "./component/scroll_top";
import SalesResult3EV from "./pages/sales/result3_ev";
import PdfDownloadSalesEV from "./pages/sales/pdf_download_ev";

const isRouteTop = () => {
    console.log('window.location.pathname', window.location.pathname)
    if(window.location.pathname === "/"){
        return true
    }
    return false
}
interface IProps {
}
interface IState {
    isRouteTop?: boolean;
}
interface IAppContext {

}
const App: FC = () => {
    LocalStorage.checkVersion()
    const gls =  LocalStorage.getInstance()
    const [modeSelected, modeSelectedSet] = useState(gls.saveData.app_mode)
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const mode = query.get('mode')
    const refType = query.get('ref-type')
    const defaultMailTo = query.get('def-mail-to')
    if(mode == AppMode.DEFAULT){
        gls.setAppMode(AppMode.DEFAULT)
    }else if(mode == AppMode.YLET){
        gls.setAppMode(AppMode.YLET)
    }else
    if(refType == AppReferrer.DEFAULT){
        gls.setReferrerType(AppReferrer.DEFAULT)
    }else if(refType == AppReferrer.LEOHOUSE){
        gls.setReferrerType(AppReferrer.LEOHOUSE)
    }
    if(defaultMailTo){
        const regex: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
        if(regex.test(defaultMailTo)){
            gls.setDefaultMailTo(defaultMailTo)
        }
    }

    const [isRouteTop, isRouteTopSet] =  React.useState(true)
    const [isResult, isResultSet] =  React.useState(false)
    const [isPDFPage, isPDFPageSet] =  React.useState(false)
    const [isSalesPage, isSalesPageSet] =  React.useState(false)
    const [isResultAnswer, isResultAnswerSet] =  React.useState(false)
    const [bgColor, setBgColor] =  React.useState('#489FE4')

    useEffect(() => {
        console.log('Version:', process.env.REACT_APP_VERSION)
    }, [ ])

    useLayoutEffect(() => {
        document.body.style.backgroundColor = bgColor
    });

    useLocationChange((location) => {
        // console.log('location.pathname', location.pathname)
        isRouteTopSet(false)
        isResultSet(false)
        isResultAnswerSet(false)
        isSalesPageSet(false)
        setBgColor('#489FE4')
        if(location.pathname === "/"){
            isRouteTopSet(true)
            return
        }
        if(location.pathname === "/q9"){
            isResultSet(true)
            setBgColor('#fff')
        }
        if(location.pathname === "/result1"
            || location.pathname === "/result2"){
            isResultSet(true)
            isResultAnswerSet(true)
            setBgColor('#fff')
        }
        if(location.pathname === "/pdf_download"){
            isPDFPageSet(true)
            setBgColor('#fff')
        }
        if(location.pathname.match(/^\/sales/)){
            isSalesPageSet(true)
        }
    })

    return (
        <>
            {!isPDFPage && <>{!isRouteTop && !isResult && (<header className={`position-fixed top-0 bg-white d-block w-100`}>
                <Link to={isSalesPage?"/sales/top":"/"} className="bg-yellow p-2 homeIcon"><img src={IconHome} /></Link>
                <p className="homeTitle">太陽光発電システム<br className={'d-md-none'} />導入シミュレーター</p>
            </header>)}
                {!isRouteTop && isResult && (<header className={`position-fixed top-0 bg-darkblue tc-white d-block w-100`}>
                    <Link to={isSalesPage?"/sales/top":"/"} className="bg-blue2 p-2 homeIcon"><img src={IconHomeResult} /></Link>
                    <p className="resultTitle tc-white">太陽光発電システム<br className={'d-md-none'} />導入シミュレーター</p>
                    {isResultAnswer && (<p className={'d-none d-lg-inline-block result-notice fs-sm '+(gls.saveData.app_mode==AppMode.YLET&&'pe-5')}>当シミュレーターの数値は、一般的なデータに基づく大まかな試算結果であり、導入後の結果をお約束するものではありません。</p>) }
                </header>)}</> }

            <ScrollToTop />
            <SlideRoutes  >
                <Route path="/sales/top" element={<Top />} />
                <Route path="/sales/price-config-confirm" element={<SalesPriceConfigConfirm />} />
                <Route path="/sales/price-config" element={<SalesPriceConfig />} />
                <Route path="/sales/confirm" element={<Confirm />} />
                <Route path="/sales/result1" element={<SalesResult1 isDefaultBattery={false}  />} />
                <Route path="/sales/result2" element={<SalesResult2 isDefaultBattery={false}  />} />
                <Route path="/sales/result3" element={<SalesResult3 isDefaultBattery={false} isPrint={false} />} />
                <Route path="/sales/result3_ev" element={<SalesResult3EV isDefaultBattery={false} isPrint={false} currentZeroRegiPayYear={10} />} />
                <Route path="/sales/pdf_download_ev" element={<PdfDownloadSalesEV />} />

                <Route path="/" element={<PageHome />} />
                <Route path="/agree" element={<PageAgree />} />
                <Route path="/q1" element={<PageQ1 />} />
                <Route path="/q2" element={<PageQ2 />} />
                <Route path="/q3" element={<PageQ3 />} />
                <Route path="/q4" element={<PageQ4 />} />
                <Route path="/q5" element={<PageQ5 />} />
                <Route path="/q6" element={<PageQ6 />} />
                <Route path="/q7" element={<PageQ7 />} />
                <Route path="/q8" element={<PageQ8 />} />
                <Route path="/q9" element={<PageQ9 />} />
                <Route path="/result1" element={<PageResult1 isDefaultBattery={false} />} />
                <Route path="/result2" element={<PageResult2 isDefaultBattery={false} isPrint={false} />} />

                <Route path="/pdf_download" element={<PdfDownload />} />
            </SlideRoutes>

            {!isPDFPage && (<footer className="position-fixed bottom-0 bg-darkblue d-block w-100">
                <div className="d-flex justify-content-between">
                    <div className="tc-white fs-xs">
                        <div className={'d-none'}>({gls.saveData.app_mode})</div>
                    </div>
                     <span className="tc-white fs-xs m-0 p-1 text-end">Copyright © YAMADA HOMES Co.,LTD.All Rights Reserved.</span>
                </div>
            </footer>)}
        </>
    )

}

export default App
