
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC01 from "../../img/flow-pc01.png"
import IconQ101 from "../../img/q1-01.png"
import IconQ102 from "../../img/q1-02.png"
import IconArrow from "../../img/icon_arw01.png"
import {LocalStorage} from "../../storage/local_storage";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import {GetModeTitle} from "../../util/get_mode_title";
import {SimulationMode} from "../../entity/types";

const Confirm = () => {
    const navigate = useNavigate();
    let panelList = []
    for (let i = 1; i < 40; i++) {
        panelList.push(<option>{i}</option>)
    }
    const handleNavigate = () => {
        navigate('/sales/result1')
    }
    return (
        <div className={`home container`}>
            <div className="mt-5">
            </div>
            <h1 className="tc-yellow text-center fs-24 m-4">
                <div className={'fs-12 btn btn-info'}> <FontAwesomeIcon icon={faCalculator} /> {GetModeTitle()}</div>
                <div className={'pt-2'}>各都道府県 電力発電・消費シミュレーション</div>
            </h1>

            <div className={'rounded-4 bg-white p-4'}>
                <h2 className={'fs-xl mb-4 text-center'}>試算にあたってのご注意</h2>

                <p className="mt-5 fs-lg w-80 mx-auto">
                    当シミュレーターの結果は、 <strong className={'tc-magenta'} >一般的なデータに基づく大まかな試算結果</strong>であり、
                    <strong className={'tc-magenta'} ><u>導入後の結果をお約束・保証するものではありません。</u></strong>
                    <br />あくまで目安としてご利用願います。
                    <br /><br />
                    太陽光発電は自然を利用したシステムになりますので、天候や立地環境に大きく影響を受けますことをご理解いただけますようお願いいたします。</p>

            </div>


            <div className="m-4">
                <button onClick={handleNavigate} className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto w-80 text-center fs-xl">
                        同意する（シミュレーションを実行） <div className="wpx-20 float-end"><img src={IconArrow} /></div>
                    </div>
                </button>
            </div>
            <div className="p-3 "></div>
            <div className="m-4">
                <Link to="/sales/price-config" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto w-30 text-center fs-lg back_button">
                        <div className="wpx-10 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default Confirm

