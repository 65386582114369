
import {Link} from "react-router-dom";
import IconHome01 from "../img/home-01.png"
import IconHome02 from "../img/home-02.png"
import IconHome03 from "../img/home-03.png"
import IconArrow from "../img/icon_arw01.png"
import * as React from "react";

const Home = () => {
    return (
        <div className={`home container`}>
            <div className="mt-5 row px-3">
                <div className="rounded-3 bg-white tc-blue fs-sm p-2 m-auto col-12 col-md-8 col-lg-6 center-block text-center">
                    電気料金＆ガス料金明細がなくても<br className={'d-block d-md-none'} /> シミュレーション可能！
                </div>
            </div>
            <h1 className="tc-yellow text-center fs-32 m-3">
                太陽光発電システム<br />
                導入シミュレーター
            </h1>

            <p className="tc-white fs-20 text-center mt-3">こんなことがわかります！</p>

            <div className="row row-eq-height mx-2 mx-md-0 ">
                <div className="col-md-4 mt-2 mt-md-0  row-eq-height">
                    <div className="block rounded-4 bg-white text-md-center p-2 ">
                        <div className={'row  d-flex align-items-center'}>
                            <figure className="col-6 col-md-12 mb-0 mb-md-2 " ><img src={IconHome01} /></figure>
                            <p className={'mb-0 mb-md-2 col-6 col-md-12 '}>オトクになる<br className={'d-block d-md-none'} /> 光熱費<br className={'d-none d-md-block'}  />&nbsp;</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-4 mt-md-0 row-eq-height">
                    <div className="block rounded-4 bg-white text-md-center p-2 ">
                        <div className={'row  d-flex align-items-center'}>
                            <figure className="col-6 col-md-12 mb-0 mb-md-2 "><img src={IconHome02} /></figure>
                            <p className={'mb-0 mb-md-2 col-6 col-md-12 '}>購入とサブスク<br />
                                わが家にあった導入方法</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-4 mt-md-0 row-eq-height">
                    <div className="block rounded-4 bg-white text-md-center p-2 ">
                        <div className={'row  d-flex align-items-center'}>
                            <figure className="col-6 col-md-12 mb-0 mb-md-2 "><img src={IconHome03} /></figure>
                            <p className={'mb-0 mb-md-2 col-6 col-md-12 '}>蓄電池を導入した場合と<br className={'d-none d-md-block'} />
                                パネル搭載のみの場合の差</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="m-4">
                <Link to="/agree" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto col-12 col-md-6 text-center fs-xl">
                        次画面へ進む <div className="w-4 float-end"><img src={IconArrow} /></div>
                    </div>
                </Link>
            </div>

            <div className="text-center border border-yellow">
                <p className="tc-yellow p-2 m-0 fs-sm">※当シミュレータの数値は、一般的なデータに基づく大まかな試算結果であり、導入後の結果をお約束するものではありません。</p>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Home

