import {UsageResult} from "../entity/usage_result"
import {SummaryResult} from "../entity/summary_result"
import {PayConditon} from "../entity/pay_condition"
import { compareVersions } from 'compare-versions'
import {AppVersion} from "../const/app_version";
import {SimulationMode} from "../entity/types";

export enum KEYS {
    SAVEDATA = "save_data",
    USAGE_DATA = "usage_data",
    PAY_DATA = "pay_data",
    VERSION_DATA = "version_data"
}

export type TypeSaveData = {
    init: boolean
    sim_acception : boolean
    home_type : string
    prefecture : string
    stay_type : string
    number_of_family : number
    roof_type : string
    roof_degree : number
    house_angle : number
    house_tsubo : number
    panel_number : number
    condition_price_solar : number
    condition_price_solar_battery : number
    monthly_elec_cost : number
    monthly_gas_cost : number
    monthly_total_all_elec_cost : number
    monthly_total_all_elec_cost_calc : number
    monthly_total_cost : number
    monthly_total_cost_calc : number
    app_mode : string
    referrer_type : string
    default_mail_to : string
    simulation_mode: SimulationMode;
}

export class LocalStorage {
    private static _instance: LocalStorage;
    saveData = {init: true, app_mode:'default'} as TypeSaveData
    usageData = {} as UsageResult
    payConditionData = {zero_regi_year:15, loan_type:'住宅ローン', loan_year:35, rate:1.0} as PayConditon

    static checkVersion(){
        const savedVersion = localStorage.getItem(KEYS.VERSION_DATA)
        if(savedVersion == null){
            console.info('Version diff. reloading...')
            localStorage.setItem(KEYS.VERSION_DATA, AppVersion)
            window.location.reload()
        }else{
            let doReload = false
            try{
                if(compareVersions(AppVersion, savedVersion) > 0){
                    doReload = true
                }
            }catch (e){
                console.error(e)
                doReload = true
            }finally {
                if(doReload){
                    console.info('Version diff. reloading...')
                    localStorage.setItem(KEYS.VERSION_DATA, AppVersion)
                    window.location.reload()
                }
            }
        }
    }

    static getInstance() {

        if(this._instance){
        }else {
            this._instance = new this()
            this._instance.getSaveData()
        }
        return  this._instance
    }

    resetSaveData(){
        this.saveData = {
            init: true,
            app_mode:this.saveData.app_mode,
            referrer_type: this.saveData.referrer_type,
            default_mail_to: this.saveData.default_mail_to
        } as TypeSaveData
        localStorage.setItem(KEYS.SAVEDATA, JSON.stringify(this.saveData))
    }
    resetUsageData(){
        this.usageData = {} as UsageResult
        localStorage.setItem(KEYS.USAGE_DATA, JSON.stringify(this.usageData))
    }

    resetPayConditionData(){
        this.payConditionData = {zero_regi_year:15, loan_type:'住宅ローン', loan_year:35, rate:1.0} as PayConditon
        localStorage.setItem(KEYS.PAY_DATA, JSON.stringify(this.payConditionData))
        return true
    }

    setSaveData() : boolean  {
        this.saveData.init = false
        localStorage.setItem(KEYS.SAVEDATA, JSON.stringify(this.saveData))
        return true
    }
    setSaveDataWhole(saveData: TypeSaveData) : boolean  {
        localStorage.setItem(KEYS.SAVEDATA, JSON.stringify(saveData))
        return true
    }
    setUsageData(usageData: UsageResult) : boolean  {
        localStorage.setItem(KEYS.USAGE_DATA, JSON.stringify(usageData))
        return true
    }
    setPayConditionData(payConditon: PayConditon) : boolean  {
        localStorage.setItem(KEYS.PAY_DATA, JSON.stringify(payConditon))
        return true
    }

    getSaveData() : boolean {
        const localJson = localStorage.getItem(KEYS.SAVEDATA)
        if (localJson !== null) {
            this.saveData = JSON.parse(localJson) as TypeSaveData
            return true
        }else{
            console.log('[Warning] Get failed save data')
            localStorage.setItem(KEYS.SAVEDATA, JSON.stringify(this.saveData))
            return true
        }
    }

    getUsageData() : boolean {
        const localJson = localStorage.getItem(KEYS.USAGE_DATA)
        if (localJson !== null) {
            this.usageData = JSON.parse(localJson) as UsageResult
            return true
        }else{
            console.log('[Warning] Get failed save data')
            localStorage.setItem(KEYS.USAGE_DATA, JSON.stringify(this.usageData))
            return true
        }
    }
    getPayCondtionData() : boolean {
        const localJson = localStorage.getItem(KEYS.PAY_DATA)
        if (localJson !== null) {
            this.payConditionData = JSON.parse(localJson) as PayConditon
            return true
        }else{
            console.log('[Warning] Get failed payConditionData')
            localStorage.setItem(KEYS.PAY_DATA, JSON.stringify(this.payConditionData))
            return true
        }
    }

    setSimAcception(value : boolean){
        if (this.getSaveData()){
            this.saveData.sim_acception = value
            this.setSaveData()
        }
    }
    setHomeType(value : string){
        if (this.getSaveData()){
            this.saveData.home_type = value
            this.setSaveData()
        }
    }
    setPrefecture(value : string){
        if (this.getSaveData()){
            this.saveData.prefecture = value
            this.setSaveData()
        }
    }
    setStayType(value : string){
        if (this.getSaveData()){
            this.saveData.stay_type = value
            this.setSaveData()
        }
    }
    setNumberOfFamily(value : number){
        if (this.getSaveData()){
            this.saveData.number_of_family = value
            this.setSaveData()
        }
    }
    setRoofType(value : string){
        if (this.getSaveData()){
            this.saveData.roof_type = value
            this.setSaveData()
        }
    }
    setRoofDegree(value : number){
        if (this.getSaveData()){
            this.saveData.roof_degree = value
            this.setSaveData()
        }
    }
    setHouseAngle(value : number){
        if (this.getSaveData()){
            this.saveData.house_angle = value
            this.setSaveData()
        }
    }
    setHouseTsubo(value : number){
        if (this.getSaveData()){
            this.saveData.house_tsubo = value
            this.setSaveData()
        }
    }
    setPanelNumber(value : number){
        if (this.getSaveData()){
            this.saveData.panel_number = value
            this.setSaveData()
        }
    }
    setConditionPriceSolar(value : number){
        if (this.getSaveData()){
            this.saveData.condition_price_solar = value
            this.setSaveData()
        }
    }
    setConditionPriceSolarBattery(value : number){
        if (this.getSaveData()){
            this.saveData.condition_price_solar_battery = value
            this.setSaveData()
        }
    }
    setMonthlyElecCost(value : number){
        if (this.getSaveData()){
            this.saveData.monthly_elec_cost = value
            this.setSaveData()
        }
    }
    setMonthlyGasCost(value : number){
        if (this.getSaveData()){
            this.saveData.monthly_gas_cost = value
            this.setSaveData()
        }
    }
    setMonthlyTotalAllElecCost(value : number){
        if (this.getSaveData()){
            this.saveData.monthly_total_all_elec_cost = value
            this.setSaveData()
        }
    }
    setMonthlyTotalAllElecCostCalc(value : number){
        if (this.getSaveData()){
            this.saveData.monthly_total_all_elec_cost_calc = value
            this.setSaveData()
        }
    }
    setMonthlyTotalCost(value : number){
        if (this.getSaveData()){
            this.saveData.monthly_total_cost = value
            this.setSaveData()
        }
    }
    setMonthlyTotalCostCalc(value : number){
        if (this.getSaveData()){
            this.saveData.monthly_total_cost_calc = value
            this.setSaveData()
        }
    }
    setAppMode(value : string){
        if (this.getSaveData()){
            this.saveData.app_mode = value
            this.setSaveData()
        }
    }
    setReferrerType(value : string){
        if (this.getSaveData()){
            this.saveData.referrer_type = value
            this.setSaveData()
        }
    }
    setDefaultMailTo(value : string){
        if (this.getSaveData()){
            this.saveData.default_mail_to = value
            this.setSaveData()
        }
    }
    setSimulationMode(value : SimulationMode){
        if (this.getSaveData()){
            this.saveData.simulation_mode = value
            this.setSaveData()
        }
    }

}
