
import Plotly, {Layout, PlotData} from 'plotly.js'
import Plot from "react-plotly.js";
import {useEffect, useRef, useState} from "react";
import {ResponseResults} from "../entity/result_response";

interface Props {
    resData: ResponseResults
}
const ResultGraph = (props: Props) => {
    const ref = useRef<HTMLElement>(null);
    const [width, widthSet] = useState(0);
    const {resData} = props
    useEffect(() => {
        handleResize()
        // console.log('dom width', document?.getElementById('resultLeft')?.clientWidth)
        // console.log('width', ref.current ? ref.current.clientWidth : 0);
    }, [ref.current]);

    const handleResize = () => {
        let targetWidth = document?.getElementById('resultLeft')?.clientWidth
        if(targetWidth && targetWidth > 100){
            widthSet(targetWidth * 0.96)
        }
        console.log('graph resData', resData)
    }
    window.addEventListener('resize', handleResize)

    const trace1:Partial<PlotData> =  {
        x: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        y: [resData.monthly.m1.monthly_total,
            resData.monthly.m2.monthly_total,
            resData.monthly.m3.monthly_total,
            resData.monthly.m4.monthly_total,
            resData.monthly.m5.monthly_total,
            resData.monthly.m6.monthly_total,
            resData.monthly.m7.monthly_total,
            resData.monthly.m8.monthly_total,
            resData.monthly.m9.monthly_total,
            resData.monthly.m10.monthly_total,
            resData.monthly.m11.monthly_total,
            resData.monthly.m12.monthly_total],
        type: 'scatter',
        hovertemplate: '%{x}: ￥%{y:,.0d}' +'',
        name: '月間電気料金計',
        line: {color: '#E33982'},
    }
    const trace2:Partial<PlotData> =  {
        x: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        y: [resData.monthly.m1.non_panel_total,
            resData.monthly.m2.non_panel_total,
            resData.monthly.m3.non_panel_total,
            resData.monthly.m4.non_panel_total,
            resData.monthly.m5.non_panel_total,
            resData.monthly.m6.non_panel_total,
            resData.monthly.m7.non_panel_total,
            resData.monthly.m8.non_panel_total,
            resData.monthly.m9.non_panel_total,
            resData.monthly.m10.non_panel_total,
            resData.monthly.m11.non_panel_total,
            resData.monthly.m12.non_panel_total],
        type: 'scatter',
        hovertemplate: '%{x}: ￥%{y:,.0d}' +'',
        line: {color: '#555'},
        name: '非搭載時月間電気料金計(オール電化)',
    }
    const data = [trace1, trace2];
    const config = {
        displayModeBar: false, // this is the line that hides the bar.
    };
    const layout1:Partial<Layout> = {
        paper_bgcolor:'rgba(0,0,0,0)',
        plot_bgcolor:'rgba(0,0,0,0)',
        hovermode: 'closest',
        title: '',
        autosize: false,
        showlegend: true,
        margin: {l:50, r:0, t:0, b:0},
        legend: {
            orientation: 'h',
            x: 0.4,
            y: -0.1
        },
        yaxis: {
            fixedrange: true,
            gridcolor: '#ccc',
            tickformat: ',',
            tickwidth: 0,
            tickprefix:'￥'
        },
        xaxis: {
            fixedrange: true,
            gridcolor: '#ccc',
        }
    }
    if(width > 100){
        layout1.autosize = false
        layout1.width = width
        layout1.height = 320
    }
    return (
        <Plot data={data} layout={layout1} config={config} />
    );
}
export default  ResultGraph

