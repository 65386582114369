
import React, {useEffect, useState} from "react";
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import {LocalStorage, TypeSaveData} from "../../storage/local_storage";
import axios from "axios";
import {APIURLs} from "../../const/urls";
import {Link, useLocation} from "react-router-dom";
import { Buffer } from 'buffer';
import SalesResult1 from "./result1";
import SalesResult11 from "./result1";
import SalesResult2 from "./result2";
import SalesResult22 from "./result2";
import SalesResult3EV from "./result3_ev";
import SalesResult33EV from "./result3_ev";
import YamadaHomesLogo from "../../img/yamadahomeslogo.png";
import {UsageResult} from "../../entity/usage_result";
import {PayConditon} from "../../entity/pay_condition";
window.Buffer = Buffer;

interface ResultData {
    result: boolean
    data: {
        calc_data: UsageResult
        conditions: TypeSaveData
        pay_data: { }
    }
}
let loaded = false
let isDataload = false
const zeroRegiYearMax = 15
let interval: number = -1
const PdfDownloadSalesEV = () => {
    let gls =  LocalStorage.getInstance()
    const search = useLocation().search;
    const [showBattery, showBatterySet] = useState(true)
    const [payCondition, payConditionSet] = useState({} as PayConditon)
    const [loading, loadingSet] = useState(false);
    useEffect(() => {
        dataLoad()
    }, []);
    useEffect(() => {
        interval = window.setInterval(fileDL,1000)
    }, [payCondition]);

    const dataLoad = ()=>{
        gls.getSaveData()

        if(isDataload == false){
            const query = new URLSearchParams(search);
            const did = query.get('did')
            if(did){
                const reqUrl = `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_HOST}`+APIURLs.GETRES+"?id="+did
                axios.get(reqUrl).then(response => {
                    console.log('response.data', response.data)
                    if(response.data){
                        const ls =  LocalStorage.getInstance()
                        const res = response.data as ResultData
                        if(res.result == true){
                            console.log(res.data)
                            ls.setUsageData(res.data.calc_data )
                            ls.setSaveDataWhole(res.data.conditions )
                            ls.setPayConditionData(res.data.pay_data as PayConditon )
                            console.log('payCondition seted', res.data.pay_data)
                            payConditionSet(res.data.pay_data as PayConditon)
                            gls.getSaveData()
                        }
                        isDataload = true
                    }
                })
            }
        }
    }


    const fileDL = ()=>{
        console.log('loaded, isDataload , payCondition.zero_regi_year',loaded, isDataload, payCondition.zero_regi_year)
        if(loaded==false && isDataload == true && payCondition.zero_regi_year){
            let element11solar = document.getElementById('result1-solar-table1')
            let element12solar = document.getElementById('result1-solar-table2')
            let element21solar = document.getElementsByClassName('result2-solar-table1')[0]
            if(element11solar && element12solar  && element21solar ){
                showBatterySet(true)
            }
            let setClass = element21solar.getAttribute('class')
            // @ts-ignore
            setClass = setClass.replace('d-none', '')
            element21solar.setAttribute('class', setClass)

            let element11battery = document.getElementById('result1-battery-table1')
            let element12battery = document.getElementById('result1-battery-table2')
            let element21battery = document.getElementsByClassName('result2-battery-table1')[0]
            setClass = element21battery.getAttribute('class')
            // @ts-ignore
            setClass = setClass.replace('d-none', '')
            element21battery.setAttribute('class', setClass)

            console.log('element11battery, element12battery',element11battery, element12battery, element21battery)
            if(element11solar && element12solar && element21solar &&
                element11battery &&  element12battery && element21battery
            ){
                loaded=true
                window.clearInterval(interval)

                let title1 = document.createElement('h5');
                title1.innerText = '▼ 日間消費モデル（太陽光とEVあり）'
                const pdfBlock1 = document.getElementById("pdfBlock1")
                pdfBlock1!.innerHTML = ''
                if(pdfBlock1){
                    pdfBlock1.appendChild(title1)
                    pdfBlock1.appendChild(element11solar)
                    if(payCondition.zero_regi_year >= zeroRegiYearMax) {
                        const itemsTd = pdfBlock1.getElementsByTagName('td')
                        for (let i = 0; i < itemsTd.length; i++) {
                            itemsTd[i].innerText = '----';
                        }
                    }
                }

                let title2 = document.createElement('h5');
                title2.innerText = '▼ 月間電気料金試算（太陽光とEVあり）'
                title2.setAttribute("style", "margin-top:2rem;")
                if(pdfBlock1){
                    pdfBlock1.appendChild(title2)
                    pdfBlock1.appendChild(element12solar)
                    console.log('payCondition.zero_regi_year >= zeroRegiYearMax', payCondition.zero_regi_year ,zeroRegiYearMax)
                    if(payCondition.zero_regi_year >= zeroRegiYearMax) {
                        const itemsTd = pdfBlock1.getElementsByTagName('td')
                        for (let i = 0; i < itemsTd.length; i++) {
                            itemsTd[i].innerText = '----';
                            itemsTd[i].classList.remove('sagaku_result')
                        }
                    }
                }

                let title3 = document.createElement('h5');
                title3.innerText = '▼ 日間消費モデル（太陽光とEV+蓄電池あり）'
                const pdfBlock2 = document.getElementById("pdfBlock2")
                pdfBlock2!.innerHTML = ''
                if(pdfBlock2){
                    pdfBlock2.appendChild(title3)
                    pdfBlock2.appendChild(element11battery)
                }

                let title4 = document.createElement('h5');
                title4.innerText = '▼ 月間電気料金試算（太陽光とEV+蓄電池あり）'
                title4.setAttribute("style", "margin-top:2rem;")
                if(pdfBlock2){
                    pdfBlock2.appendChild(title4)
                    pdfBlock2.appendChild(element12battery)
                }

                let title21 = document.createElement('h5');
                title21.innerText = '▼ 年間支払額シミュレーション結果（太陽光とEVあり）'
                title21.setAttribute("style", "margin-top:1rem;margin-bottom:-2rem;")
                const pdfBlock3 = document.getElementById("pdfBlock3")
                pdfBlock3!.innerHTML = ''
                if(pdfBlock3){
                    pdfBlock3.appendChild(title21)
                    pdfBlock3.appendChild(element21solar)
                    if(payCondition.zero_regi_year >= zeroRegiYearMax) {
                        const itemsTd = pdfBlock3.getElementsByTagName('td')
                        for (let i = 0; i < itemsTd.length; i++) {
                            itemsTd[i].innerText = '----';
                        }
                        const itemsTr = pdfBlock3.getElementsByTagName('tr')
                        for (let i = 0; i < itemsTr.length; i++) {
                            itemsTr[i].classList.remove('cheapest')
                        }
                    }
                }

                let title41 = document.createElement('h5');
                title41.innerText = '▼ 年間支払額シミュレーション結果（太陽光とEV+蓄電池あり）'
                title41.setAttribute("style", "margin-top:1rem;margin-bottom:-2rem;")
                const pdfBlock4 = document.getElementById("pdfBlock4")
                pdfBlock4!.innerHTML = ''
                if(pdfBlock4){
                    pdfBlock4.appendChild(title41)
                    pdfBlock4.appendChild(element21battery)
                }

                const pdfResource = document.getElementById("pdfResource")
                if(pdfResource){
                    pdfResource.remove()
                }
                const pdfPage = document.getElementById("pdfPage")

                let opt = {
                    margin:       0,
                    filename:     'yamadahomes_sim.pdf',
                    image:        { type: 'jpeg', quality: 0.90 },
                    html2canvas:  { scale: 2 },
                    jsPDF:        { unit: 'in', format: 'a3', orientation: 'landscape' },
                    pagebreak: {  after: 'hr' }
                }
                /*html2pdf().set(opt).from(pdfPage).toPdf()
                    .get('pdf').save().then((result: any) => {
                    console.log('Success:', result);
                    // window.location.href = "/"
                    // window.close()

                })*/

                setTimeout(() => {
                    html2pdf().set(opt).from(pdfPage)
                        .set(opt)
                        .toPdf()
                        .get('pdf')
                        .then((pdf: { autoPrint?: (() => void) | undefined; output: any; }) => {
                            /// pdfObj.autoPrint();
                            // window.location.href = pdfObj.output("bloburl") as string
                            downloadPdf(pdf)
                        })
                }, 3000)
                const downloadPdf = (pdf: { autoPrint?: () => void; output: any; }) => {
                    let link = document.createElement('a');
                    link.target = '_blank';
                    link.href = pdf.output('bloburl');
                    link.download = 'yamadahomes_sim.pdf';
                    link.click();
                    link.remove();
                    loadingSet(true)
                }
            }
        }
    }
    return (
        <>
            {loading == false && <div className={'loaderWrap'} ><p className="loader">ロード中...</p></div> }
            {loading == true && <div className={'text-center p-5'}>
                PDF生成が完了しました。ダウンロードが開始されます。
            </div>}
            <div id="pdfRoot" className={'d-none'} >
                <div id={"pdfResource"} style={{display:'none'}}>
                    {isDataload && payCondition.zero_regi_year &&
                        (<><SalesResult1 isDefaultBattery={false}></SalesResult1>
                            <SalesResult2 isDefaultBattery={false}></SalesResult2>
                            <SalesResult3EV isDefaultBattery={false} isPrint={true} currentZeroRegiPayYear={payCondition.zero_regi_year}></SalesResult3EV>

                            {showBattery&&(<>
                                <SalesResult11 isDefaultBattery={true}></SalesResult11>
                                <SalesResult22 isDefaultBattery={true}></SalesResult22>
                                <SalesResult33EV isDefaultBattery={true} isPrint={true} currentZeroRegiPayYear={payCondition.zero_regi_year}></SalesResult33EV></>)}
                        </>)}
                </div>
                <div id={"pdfPage"} className={'position-relative p-4'}>
                    {payCondition.water_mark&& (<div className={'text-center'} style={{zIndex:30000000, position:"fixed", textAlign:'center', width:'1600px', fontSize:"340px" , color:"rgba(0,0,0,0.06)"}}>
                        持ち出し<br />禁止
                    </div>) }
                    <header className={`top-0 bg-darkblue tc-white d-block w-100 position-relative`}>
                        <Link to="/" className="d-inline-block bg-white p-2 m-1 wpx-250"><img className={'w-100'} src={YamadaHomesLogo} /></Link>
                        <p className="d-inline-block tc-white">太陽光発電システム導入シミュレーター</p>
                        <div className={'d-inline-block result-notice'}>
                            <p className={'p-2'} >{new Date().getFullYear()}年{new Date().getMonth()+1}月{new Date().getDate()}日<br />
                                当シミュレーターの数値は、一般的なデータに基づく大まかな試算結果であり、導入後の結果をお約束するものではありません。</p></div>
                    </header>

                    <div className={'row p-2 fs-sm'}>
                        <div className={'col-2'} >
                            住宅: {gls.saveData.home_type=='reform'?'所有中の住宅':'新たに取得する住宅'}
                        </div>
                        <div className={'col-2'} >
                            都道府県: {gls.saveData.prefecture}
                        </div>
                        <div className={'col-2'} >
                            在宅形式: {gls.saveData.stay_type=='always'?'在宅':'共働き'}
                        </div>
                        <div className={'col-2'} >
                            世帯人数: {gls.saveData.number_of_family} 人
                        </div>
                        <div className={'col-2'} >
                            屋根の形: {gls.saveData.roof_type}
                        </div>
                        <div className={'col-2'} >
                            屋根の勾配: {gls.saveData.roof_degree}°
                        </div>
                        <div className={'col-2'} >
                            家の向き: {gls.saveData.house_angle}°
                        </div>
                        <div className={'col-2'} >
                            パネル枚数: {gls.saveData.panel_number}
                        </div>
                        <div className={'col-2'} >
                            ゼロレジ支払い年数: {payCondition.zero_regi_year}年
                        </div>
                        <div className={'col-2'} >
                            ローン種別: {gls.saveData.home_type=='reform'?'リフォームローン':'住宅ローン'}
                        </div>
                        <div className={'col-2'} >
                            ローン年数: {payCondition.loan_year}年
                        </div>
                        <div className={'col-2'} >
                            金利: {payCondition.rate&&payCondition.rate.toFixed(1)} %
                        </div>
                    </div>
                    <div className={'row px-3'}>
                        <div className={'col-6'} id={"pdfBlock1"}>

                        </div>
                        <div className={'col-6'} id={"pdfBlock2"}>

                        </div>
                        <div className={'col-6'} id={"pdfBlock3"}>

                        </div>
                        <div className={'col-6'} id={"pdfBlock4"}>

                        </div>
                    </div>
                    <footer className="position-absolute start-0 bottom-0 w-100">
                        <div className={'px-4'}>
                            <div className={'bg-darkblue d-block '}>
                                <div className="d-flex justify-content-between">
                                    <div className="tc-white fs-xs">
                                    </div>
                                    <span className="tc-white fs-xs m-0 p-1 text-end">Copyright © YAMADA HOMES Co.,LTD.All Rights Reserved.</span>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>

    )
}
export default PdfDownloadSalesEV

