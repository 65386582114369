
import {Link} from "react-router-dom";
import IconTableOff from "../img/icon_table_off.png";
import IconTableOn from "../img/icon_table_on.png";
import IconPdf from "../img/icon_pdf.png";
import IconGraphOff from "../img/icon_graph_off.png";
import IconGraphOn from "../img/icon_graph_on.png";
import IconArrow from "../img/icon_arw01.png";
import ButtonSelector from "../component/button_selector";
import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import ResultGraph from "../component/result_graph";
import {ResponseResults, ResponseResultsBaseData} from "../entity/result_response";
import {LocalStorage} from "../storage/local_storage";
import {GetLinkUrl} from "../util/get_link_url";
import {CurrencyFormat} from "../helper/formatter";
import {AppMode} from "../const/app_mode";
import axios from "axios";
import {APIURLs} from "../const/urls";

let fixPar = 1.0

interface Props {
    isDefaultBattery: boolean
}
const Result1 = (props:Props) => {
    let  ResponseResultsBaseData1 = { ...ResponseResultsBaseData }
    let  ResponseResultsBaseData2 = { ...ResponseResultsBaseData }
    const gls =  LocalStorage.getInstance()
    gls.getSaveData()
    gls.getUsageData()
    const [isModeBattery, isModeBatterySet] = useState(props.isDefaultBattery)
    const [appMode, appModeSet] = useState(gls.saveData.app_mode)
    const [isSolarOnly, isSolarOnlySet] = useState(true)
    const [isSolarBattery, isSolarBatterySet] = useState(false)
    const [isGraphMode, isGraphModeSet] = useState(false)
    const [resultSagakuValue, resultSagakuValueSet] = useState('')
    const [loading, loadingSet] = useState(true);
    const [resData, resDataSet] = useState<ResponseResults>(ResponseResultsBaseData1)
    const [resDataBattery, resDataBatterySet] = useState<ResponseResults>(ResponseResultsBaseData2)
    useEffect(() => {
        /// loadData(false)
        if(isModeBattery){
            isSolarOnlySet(false)
            isSolarBatterySet(true)
            loadData(true)
        }else{
            isSolarOnlySet(true)
            isSolarBatterySet(false)
            loadData(false)
        }
    }, []);

    const loadData = (isBattery:boolean) => {
        let baseData = getLoadData(isBattery)
        resDataSet(baseData)
        let baseDataBattery = getLoadData(true)
        resDataBatterySet(baseDataBattery)
        loadingSet(false)
    }

    const getLoadData = (isBattery:boolean) => {
        let setupResponseResult: ResponseResults =  JSON.parse(JSON.stringify(ResponseResultsBaseData))
        if( gls.usageData){
            let sellIncomeTotal = 0
            let buyOutcomeTotal = 0
            let basicPriceTotal = 0
            let saienePriceTotal = 0
            let surchargePriceTotal = 0
            let yearTotal = 0
            let yearNonPanelTotal = 0
            let totalDiffTotal = 0

            let usageSeason = {summer:0, winter:0}
            let generateSeason = {summer:0, winter:0}
            let sellIncomeSeason = {summer:0, winter:0}
            let buyOutcomeSeason = {summer:0, winter:0}

            fixPar = gls.saveData.monthly_total_all_elec_cost / gls.saveData.monthly_total_all_elec_cost_calc
            if(isNaN(fixPar)){
                fixPar = 1.0
            }
            console.log('fixPar--------------', fixPar)
            for(const key in gls.usageData.summaryWithAll.monthly ) {
                type ObjectKeyResponseResult = keyof typeof  ResponseResultsBaseData.monthly;
                const mKey = "m"+key as ObjectKeyResponseResult
                type BuyKey = keyof typeof gls.usageData.summaryWithAll.monthly;
                const buyKey = key as unknown as BuyKey

                let baseData = gls.usageData.summaryWithAll
                let allNoPanel = gls.usageData.summaryWithAllNoPanelNoBattery
                if(isBattery){
                    baseData = gls.usageData.summaryWithAllWithBattery
                }

                const sellUnitPrice =  gls.usageData.unitDefines.sellUnitPrice
                const sellIncome = baseData.monthly[buyKey].sell_income
                const buyOutComeWithPanel = baseData.monthly[buyKey].buy_outcome*fixPar
                const buyOutComeNoPanel = allNoPanel.monthly[buyKey].buy_outcome*fixPar
                const saieneChargeWithPanel = Math.floor(baseData.monthly[buyKey].saiene_outcome*fixPar)
                const surchargeWithPanel =  Math.floor(baseData.monthly[buyKey].surcharge_outcome*fixPar)

                //const avgKwhBuyUnit = gls.usageData.summaryWithAll.monthly[buyKey].buy_outcome / (gls.usageData.summaryWithAll.monthly[buyKey].usage_wh/1000)
                //const nonPanelSurplusWh = (baseData.monthly[buyKey].usage_wh_day - baseData.monthly[buyKey].generate_wh)
                const nonPanelbuyWh = allNoPanel.monthly[buyKey].usage_wh
                const nonPanelSaieneCharge = allNoPanel.monthly[buyKey].saiene_outcome*fixPar
                const nonPanelSurcharge = allNoPanel.monthly[buyKey].surcharge_outcome*fixPar

                let fixedBasicPrice = baseData.basic*fixPar
                setupResponseResult.monthly[mKey].sell_income = sellIncome
                setupResponseResult.monthly[mKey].buy_outcome = (buyOutComeWithPanel<0?0:buyOutComeWithPanel)
                setupResponseResult.monthly[mKey].basic_price = fixedBasicPrice
                setupResponseResult.monthly[mKey].saiene_fuka = saieneChargeWithPanel
                setupResponseResult.monthly[mKey].surcharge =  surchargeWithPanel
                setupResponseResult.monthly[mKey].monthly_total = buyOutComeWithPanel + (fixedBasicPrice) + saieneChargeWithPanel + surchargeWithPanel - sellIncome
                setupResponseResult.monthly[mKey].non_panel_total = buyOutComeNoPanel + fixedBasicPrice + nonPanelSaieneCharge + nonPanelSurcharge
                setupResponseResult.monthly[mKey].total_diff = setupResponseResult.monthly[mKey].non_panel_total - setupResponseResult.monthly[mKey].monthly_total
                setupResponseResult.monthly[mKey].total_diff = (setupResponseResult.monthly[mKey].total_diff<0?0:setupResponseResult.monthly[mKey].total_diff)

                if(parseInt(key) >= 10 || parseInt(key) <= 3 ){
                    usageSeason.winter += baseData.season.winter.daily_avg.usage_wh
                    generateSeason.winter +=baseData.season.winter.daily_avg.generate_wh
                    sellIncomeSeason.winter += baseData.season.winter.daily_avg.sell_income
                    buyOutcomeSeason.winter +=  baseData.season.winter.daily_avg.buy_outcome
                }else{
                    usageSeason.summer += baseData.season.summer.daily_avg.usage_wh
                    generateSeason.summer +=baseData.season.summer.daily_avg.generate_wh
                    sellIncomeSeason.summer += baseData.season.summer.daily_avg.sell_income
                    buyOutcomeSeason.summer += baseData.season.summer.daily_avg.buy_outcome
                }
                console.log('baseData.monthly[mKey]-------------------------',baseData.monthly[buyKey])
                sellIncomeTotal += baseData.monthly[buyKey].sell_income
                buyOutcomeTotal +=  (baseData.monthly[buyKey].buy_outcome<0?0:baseData.monthly[buyKey].buy_outcome)
                basicPriceTotal += baseData.basic
                saienePriceTotal += baseData.monthly[buyKey].saiene_outcome
                surchargePriceTotal += baseData.monthly[buyKey].surcharge_outcome
                yearTotal += setupResponseResult.monthly[mKey].monthly_total
                yearNonPanelTotal += setupResponseResult.monthly[mKey].non_panel_total
                totalDiffTotal = yearNonPanelTotal - yearTotal
            }
            setupResponseResult.monthly.year_total.sell_income = sellIncomeTotal
            setupResponseResult.monthly.year_total.buy_outcome = buyOutcomeTotal
            setupResponseResult.monthly.year_total.basic_price = basicPriceTotal
            setupResponseResult.monthly.year_total.saiene_fuka = saienePriceTotal
            setupResponseResult.monthly.year_total.surcharge = surchargePriceTotal
            setupResponseResult.monthly.year_total.monthly_total = yearTotal
            setupResponseResult.monthly.year_total.non_panel_total = yearNonPanelTotal
            setupResponseResult.monthly.year_total.total_diff = totalDiffTotal

            setupResponseResult.daily.summer.use =  ((usageSeason.summer)/1000)/6
            setupResponseResult.daily.summer.generate =  ((generateSeason.summer)/1000)/6
            setupResponseResult.daily.summer.sell_income =  Math.floor(sellIncomeSeason.summer)/6
            setupResponseResult.daily.summer.buy_outcome =  Math.floor(buyOutcomeSeason.summer)/6

            setupResponseResult.daily.winter.use = ((usageSeason.winter)/1000)/6
            setupResponseResult.daily.winter.generate = ((generateSeason.winter)/1000)/6
            setupResponseResult.daily.winter.sell_income = Math.floor(sellIncomeSeason.winter)/6
            setupResponseResult.daily.winter.buy_outcome = Math.floor(buyOutcomeSeason.winter)/6
        }
        return setupResponseResult
    }
    const handleClickBt1 = (isActive: boolean) => {
        console.log('handleClickBt1', isActive)
        if(isSolarOnly){
            return
        }
        isSolarOnlySet((res) => {
            return true
        })
        isSolarBatterySet((res) => {
            return false
        })
        loadData(false)
    }
    const handleClickBt2 = (isActive: boolean) => {
        console.log('handleClickBt2', isActive)
        if(isSolarBattery){
            return
        }
        isSolarOnlySet((res) => {
            return false
        })
        isSolarBatterySet((res) => {
            return true
        })
        loadData(true)
    }
    const changeMode = () => {
        isGraphModeSet(!isGraphMode)
    }

    return (
        <div className={``}>
            <div className={'d-none d-lg-block resultBg'}>
                <div className={'resultBgLeft'}></div>
                <div className={'resultBgRight'}></div>
            </div>
            <div className={`result `}>
                <div className="mt-4 pt-4 pt-lg-1">
                    <h1 className="fs-xl pt-2 ps-2 mt-4 text-center text-lg-start">
                        電気料金<br className={'d-block d-lg-none'} />シミュレーション結果
                    </h1>
                    <div className={'resultLeft'} id={'resultLeft'}>
                        <div className={'px-3'}>
                            <div className={'d-none d-lg-block'}>
                                <div className={'row mt-1'}>
                                    <div className={'col-6'}>
                                        <ButtonSelector title={"太陽光パネルのみ搭載時"} active={isSolarOnly} callback={handleClickBt1} />
                                    </div>
                                    <div className={'col-6'}>
                                        <ButtonSelector title={"太陽光パネル+蓄電池搭載時"} active={isSolarBattery} callback={handleClickBt2} />
                                    </div>
                                </div>
                                <div className={'text-center  d-none d-lg-block'}>
                                    {isSolarOnly && (<p className={'mb-0'}>太陽光発電パネルのみを搭載した場合の電気料金を試算しています。</p>)}
                                    {isSolarBattery && (<p className={'mb-0'}>太陽光発電パネルと蓄電池を搭載した場合の電気料金を試算しています。</p>)}
                                </div>
                            </div>
                            <div className={'d-block d-lg-none'}>
                                <div className={'row mt-1'}>
                                    <div className={'col-12 tc-white bg-blue p-２ fs-xl text-center'}>
                                       太陽光パネルのみ搭載時
                                    </div>
                                </div>

                                <div className={' p-2'} >
                                    <div className={'d-flex justify-content-around'} >
                                        <div>年間</div>
                                        <div className={'pe-1'}>
                                            <input className={'resultSagakuValue'} value={(loading == false?Math.floor(resData.monthly.year_total.total_diff).toLocaleString() :'')} readOnly={true} />
                                        </div>
                                        <div className={'pt-3'}>
                                            円
                                        </div>
                                    </div>
                                    <div className={'text-center pt-2'}>光熱費削減に！</div>
                                </div>
                            </div>

                            <div >
                            {loading ? (
                                <p className="loader">ロード中...</p>
                            ) : (
                                <>
                                    <h2 className="fs-lg ">
                                        日間消費モデル
                                    </h2>
                                    <table className={'resultTable '} key={"result1-"+(isSolarOnly?'solar':'battery')+"-table1"} id={"result1-"+(isSolarOnly?'solar':'battery')+"-table1"}>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>電気使用量</th>
                                            <th>発電量</th>
                                            <th>売電収入</th>
                                            <th>買電支出 </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={'text-start'}>春夏季平均（4月－9月）</td>
                                            <td>{resData.daily.summer.use.toFixed(1)} kWh</td>
                                            <td>{resData.daily.summer.generate.toFixed(1)} kWh</td>
                                            <td>{CurrencyFormat.format(resData.daily.summer.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resData.daily.summer.buy_outcome)}</td>
                                        </tr>
                                        <tr>
                                            <td className={'text-start'}>秋冬季平均（10月－3月）</td>
                                            <td>{resData.daily.winter.use.toFixed(1)} kWh</td>
                                            <td>{resData.daily.winter.generate.toFixed(1)} kWh</td>
                                            <td>{CurrencyFormat.format(resData.daily.winter.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resData.daily.winter.buy_outcome)}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div className="d-flex bd-highlight mt-3">
                                        <div className="flex-grow-1 bd-highlight">
                                            <h2 className="fs-lg ">
                                                月間電気料金試算
                                            </h2></div>

                                        <div className="d-none d-lg-block ps-2 pb-2 bd-highlight"><Button onClick={changeMode} className={'modeButton ' + (isGraphMode?'in_active':'active')}><img src={(isGraphMode?IconTableOff:IconTableOn)} /> 試算表を見る</Button></div>
                                        <div className="d-none d-lg-block ps-2 pb-2 bd-highlight"><Button onClick={changeMode} className={'modeButton ' + (isGraphMode?'active':'in_active')}><img src={(isGraphMode?IconGraphOn:IconGraphOff)} /> グラフ表示</Button></div>
                                    </div>
                                    {isGraphMode&&(resData != null && (
                                        <div className={''}>
                                            <ResultGraph resData={resData} />
                                        </div>))}
                                    {!isGraphMode&&(
                                        <table className={'resultTable table2'} key={"result1-"+(isSolarOnly?'solar':'battery')+"-table2"} id={"result1-"+(isSolarOnly?'solar':'battery')+"-table2"}>
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>売電収入</th>
                                                <th>買電金額</th>
                                                <th>基本電気料金</th>
                                                <th>再エネ賦課金</th>
                                                <th>燃料調整費</th>
                                                <th className={'monthlySubtotal'}>月間電気料金計</th>
                                                <th className={'hitousai'}>非搭載時月間電気料金計<br />（オール電化）</th>
                                                <th className={'sagaku'}>差額 </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>1月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m1.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m1.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m1.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m1.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m1.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m1.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m1.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m1.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>2月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m2.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m2.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m2.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m2.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m2.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m2.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m2.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m2.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>3月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m3.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m3.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m3.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m3.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m3.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m3.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m3.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m3.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>4月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m4.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m4.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m4.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m4.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m4.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m4.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m4.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m4.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>5月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m5.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m5.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m5.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m5.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m5.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m5.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m5.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m5.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>6月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m6.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m6.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m6.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m6.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m6.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m6.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m6.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m6.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>7月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m7.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m7.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m7.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m7.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m7.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m7.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m7.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m7.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>8月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m8.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m8.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m8.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m8.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m8.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m8.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m8.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m8.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>9月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m9.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m9.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m9.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m9.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m9.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m9.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m9.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m9.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>10月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m10.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m10.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m10.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m10.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m10.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m10.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m10.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m10.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>11月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m11.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m11.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m11.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m11.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m11.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m11.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m11.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m11.total_diff)}</td>
                                            </tr>
                                            <tr>
                                                <td>12月</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m12.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m12.buy_outcome)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m12.basic_price)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m12.saiene_fuka)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.m12.surcharge)}</td>
                                                <td className={'monthlySubtotal'}>{CurrencyFormat.format(resData.monthly.m12.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.m12.non_panel_total)}</td>
                                                <td className={'sagaku'}>{CurrencyFormat.format(resData.monthly.m12.total_diff)}</td>
                                            </tr>
                                            <tr className={'tableFooting'}>
                                                <td>年間<br />目安</td>
                                                <td>{CurrencyFormat.format(resData.monthly.year_total.sell_income)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.year_total.buy_outcome*fixPar)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.year_total.basic_price*fixPar)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.year_total.saiene_fuka*fixPar)}</td>
                                                <td>{CurrencyFormat.format(resData.monthly.year_total.surcharge*fixPar)}</td>
                                                <td className={'monthlySubtotal end'}>{CurrencyFormat.format(resData.monthly.year_total.monthly_total)}</td>
                                                <td className={'hitousai'}>{CurrencyFormat.format(resData.monthly.year_total.non_panel_total)}</td>
                                                <td className={'sagaku sagaku_result'}>{CurrencyFormat.format(resData.monthly.year_total.total_diff)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )}
                                </>
                            )}
                            </div>


                            <div className="mt-4 d-block d-lg-none">
                                <div className={' px-2 '}>
                                    <Link to="/result2" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-yellow py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center lh-100 '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1'} >支払額<br className={'d-none d-lg-block'} />シミュレーションを見る</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 pt-lg-2 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="p-4 "></div>

                                <div className={'d-block d-lg-none'}>
                                    <div className={'row mt-1'}>
                                        <div className={'col-12 tc-white bg-blue p-２ fs-xl text-center'}>
                                            太陽光パネル+蓄電池搭載時
                                        </div>
                                    </div>

                                    <div className={' p-2'} >
                                        <div className={'d-flex justify-content-around'} >
                                            <div>年間</div>
                                            <div className={'pe-1'}>
                                                <input className={'resultSagakuValue'} value={(loading == false?Math.floor(resDataBattery.monthly.year_total.total_diff).toLocaleString() :'')} readOnly={true} />
                                            </div>
                                            <div className={'pt-3'}>
                                                円
                                            </div>
                                        </div>
                                        <div className={'text-center pt-2'}>光熱費削減に！</div>
                                    </div>
                                </div>


                                <h2 className="fs-lg ">
                                    日間消費モデル
                                </h2>
                                <table className={'resultTable '} key={"result1-"+(isSolarOnly?'solar':'battery')+"-table1"} id={"result1-"+(isSolarOnly?'solar':'battery')+"-table1"}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>電気使用量</th>
                                        <th>発電量</th>
                                        <th>売電収入</th>
                                        <th>買電支出 </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'text-start'}>春夏季平均（4月－9月）</td>
                                        <td>{resDataBattery.daily.summer.use.toFixed(1)} kWh</td>
                                        <td>{resDataBattery.daily.summer.generate.toFixed(1)} kWh</td>
                                        <td>{CurrencyFormat.format(resDataBattery.daily.summer.sell_income)}</td>
                                        <td>{CurrencyFormat.format(resDataBattery.daily.summer.buy_outcome)}</td>
                                    </tr>
                                    <tr>
                                        <td className={'text-start'}>秋冬季平均（10月－3月）</td>
                                        <td>{resDataBattery.daily.winter.use.toFixed(1)} kWh</td>
                                        <td>{resDataBattery.daily.winter.generate.toFixed(1)} kWh</td>
                                        <td>{CurrencyFormat.format(resDataBattery.daily.winter.sell_income)}</td>
                                        <td>{CurrencyFormat.format(resDataBattery.daily.winter.buy_outcome)}</td>
                                    </tr>
                                    </tbody>
                                </table>


                                <div className="d-flex bd-highlight mt-3">
                                    <div className="flex-grow-1 bd-highlight">
                                        <h2 className="fs-lg ">
                                            月間電気料金試算
                                        </h2></div>
                                    <div className="d-none d-lg-block ps-2 pb-2 bd-highlight"><Button onClick={changeMode} className={'modeButton ' + (isGraphMode?'in_active':'active')}><img src={(isGraphMode?IconTableOff:IconTableOn)} /> 試算表を見る</Button></div>
                                    <div className="d-none d-lg-block ps-2 pb-2 bd-highlight"><Button onClick={changeMode} className={'modeButton ' + (isGraphMode?'active':'in_active')}><img src={(isGraphMode?IconGraphOn:IconGraphOff)} /> グラフ表示</Button></div>
                                </div>
                                <div>
                                    <table className={'resultTable table2'} key={"result1-"+(isSolarOnly?'solar':'battery')+"-table2"} id={"result1-"+(isSolarOnly?'solar':'battery')+"-table2"}>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>売電収入</th>
                                            <th>買電金額</th>
                                            <th>基本電気料金</th>
                                            <th>再エネ賦課金</th>
                                            <th>燃料調整費</th>
                                            <th className={'monthlySubtotal'}>月間電気料金計</th>
                                            <th className={'hitousai'}>非搭載時月間電気料金計<br />（オール電化）</th>
                                            <th className={'sagaku'}>差額 </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>1月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m1.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m1.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m1.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m1.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m1.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m1.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m1.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m1.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>2月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m2.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m2.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m2.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m2.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m2.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m2.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m2.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m2.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>3月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m3.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m3.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m3.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m3.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m3.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m3.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m3.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m3.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>4月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m4.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m4.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m4.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m4.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m4.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m4.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m4.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m4.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>5月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m5.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m5.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m5.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m5.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m5.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m5.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m5.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m5.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>6月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m6.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m6.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m6.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m6.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m6.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m6.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m6.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m6.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>7月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m7.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m7.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m7.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m7.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m7.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m7.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m7.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m7.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>8月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m8.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m8.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m8.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m8.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m8.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m8.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m8.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m8.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>9月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m9.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m9.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m9.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m9.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m9.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m9.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m9.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m9.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>10月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m10.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m10.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m10.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m10.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m10.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m10.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m10.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m10.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>11月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m11.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m11.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m11.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m11.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m11.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m11.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m11.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m11.total_diff)}</td>
                                        </tr>
                                        <tr>
                                            <td>12月</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m12.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m12.buy_outcome)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m12.basic_price)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m12.saiene_fuka)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.m12.surcharge)}</td>
                                            <td className={'monthlySubtotal'}>{CurrencyFormat.format(resDataBattery.monthly.m12.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.m12.non_panel_total)}</td>
                                            <td className={'sagaku'}>{CurrencyFormat.format(resDataBattery.monthly.m12.total_diff)}</td>
                                        </tr>
                                        <tr className={'tableFooting'}>
                                            <td>年間<br />目安</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.year_total.sell_income)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.year_total.buy_outcome*fixPar)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.year_total.basic_price*fixPar)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.year_total.saiene_fuka*fixPar)}</td>
                                            <td>{CurrencyFormat.format(resDataBattery.monthly.year_total.surcharge*fixPar)}</td>
                                            <td className={'monthlySubtotal end'}>{CurrencyFormat.format(resDataBattery.monthly.year_total.monthly_total)}</td>
                                            <td className={'hitousai'}>{CurrencyFormat.format(resDataBattery.monthly.year_total.non_panel_total)}</td>
                                            <td className={'sagaku sagaku_result'}>{CurrencyFormat.format(resDataBattery.monthly.year_total.total_diff)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'resultRight'}>
                        <div className={'px-2'}>
                            <div className={' p-2 d-none d-lg-block'} >
                                <div>年間</div>
                                <div className={'d-flex justify-content-around'} >
                                    <div className={'pe-1'}>
                                        <input className={'resultSagakuValue'} value={(loading == false?Math.floor(resData.monthly.year_total.total_diff).toLocaleString() :'')} readOnly={true} />
                                    </div>
                                    <div className={'pt-3'}>
                                        円
                                    </div>
                                </div>
                                <div className={'text-center pt-2'}>光熱費削減に！</div>
                            </div>

                            <div className={'navigationBlock px-3'}>
                                <div className="mt-4">
                                    <Link to="/result2" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-yellow py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1 lh-100'} >支払額<br className={'d-none d-lg-block'} />シミュレーションを見る</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 pt-lg-2 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                {appMode=='default'&&(<div className="mt-4">
                                    <a href={GetLinkUrl('contact')} target="_blank" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-white py-2 px-2 m-auto w-100 fs-sm">
                                            <div className={'d-flex justify-content-between position-relative'}>
                                                <div className={'w-100 text-center '} >
                                                    <div className={'d-inline-block align-middle fs-sm pt-1'} >お問い合わせ</div>
                                                </div>
                                                <div className="position-absolute end-0 mt-1 wpx-10 d-flex align-items-center"><img src={IconArrow} /></div>
                                            </div>
                                        </div>
                                    </a>
                                    <p className={'fs-xs text-center mt-3'}>更に詳しいシミュレーションをご希望の方はこちら</p>
                                </div>)}
                                <div className="mt-4">
                                    <Link to="/q9" className="link-no-decoration">
                                        <div className="defbtn d-block rounded-5 bg-grey1 py-2 px-4 m-auto w-80 text-center fs-sm back_button ">
                                            <div className="wpx-7 float-start"><img className="flip-horizontal" src={IconArrow} alt={'Arrow'}  /></div>
                                            戻る
                                        </div>
                                    </Link>
                                </div>
                                <div className="p-4 "></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Result1

