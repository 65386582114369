
import {Link} from "react-router-dom";
import IconArrow from "../../img/icon_arw01.png"
import {LocalStorage} from "../../storage/local_storage";
import React, {ReactElement, useEffect, useState} from "react";
import ButtonSelector from "../../component/button_selector";
import {CurrencyFormat} from "../../helper/formatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import {GetModeTitle} from "../../util/get_mode_title";
import {SimulationMode} from "../../entity/types";
import {UsageResultBaseData} from "../../entity/usage_result";

const gls = LocalStorage.getInstance()
interface Props {
    isDefaultBattery: boolean
}
const SalesResult1 = (props:Props) => {
    const ls = LocalStorage.getInstance()
    ls.getSaveData()
    ls.getUsageData()
    const [isModeBattery, isModeBatterySet] = useState(props.isDefaultBattery)
    const [isSolarOnly, isSolarOnlySet] = useState(props.isDefaultBattery)
    let dataAllNoBat =  ls.usageData.summaryWithAll
    let dataAllWithBat =  ls.usageData.summaryWithAllWithBattery
    let dataAllWithBatWithEV =  ls.usageData.summaryWithAllWithBatteryWithEV
    let unitData = gls.usageData.unitDefines
    const [resData, resDataSet] = useState<UsageResultBaseData>(dataAllNoBat)
    const [dailyDataTableListView, dailyDataTableListViewSet] = useState<ReactElement[]>()

    useEffect(() => {
        if(isModeBattery){
            isSolarOnlySet(false)
        }else{
            isSolarOnlySet(true)
        }
        // eslint-disable-next-line
    },  []);
    useEffect(() => {
        if((gls.saveData.simulation_mode == SimulationMode.EV)) {
            if(isSolarOnly){
                resDataSet(dataAllWithBat)
            }else{
                resDataSet(dataAllWithBatWithEV)
            }
        } else {
            if(isSolarOnly){
                resDataSet(dataAllNoBat)
            }else{
                resDataSet(dataAllWithBat)
            }
        }
    },  [isSolarOnly]);
    useEffect(() => {
        if((gls.saveData.simulation_mode == SimulationMode.EV)) {
            if(isSolarOnly){
                dailyDataTableList(dataAllWithBat)
            }else{
                dailyDataTableList(dataAllWithBatWithEV)
            }
        } else {
            if(isSolarOnly){
                dailyDataTableList(dataAllNoBat)
            }else{
                dailyDataTableList(dataAllWithBat)
            }
        }
    },  [resData]);

    const handleClickBt1 = (isActive: boolean) => {
        console.log('handleClickBt1', isActive)
        isSolarOnlySet(true)
    }
    const handleClickBt2 = (isActive: boolean) => {
        console.log('handleClickBt2', isActive)
        isSolarOnlySet(false)
    }
    const dailyDataTableList = (baseData:UsageResultBaseData) =>{
        let list = []
        for(let month=1; month<=12; month++){
            let days = 30
            if(month === 1 || month === 3 || month===5 || month===7 || month===8 ||  month===10 || month===12){
                days = 31
            }else{
                if(month===2){
                    days=28
                }
            }
            type MonKey = keyof typeof baseData.monthly;
            const mKey = month as unknown as MonKey

            list.push( <tr>
                <th>{month}月</th>
                <td>{(baseData.monthly[mKey].generate_wh/1000/days).toFixed(2) } kWh</td>
                <td>{(baseData.monthly[mKey].usage_wh_fixed/1000/days).toFixed(2) } kWh</td>
                <td>{(baseData.monthly[mKey].usage_wh/1000/days).toFixed(2) } kWh</td>
                <td className={'sell'}>{(baseData.monthly[mKey].sell_wh/1000/days).toFixed(2) } kWh</td>
                <td className={'sell'}>{CurrencyFormat.format(Math.floor(baseData.monthly[mKey].sell_income/days))}</td>
                <td className={'buy'}>{CurrencyFormat.format(Math.floor((baseData.monthly[mKey].buy_outcome +baseData.basic+ baseData.monthly[mKey].surcharge_outcome  + baseData.monthly[mKey].saiene_outcome)/days))}</td>
            </tr>)
        }
        dailyDataTableListViewSet(list)
    }
    return (
        <div className={`home container pt-3`}>
            <h1 className="tc-yellow text-center fs-24 m-4">
                <div className={'fs-12 btn btn-info'}> <FontAwesomeIcon icon={faCalculator} /> {GetModeTitle()}</div>
                <div className={'pt-2'}>電気料金シミュレーション結果</div>
            </h1>
            <div className={'rounded-4 bg-white p-4'}>
                <div className={'row mt-2'}>
                    <div className={'col-6'}>
                        <ButtonSelector title={(gls.saveData.simulation_mode == SimulationMode.EV?"太陽光とEVあり":"太陽光パネルのみ搭載時")} active={isSolarOnly} callback={handleClickBt1} />
                    </div>
                    <div className={'col-6'}>
                        <ButtonSelector title={(gls.saveData.simulation_mode == SimulationMode.EV?"太陽光とEV+蓄電池あり":"太陽光パネル+蓄電池搭載時")} active={!isSolarOnly} callback={handleClickBt2} />
                    </div>
                </div>
                <div className="row">
                    <div className={'text-center  d-none d-lg-block'}>
                        { (gls.saveData.simulation_mode == SimulationMode.EV) && (
                            (isSolarOnly && (<p className={'mb-0'}>
                                EV【太陽光パネル＋V2H】を搭載した場合の電気料金を試算しています。<br />
                                蓄電池容量の想定： {unitData.batteryCapacityForModeEVLeft} kWh（緊急時を考慮して容量上限の75％設定としています。）
                            </p>))
                        )}
                        { (gls.saveData.simulation_mode == SimulationMode.EV) && (
                            (!isSolarOnly && (<p className={'mb-0'}>
                                EV【太陽光パネル＋蓄電池＋V2H】を搭載した場合の電気料金を試算しています。<br />
                                蓄電池容量の想定： {unitData.batteryCapacityForModeEVRight} kWh（設置型蓄電池容量＋EV、合計{unitData.batteryCapacityForModeEVRight}kWh設定としています。）
                            </p>))
                        )}
                        { (gls.saveData.simulation_mode == SimulationMode.DEFAULT) && (
                            (isSolarOnly && (<p className={'mb-0'}>
                                太陽光発電パネルのみを搭載した場合の電気料金を試算しています。
                            </p>))
                        )}
                        { (gls.saveData.simulation_mode == SimulationMode.DEFAULT) && (
                            (!isSolarOnly && (<p className={'mb-0'}>
                                太陽光発電パネルと蓄電池を搭載した場合の電気料金を試算しています。
                            </p>))
                        )}
                    </div>
                    <div className="col-12">
                        <h2 className="fs-lg ">
                            日間消費モデル
                        </h2>
                        <table className={'resultTable '} key={"result1-"+(isSolarOnly?'solar':'battery')+"-table1"} id={"result1-"+(isSolarOnly?'solar':'battery')+"-table1"}>
                            <thead>
                            <tr>
                                <th></th>
                                <th>電気使用量</th>
                                <th>発電量</th>
                                <th>売電収入</th>
                                <th>買電支出 </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={'text-start'}>春夏季平均（4月－9月）</td>
                                <td>{(resData.season.summer.daily_avg.usage_wh/1000).toFixed(1)} kWh</td>
                                <td>{(resData.season.summer.daily_avg.generate_wh/1000).toFixed(1)} kWh</td>
                                <td>{CurrencyFormat.format(resData.season.summer.daily_avg.sell_income)}</td>
                                <td>{CurrencyFormat.format(resData.season.summer.daily_avg.buy_outcome)}</td>
                            </tr>
                            <tr>
                                <td className={'text-start'}>秋冬季平均（10月－3月）</td>
                                <td>{(resData.season.winter.daily_avg.usage_wh/1000).toFixed(1)} kWh</td>
                                <td>{(resData.season.winter.daily_avg.generate_wh/1000).toFixed(1)} kWh</td>
                                <td>{CurrencyFormat.format(resData.season.winter.daily_avg.sell_income)}</td>
                                <td>{CurrencyFormat.format(resData.season.winter.daily_avg.buy_outcome)}</td>
                            </tr>
                            </tbody>
                        </table>

                        <table className={'resultTableSales table-bordered'} >
                            <thead>
                            <tr>
                                <th></th>
                                <th>発電量</th>
                                <th>買電量</th>
                                <th>消費量</th>
                                <th className={'sell'}>売電可能電力量</th>
                                <th className={'sell'}>売電価格</th>
                                <th className={'buy'}>買電価格</th>
                            </tr>
                            </thead>
                            <tbody>

                            {dailyDataTableListView}
                            </tbody>
                        </table>

                        <div className="p-3 "></div>
                    </div>
                </div>
            </div>

            <div className={'row mt-4 pb-5'}>
                <div className="col-4">
                    <Link to="/sales/confirm" className="link-no-decoration">
                        <div className="  float-start defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 w-80 text-center fs-lg back_button">
                            <div className="wpx-10 float-start"><img className="flip-horizontal" src={IconArrow} alt={''} /></div>
                            戻る
                        </div>
                    </Link>
                </div>
                <div className="col-8">
                    <Link to="/sales/result2" className="link-no-decoration">
                        <div className=" float-end defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 w-80 text-center fs-xl">
                            月間消費モデルを見る <div className="wpx-20 float-end"><img src={IconArrow} alt={''} /></div>
                        </div>
                    </Link>
                </div>
                <br className={'clear'} />
                <div className="p-3 "></div>
            </div>
        </div>
    )
}
export default SalesResult1

