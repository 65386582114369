import {LocalStorage} from "../storage/local_storage";

const routeCheck = () => {
    const ls =  LocalStorage.getInstance()
    let routeValid = false
    ls.getSaveData()
    if(ls.saveData.sim_acception){
        if(ls.saveData.home_type !== ''){
            if(ls.saveData.prefecture !== ''){
                if(ls.saveData.stay_type !== ''){
                    if(ls.saveData.number_of_family !== 0){
                        if(ls.saveData.roof_type !== ''){
                            if(ls.saveData.roof_degree !== 0){
                                if(ls.saveData.house_angle !== undefined){
                                    if(ls.saveData.panel_number !== 0){
                                       routeValid = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return routeValid
}
export default routeCheck
