import React, {useState} from "react";
import IconCircleOff from "../img/icon_circle_off.png";
import IconCircleOn from "../img/icon_circle_on.png";
import {LocalStorage} from "../storage/local_storage";


interface Props {
    title: string
    callback: Function
    active: boolean
}
interface State {
    isActive: boolean
}
class ButtonSelector　extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }
    onTouch = () => {
        this.props.callback(!this.props.active)
    }
    render() {
        let returns = (<button key={'selectorButton'} className={`conditionSelector ${this.props.active?'active':'inactive'}`} onClick={this.onTouch}>
            {!this.props.active && <span className={'circle'}><img src={IconCircleOff} alt={'circle_off'}/></span>}
            {this.props.active && <span className={'circle'}><img src={IconCircleOn} alt={'circle_on'}/></span>}
            <span className="text">{this.props.title}</span>
        </button>)
        return (
            <React.Fragment>
                {returns}
            </React.Fragment>
        )
    }
}
export default ButtonSelector
