import {LocalStorage} from "../storage/local_storage";
import {SimulationMode} from "../entity/types";

export const GetModeTitle = () => {
    const gls = LocalStorage.getInstance()
    if(gls.saveData.simulation_mode == SimulationMode.EV) {
        return 'EVシミュレーションモード'
    }else{
        return '蓄電池シミュレーションモード'
    }
}
