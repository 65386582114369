
import {Link, useNavigate} from "react-router-dom";
import ImgFlowPC08 from "../img/flow-pc08.png"
import IconArrow from "../img/icon_arw01.png"
import {LocalStorage} from "../storage/local_storage";
import {useEffect, useRef, useState} from "react";
import * as React from "react";
import ImgFlowPC06 from "../img/flow-pc06.png";

const Q8 = () => {
    const gls =  LocalStorage.getInstance()
    const [btSelected, btSelectedSet] = useState(gls.saveData.house_tsubo)
    const getSelected = (key: number) => {
        if(btSelected == key){
            return 'selected'
        }
        return ''
    }
    const [panelNum, panelNumSet] = useState(0)
    const navigate = useNavigate();;
    const initialized = useRef(false);
    useEffect(() => {
        if(!initialized.current){
            console.log('useEffect init')
            initialized.current = true;
            const ls =  LocalStorage.getInstance()
            ls.getSaveData()
            panelNumSet(ls.saveData.panel_number)
        }
    });
    const handleAnswer = (value: number) => {
        const ls =  LocalStorage.getInstance()
        interface KVNumJson {
            [id: string]: number;
        }
        const definePanels:KVNumJson = {
            "5" : 12,
            "10" : 14,
            "15" : 16,
            "20" : 18,
            "25" : 20,
            "30" : 24,
        }
        ls.setHouseTsubo(value)
        btSelectedSet(value)
        const strVal = value.toString()
        ls.setPanelNumber(definePanels[strVal])
        panelNumSet(definePanels[strVal])
    }
    const changeInput = (value: string) => {
        const ls =  LocalStorage.getInstance()
        const num = parseInt(value)
        if(num && !isNaN(num)){
            panelNumSet(num)
            ls.setPanelNumber(num)
        }else{
            panelNumSet(0)
            ls.setPanelNumber(0)
        }
    }

    return (
        <div className={`home container`}>
            <div className="mt-5">
                <p className="pt-4 pt-md-0 tc-white text-center">
                    太陽光発電パネルを設置する環境についてご回答ください
                </p>
                <figure className="text-center col-11 col-md-6 m-auto"><img src={ImgFlowPC08} /></figure>
            </div>
            <h1 className="tc-yellow text-center fs-xl mx-4 mt-3 ">
                坪数を選択してください
            </h1>

            <div className={'d-none d-md-block'}>
                <div className="mt-3 mt-md-4 mx-auto w-80 d-flex justify-content-center">
                    <a href="javascript:void(0);" onClick={() => handleAnswer(5)} className={'link-no-decoration d-block w-10 me-3 ' + getSelected(5)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">5坪</h2>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(10)} className={'link-no-decoration d-block w-10 me-3 ' + getSelected(10)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">10坪</h2>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(15)} className={'link-no-decoration d-block w-10 me-3 ' + getSelected(15)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">15坪</h2>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(20)} className={'link-no-decoration d-block w-10 me-3 ' + getSelected(20)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">20坪</h2>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(25)} className={'link-no-decoration d-block w-10 me-3 ' + getSelected(25)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">25坪</h2>
                        </div>
                    </a>
                    <a href="javascript:void(0);" onClick={() => handleAnswer(30)} className={'link-no-decoration d-block w-10 me-3 ' + getSelected(30)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">30坪</h2>
                        </div>
                    </a>
                </div>
            </div>
            <div className={'d-block d-md-none'}>
                <div className="mt-3 mt-md-4 mx-auto row">
                    <div onClick={() => handleAnswer(5)} className={'selectable col-4 mt-3 ' + getSelected(5)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">5坪</h2>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(10)} className={'selectable col-4 mt-3 ' + getSelected(10)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">10坪</h2>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(15)} className={'selectable col-4 mt-3 ' + getSelected(15)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">15坪</h2>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(20)} className={'selectable col-4 mt-3 ' + getSelected(20)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">20坪</h2>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(25)} className={'selectable col-4 mt-3 ' + getSelected(25)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">25坪</h2>
                        </div>
                    </div>
                    <div onClick={() => handleAnswer(30)} className={'selectable col-4 mt-3 ' + getSelected(30)}>
                        <div className="block rounded-4 bg-white text-center p-2 link-btn">
                            <h2 className="text-center pt-2 fs-lg">30坪</h2>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-center tc-white m-4 fs-sm"> ※建坪：2階建て住宅の場合は2階部の床面積で選択してください。（延べ床面積ではありません。）</p>

            <div className="rounded-4 bg-white p-3" >
                <p className="fs-xl text-center">
                    ご自宅に設置可能な<br className={'d-block d-md-none'} /> 太陽光パネルはおよそ
                </p>
                <div className="row  justify-content-center">
                    <div className="col-auto col-4 col-md-2">
                        <input type="number" readOnly={true} className="text-center form-control w-100" value={panelNum} onChange={(e) => changeInput(e.target.value) } />
                    </div>
                    <div className="col-auto "> 枚です。</div>
                </div>

                <p className="text-center mt-3 mb-0 p-0 fs-sm">
                    ※個人搭載の最大容量は24枚までとなります。
                    <br />
                    あくまでも比較的効率よく配置できた場合での目安です。屋根形状などにより設置可能枚数は異なります。
                </p>
            </div>

            <div className="m-4">
                <Link to={panelNum > 0 ?"/q9" : '#'} className="link-no-decoration" >
                    <div className="defbtn d-block rounded-5 bg-yellow fw-bold py-3 px-4 m-auto col-10 col-md-7 text-center fs-xl">
                        光熱費確認画面へ進む <div className="w-4 float-end"><img src={IconArrow} /></div>
                    </div>
                </Link>
            </div>
            <div className="m-4">
                <Link to="/q7" className="link-no-decoration">
                    <div className="defbtn d-block rounded-5 bg-grey1 fw-bold py-2 px-4 m-auto col-8 col-md-4 text-center fs-lg back_button">
                        <div className="w-4 float-start"><img className="flip-horizontal" src={IconArrow} /></div>
                        戻る
                    </div>
                </Link>
            </div>
            <div className="p-3 "></div>
        </div>
    )
}
export default Q8

